<template>
  <div class="credit-book">
    <h3 class="text-title">Зачётная книжка</h3>

    <creditBookTab />

    <template v-if="recordBook">
      <listOfCourses :list="recordBook" v-model="selectedSemester" />

      <credit-table
        v-if="selectedSemester"
        :tableData="selectedSemesterData"
        :headers="headers"
        slot-row
      >
        <template #row="props">
          <span class="table__col">
            {{ props.index }}
          </span>

          <span class="table__col">
            {{ props.discipline }}
          </span>

          <span class="table__col">
            {{ props.discipline_code }}
          </span>

          <span class="table__col">
            {{ props.exam }}
          </span>

          <span :class="{'table__col--is-red': props.result === 'не аттестован(-а)'}" class="table__col" >
            {{ props.result }}
          </span>

          <span class="table__col">
            {{ props.date }}
          </span>

          <span class="table__col">
            {{ props.teacher }}
          </span>
        </template>
      </credit-table>

      <h2 v-else class="credit-book__notify">Выберите семестр</h2>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import listOfCourses from '@/views/EducationalProcess/modules/common/listOfCourses';
import creditTable from '@/views/EducationalProcess/modules/common/creditTable.vue';
import creditBookTab from './creditBookTab.vue';

export default {
  data() {
    return {
      selectedSemester: null,
      currentTab: 1,
      headers: [
        '№',
        'Дисциплина',
        'Код',
        'Тип',
        'Оценка',
        'Дата сдачи',
        'Преподаватель',
      ],
    };
  },
  components: {
    creditBookTab,
    listOfCourses,
    'credit-table': creditTable,
  },
  computed: {
    selectedSemesterData() {
      const course = Math.ceil(Number(this.selectedSemester) / 2);

      return this.recordBook?.[course]?.[this.selectedSemester]?.map((el, idx) => ({ index: idx + 1, ...el })) || [];
    },

    recordBook() {
      return this.$store.state.education.recordBook;
    },

    navigationMax() {
      return this.$store.state.navigationMax ? '' : 'table--more';
    },
  },
  methods: {
    ...mapActions({ getRecordBook: 'education/getRecordBook' }),
  },
  created() {
    if (!this.recordBook) this.getRecordBook();
  },
};
</script>

<style lang="scss">
.credit-book {
  display: grid;
  row-gap: 2.4rem;
  padding-right: 3rem;

  @include breakPoints(sm) {
    padding-right: 0;
  }

  &__notify {
    text-align: center;
  }

  .text-title {
    display: none;
  }

  .table__col.table__col--is-red {
    color: $danger-clr;
    white-space: nowrap;
  }
}
</style>
