<template>
  <div class="tab-description">
    <div v-html="descriptionData" v-if="descriptionData"></div>
    <p class="tab-description__no-content" v-else>Описание отсутствует</p>
  </div>
</template>

<script>
export default {
  computed: {
    descriptionData() {
      return this.$store.state.discipline.disciplineData.description;
    },
  },
};
</script>

<style lang="scss">
.tab-description {
  height: max-content;
  max-height: 100%;
  border-radius: 0 1rem 1rem 1rem;
  padding: 1.5rem 3rem;
  background-color: #f2f3f9;
  .tab-description__no-content {
    text-align: center;
  }
  a,
  div,
  li,
  p,
  .elms_title,
  .tab-description__text,
  .tab-description__title {
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $basic-black;
    margin-bottom: 1rem;
  }
  a,
  div,
  li,
  p,
  .tab-description__text {
    font-family: "ProximaNova-Regular";
  }
}
</style>
