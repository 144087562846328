<template>
  <div class="slider">
    <div
      class="slider__track"
      :style="{ transform: `translateX(-${(counter * widthElement) / 10}rem)` }"
      ref="slider-track"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      counter: 0,
      elements: [],
      widthElement: 0,
    };
  },
  watch: {
    counter(newValue) {
      this.$emit('change', newValue);
    },
  },
  methods: {
    nextSlide() {
      this.elements.length - 2 < this.counter ? null : ++this.counter;
    },
    prevSlide() {
      this.counter > 0 ? --this.counter : null;
    },
  },
  mounted() {
    this.elements = [...this.$refs['slider-track'].childNodes];
    this.widthElement = this.elements[0].clientWidth;
  },
};
</script>

<style lang="scss">
.slider {
  position: relative;
  height: 10.7rem;
  max-width: 100%;
  overflow: hidden;
  .slider__track {
    transition-duration: 0.3s;
    transition-property: transform;
    position: absolute;
    display: flex;
    height: 100%;
    width: max-content;
  }
  .slider__item {
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }
}
</style>
