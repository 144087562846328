<template>
  <div :class="[$style.syncCalendarModal, 'sync-calendar-modal']">
    <div :class="$style.header">
      <h3 :class="$style.title">
        {{ selectedItem ? selectedItem.title : "Синхронизация календаря" }}
      </h3>
    </div>

    <component
      :is="currentComponent"
      v-bind="currentProps"
      :class="$style.body"
      @select="selectedItemIdx = $event"
      @select-method="onSelectMethod"
    />

    <div v-if="!isList" :class="$style.footer">
      <button :class="[$style.backBtn, 'btn-fill']" @click="onBack">
        Назад
      </button>
      <a
        v-if="selectedItemLink.href"
        :href="selectedItemLink.href"
        :class="[$style.link, 'btn-fill']"
        target="blank"
        >{{ selectedItemLink.text }}</a
      >
      <button v-else-if="selectedItemLink.method" :class="[$style.link, 'btn-fill']" @click="selectedItemLink.method">
        {{ selectedItemLink.text }}
      </button>
    </div>
  </div>
</template>

<script>
import syncCalendarModalList from '@/components/modalContents/syncCalendarModal/syncCalendarModalList';
import syncCalendarModalInstruction from '@/components/modalContents/syncCalendarModal/syncCalendarModalInstruction';

export default {
  name: 'syncCalendarModal',

  components: {
    syncCalendarModalList,
    syncCalendarModalInstruction,
  },

  props: {
    modalData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      currentComponent: 'syncCalendarModalList',
      selectedItemIdx: null,
      selectedMethodIdx: 0,
      url: '',

      list: [
        {
          title: 'Синхронизация с Google Календарем',
          icon: 'google-calendar',
          label: 'Google Календарь',
          methods: [
            {
              label: 'Браузеры',
              value: 'browsers',
              gif: 'google_russia.gif',
              steps: [
                {
                  type: 'copy',
                  desc: '<p>Скопируйте ссылку:</p>',
                },
                {
                  type: 'text',
                  desc: `<p>Откройте Google Календарь. <br>
                Рядом с надписью <b>Другие календари</b>  нажмите <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 3a1 1 0 1 0-2 0v6H3a1 1 0 0 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6V3z" fill="currentColor"></path></svg> <br> 
                Выберите <b>Добавить по URL</b></p>`,
                },
                {
                  type: 'text',
                  desc: '<p>Вставьте ссылку.</p>',
                },
                {
                  type: 'text',
                  desc: '<p>Нажмите кнопку <b>Добавить календарь</b></p>',
                },
              ],
              link: {
                text: 'Перейти в Google календарь',
                href: 'https://calendar.google.com/calendar',
              },
            },
          ],
        },
        {
          title: 'Синхронизация с Microsoft Outlook',
          icon: 'outlook',
          label: 'Календарь Outlook',
          methods: [
            {
              label: 'Компьютеры',
              value: 'computer',
              desc: 'Если приложение Microsoft Outlook установлено на ваш компьютер:',
              steps: [
                {
                  type: 'text',
                  desc: '<p>Нажмите кнопку <b> Перейти в Microsoft Outlook.</b></p>',
                },
                {
                  type: 'text',
                  desc: '<p>Выберите <b>Outlook.</b></p>',
                },
                {
                  type: 'text',
                  desc: '<p>Откройте приложение и согласитесь с подключением нового <br> календаря.</p>',
                },
              ],
              gif: 'outlook_desktop_russia.gif',
              link: {
                text: 'Перейти в Microsoft Outlook',
                href: 'mailto:',
              },
            },
            {
              label: 'Браузеры',
              value: 'browsers',
              steps: [
                {
                  type: 'copy',
                  desc: '<p>Скопируйте ссылку:</p>',
                },
                {
                  type: 'text',
                  desc: `<p>Откройте Календарь в Outlook. <br>
                    Если Microsoft Outlook работает на сервере вашей организации,<br>
                    самостоятельно откройте календарь Outlook в браузере.</p>`,
                },
                {
                  type: 'text',
                  desc: '<p>Нажмите <b>Добавить календарь ➞ Подписаться с веб-страницы.</b></p>',
                },
                {
                  type: 'text',
                  desc: '<p>Вставьте ссылку и нажмите <b>Импортировать.</b></p>',
                },
              ],
              gif: 'outlook_web_russia.gif',
              link: {
                text: 'Перейти в Microsoft Outlook',
                href: 'https://outlook.live.com',
              },
            },
          ],
        },
        {
          title: 'Синхронизация с Яндекс.Календарем',
          icon: 'yandex-calendar',
          label: 'Яндекс календарь',
          methods: [
            {
              label: 'Браузеры',
              value: 'browsers',
              steps: [
                {
                  type: 'copy',
                  desc: '<p>Скопируйте ссылку:</p>',
                },
                {
                  type: 'text',
                  desc: `<p>Откройте Яндекс.Календарь. <br>
                Нажмите <b>Новый календарь ➞ Импорт ➞ По ссылке</b> <br>
                и введите скопированную ссылку.</p>`,
                },
                {
                  type: 'text',
                  desc: '<p>Нажмите кнопку <b>Импортировать.</b></p>',
                },
              ],
              link: {
                text: 'Перейти в Яндекс календарь',
                href: 'https://calendar.yandex.com',
              },
              gif: 'yandex_russia.gif',
            },
          ],
        },
        {
          title: 'Синхронизация с другими календарями',
          icon: 'other-calendar',
          label: 'Другой календарь',
          methods: [
            {
              label: 'Браузеры',
              value: 'browsers',
              desc: 'Вы можете синхронизировать мероприятия с любым календарем, который поддерживает стандарт iCalendar Feed.',
              steps: [
                {
                  type: 'copy',
                  desc: '<p>Используйте эту ссылку для синхронизации:</p>',
                },
              ],
              link: {
                text: 'Понятно',
                method: this.onBack,
              },
            },
          ],
        },
      ],
    };
  },

  computed: {
    isList() {
      return this.currentComponent === 'syncCalendarModalList';
    },

    selectedItem() {
      return this.list[this.selectedItemIdx] ?? null;
    },

    selectedItemLink() {
      return this.selectedItem?.methods[this.selectedMethodIdx]?.link ?? '';
    },

    currentProps() {
      if (this.isList) {
        return {
          list: this.list,
        };
      }

      const methods = this.selectedItem?.methods;

      return {
        methods,
        url: this.url,
      };
    },
  },

  watch: {
    selectedItemIdx(newValue) {
      if (newValue !== null) {
        this.currentComponent = 'syncCalendarModalInstruction';
      }
    },
  },

  async mounted() {
    try {
      const { data, status } = await this.axios.get('/api/sync-event');

      if (status === 200) {
        this.url = data.url;
      }
    } catch (e) {
      console.warn('Failed fetch [syncCalendarModal]', e);
    }
  },

  methods: {
    onBack() {
      this.currentComponent = 'syncCalendarModalList';
      this.selectedItemIdx = null;
    },

    onSelectMethod(evt) {
      this.selectedMethodIdx = evt;
    },
  },
};
</script>

<style lang="scss" module>
.syncCalendarModal {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 62.4rem;
  max-height: 72rem;
  border-radius: 1rem;
  background-color: $basic-white;

  & + :global(.m-button) {
    top: 1.8rem !important;
    right: 1.8rem !important;
  }

  .backBtn {
    padding: 1rem 1.5rem;
    color: $basic-black;
    background-color: $light-border-clr;

    &:hover {
      background-color: darken($light-border-clr, 5);
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5.6rem;
  background-color: $brand-clr-3;
  border-radius: 1rem 1rem 0 0;
}

.title {
  font-size: 2rem;
  color: $basic-black;
}

.body {
  overflow-y: auto;
  padding: 4rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-top: 0.1rem solid $base-border-clr;
}

.link {
  padding: 1rem 1.5rem;
}
</style>
