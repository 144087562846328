<template>
  <m-collapse class="m-collapse--teacher">
    <template v-slot:header>
      <div class="header__content-wrp">
        <span class="header__text">Преподаватели </span>

        <span v-if="newMsgCount" class="header__count">{{ newMsgCount }}</span>
      </div>
    </template>

    <template v-slot:body>
      <div class="body__content">
        <div
          v-for="teacher of teachers"
          :key="teacher.id"
          :class="['user-tab-item', {'user-tab-item--selected': value === teacher}]"
          @click="onSelect(teacher)"
        >
          <div class="avatar-wrp">
            <img :src="setPhoto(teacher.avatar_link)" alt="user-avatar" />
          </div>

          <span class="user-tab-item__name">{{ teacher.name }}</span>

          <p v-show="teacher.text" class="user-tab-item__text">
            {{ teacher.text }}
          </p>

          <span v-show="teacher.lastDate" class="user-tab-item__date">{{teacher.lastDate}}</span>

          <transition name="fade">
            <span
              v-if="teacher.newMsgCount > 0 && !teacher.isRead"
              class="user-tab-item__unrd-msg"
            >
            </span>
          </transition>
        </div>
      </div>
    </template>
  </m-collapse>
</template>

<script>
export default {
  props: {
    dataTab: Object,
    value: [Object, null],
  },

  computed: {
    teachers() {
      return this.dataTab?.teacherListWithMsgCount?.map((el) => ({ ...el, isRead: false })) || [];
    },

    newMsgCount() {
      return this.dataTab.teacherListWithMsgCount.reduce((qount, el) => {
        qount += el.newMsgCount;
        return qount;
      }, 0);
    },
  },

  methods: {
    setPhoto(avatar) {
      if (avatar) return avatar;
      return 'https://college.instudy.online/img/mgok/avatar/avatar.jpg';
    },

    onSelect(teacher) {
      teacher.isRead = true;
      this.$emit('select', teacher);
    },
  },
};
</script>

<style lang="scss">
.m-collapse--teacher {
  .body__content {
    padding-top: 0;
    padding-top: 1rem;
  }
  .m-collapse__body {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
  }
  .user-tab-item {
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 0.8rem;
    display: grid;
    row-gap: 0.8rem;
    column-gap: 1rem;
    grid-template-columns: max-content 1fr max-content;
    grid-template-areas:
      "avatar name date"
      "avatar text unrd";
  }
  .user-tab-item--selected {
    border: 1px solid $brand-clr-1;
  }
}
</style>
