var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-tree"},_vm._l((_vm.dataArray),function(item,idx){return _c('m-collapse',{key:idx,class:[
      _vm.isFile(item)
        ? 'm-collapse--isFile'
        : _vm.isTemplate(item)
        ? 'm-collapse--isTemplate'
        : 'm-collapse--isFolder',
    ],attrs:{"blockShowBody":item.children && item.children.length
        ? _vm.isTemplate(item)
          ? true
          : false
        : true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header__content-wrp",on:{"click":function($event){return _vm.handleClick(item)}}},[_c('m-icon',{attrs:{"name":item.icon ? item.icon : _vm.setIcon(item)}}),_c('span',{staticClass:"text-title header__text"},[_vm._v(" "+_vm._s(item.lable))]),(_vm.isFile(item))?void 0:_vm._e(),(_vm.isTemplate(item))?[_c('div')]:_vm._e()],2)]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"body__content"},[(item.children && !_vm.isTemplate(item))?[_c('m-tree',{attrs:{"dataArray":item.children}})]:_vm._e()],2)]},proxy:true}],null,true)})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }