<template>
  <div class="work-experience-modal">
    <h3 class="resume-list__header">Опыт работы</h3>
    <h4 class="resume-list__sub-header">Начало работы</h4>
    <el-date-picker
      popper-class="custom-class"
      format="dd MM yyyy"
      v-model="form.dateStart"
      type="date"
      placeholder="Выберите дату"
    >
    </el-date-picker>
    <h4 class="resume-list__sub-header">Окончание</h4>
    <el-date-picker
      v-if="!form.presentTime"
      popper-class="custom-class"
      format="dd MM yyyy"
      v-model="form.dateEnd"
      type="date"
      placeholder="Выберите дату"
    >
    </el-date-picker>
    <el-checkbox v-model="form.presentTime">По настоящее время</el-checkbox>
    <m-input
      v-model="form.organization"
      label="Организация"
      placeholder="Название компании"
    />
    <m-input v-model="form.post" label="Должность" placeholder="Должность" />
    <m-input
      v-model="form.responsibility"
      :rows="5"
      type="textarea"
      label="Обязанности на рабочем месте"
      placeholder="Опишите, что вы делали на работе."
    />
    <div class="work-experience-modal__buttons">
      <button
        @click="closeModal"
        class="resume-list__btn resume-list__btn--cancel"
      >
        Отмена
      </button>
      <button @click="saveData" class="resume-list__btn resume-list__btn--save">
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        id: null,
        dateStart: '',
        dateEnd: '',
        organization: '',
        post: '',
        responsibility: '',
        presentTime: false,
      },
    };
  },
  props: {
    job: Object,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    mormalizeDateNumber(value) {
      return value < 10 ? `0${value}` : `${value}`;
    },
    selectPresentTime(value) {
      const date = new Date();
      const month = this.mormalizeDateNumber(date.getMonth());
      const day = date.getDate();
      const year = date.getFullYear();
      const currentDate = `${day} ${month} ${year}`;
      if (value) {
        this.form.dateEnd = currentDate;
      }
    },
    saveData() {
      this.form.presentTime ? (this.form.dateEnd = '') : null;
      this.job ? null : (this.form.id = Date.now());
      this.$emit('save', this.form);
    },
  },

  beforeMount() {
    this.job ? (this.form = this.job) : null;
  },
};
</script>

<style lang="scss">
.work-experience-modal {
  @extend %resume-list;
  width: 48.5rem;
  justify-items: flex-start;
  padding: 6.4rem 5.3rem;
  .work-experience-modal__buttons {
    padding-top: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .resume-list__btn--cancel {
    justify-self: flex-start;
  }
  .resume-list__btn--save {
    justify-self: flex-end;
  }
  .resume-list__select-block {
    grid-template-columns: 2fr 1.5fr;
    .el-select:nth-child(2) {
      .el-input__inner {
        border-right: none;
      }
    }
  }
  .el-checkbox {
    margin-bottom: 1rem;
  }
}
</style>
