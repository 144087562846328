<template>
  <div class="content-reader">
    <div class="content-reader__body" v-html="modalData"></div>
  </div>
</template>

<script>
export default {
  props: {
    modalData: String,
  },
};
</script>

<style lang="scss">
.content-reader {
  width: calc(100vw - 10rem);

  height: calc(100vh - 10rem);
  background-color: $basic-white;
  border-radius: 1rem;
  padding: 2rem;
  .content-reader__body {
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      width: 1rem;
    }
  }
  u,
  strong,
  p {
    font-size: 1.4rem;
    color: $basic-black;
  }
  .m-button {
    top: 1.5rem !important;
    right: 1.5rem !important;
  }
  .start_test {
    display: flex;
    width: max-content;
    padding: 1rem;
    border-radius: 1rem;
    margin-top: 1rem;
    background-color: $brand-clr-1;
    color: $basic-white;
  }
}
@include breakPoints(md) {
  .content-reader {
    border-radius: 0;
  }
}
</style>
