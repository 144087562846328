import axios from 'axios';
import $ from "jquery";

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    token: ''
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    getRoutes(state) {
      return state.routes;
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },

    SET_USER(state, value) {
      state.user = value;
    },

    SET_TOKEN(state, token) {
      state.token = token;
    }
  },

  actions: {
    async auth () {
      try {
        const { data, status } = await axios.get('api/auth');

        if (status === 200 && data) {
          const regex = /<meta\s+name=["']csrf-token["']\s+content=["']([^"']+)["']/i;
          const match = data.match(regex);

          if (match) {
            axios.defaults.headers['X-CSRF-TOKEN'] = match[1];
          }
        }
      } catch (error) {
        console.warn('Failed fetch [store/auth/auth]', error);
      }
    },

    async prelogin(_, credentials) {
      try {
        return await axios.post('api/prelogin', credentials);
      } catch (error) {
        console.warn('Error fetch [store/auth/auth]', error);
      }
    },

    async signIn({ dispatch }, credentials) {
      try {
        await dispatch('getCsrfCookie');

        const { data } = await dispatch('login', credentials);

        if (data.ban) return data;
        return dispatch('me');
      } catch (error) {
        console.warn('Error fetch [store/auth/signIn]', error);
      }
    },

    async getCsrfCookie() {
      try {
        return await axios.post('api/login', credentials);
      } catch (error) {
        console.warn('Failed fetch [store/auth/getCsrfCookie]', error);
      }
    },

    async login(_, credentials) {
      try {
        return await axios.post('api/login', credentials);
      } catch (error) {
        console.warn()
      }
    },

    async signOut({ dispatch }) {
      await axios.post('api/logout');
      return dispatch('me');
    },

    async me({ commit }) {
      let authenticated = false;

      try {
        const { data, errors } = await axios.get('/api/user');
        if (!errors) {
          authenticated = !!data.id;
          commit('SET_AUTHENTICATED', authenticated);
          if (authenticated) {
            commit('SET_USER', data);
            await this.dispatch('getUserInfo');
            await this.dispatch('getMessages');
            await this.dispatch('getAnnounces');
          }
        }
      } catch (e) {
        console.warn('Error get user info', e);
        commit('SET_AUTHENTICATED', false);
        commit('SET_USER', null);
      } finally {
        return { status: authenticated, ban: false };
      }
    },

    async passwordRecovery(ctx, credentials) {
      try {
        const { data } = await axios.post('/api/forgot-password', credentials);
        return data;
      } catch (e) {
        console.warn('Error fetch [store/auth/passwordRecovery]', e);
      }
    },
  },
};
