<template>
  <div class="m-progress" :style="styleCompiller">
    <template v-if="isCircle">
      <span v-if="!customText" class="m-progress__percent">{{ percent }}%</span>
      <span v-else class="m-progress__text"><slot></slot></span>
      <svg class="m-progress__svg" :width="size + unit" :height="size + unit">
        <defs>
          <filter id="inset-shadow">
            <feFlood flood-color="#C0C4CC" />
            <feComposite operator="out" in2="SourceGraphic" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite operator="atop" in2="SourceGraphic" />
          </filter>
        </defs>
        <circle
          filter="url(#inset-shadow)"
          class="m-progress__circle-bg"
          :cx="center + unit"
          :cy="center + unit"
          :r="radius + unit"
          :stroke-width="strokeWidth + unit"
        />
        <circle
          class="m-progress__circle"
          :cx="center + unit"
          :cy="center + unit"
          :r="radius + unit"
          :stroke-width="strokeWidth + unit"
          :stroke-dasharray="circumference + unit"
          :stroke-dashoffset="dashOffset + unit"
        />
      </svg>
    </template>
    <template v-if="!isCircle">
      <div class="m-progress__background">
        <div
          class="m-progress__progress"
          :style="{ width: percent + '%' }"
        ></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 100,
    },
    unit: {
      type: String,
      default: 'rem',
    },
    type: {
      type: String,
      default: 'circle',
    },
    customText: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '10rem',
    },
    height: {
      type: String,
      default: '10rem',
    },
    percent: {
      type: Number,
      default: 50,
    },
    strokeWidth: {
      type: Number,
      default: 10,
    },
    circleOneStroke: {
      type: String,
      default: 'lightgrey',
    },
    circleTwoStroke: {
      type: String,
      default: 'orange',
    },
  },
  computed: {
    styleCompiller() {
      if (this.isCircle) {
        return {
          width: this.size + this.unit,
          height: this.size + this.unit,
        };
      }
    },
    isCircle() {
      return this.type === 'circle';
    },
    center() {
      return this.size / 2;
    },
    radius() {
      return this.size / 2 - this.strokeWidth / 2;
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    dashOffset() {
      return ((100 - this.percent) / 100) * this.circumference;
    },
  },
};
</script>

<style lang="scss">
.m-progress {
  border-radius: 50%;
  position: relative;
  .m-progress__percent {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .m-progress__text {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .m-progress__svg {
    width: 100%;
    transform: rotate(-90deg);
  }

  .m-progress__circle-bg {
    stroke: lightgray;
    fill: none;
  }

  .m-progress__circle {
    stroke: orange;
    fill: none;
  }
  .m-progress__background {
    border-radius: 1rem;
    width: 100%;
    height: 2rem;
    background-color: $brand-clr-3;
    overflow: hidden;
  }
  .m-progress__progress {
    transition-duration: 0.3s;
    transition-property: background-color;
    height: 100%;
    border-radius: 1rem;
    background-color: $brand-clr-1;
  }
}
</style>
