<template>
  <div class="progress-bar">
    <div
      class="svg-base"
      :style="{ width: totalCircle * 10 + 2 + 'rem', height: '10rem' }"
    >
      <div
        :style="transformStyleShadow(selectedSemester - 1)"
        class="progress-bar__shadow"
      ></div>
      <m-progress
        :id="currentSemester === element.semester ? 'current-semester' : ''"
        @click="selectSemester(element.id)"
        v-for="(element, idx) of elements"
        :style="transformStyle(idx)"
        :key="idx + 'p'"
        :class="[
          'progress-bar__inner-ring',
          { 'progress-bar__inner-ring--fill': element.percent !== 100 },
          { 'progress-bar__outer-ring--disable': element.disable },
        ]"
        :percent="element.percent"
        unit="rem"
        :size="6"
        :strokeWidth="1"
        :customText="true"
      >
        <template>
          <span
            class="m-progress__text--inner"
            @click="selectSemester(element.semester)"
            >{{ element.semester }}</span
          >
        </template>
      </m-progress>
      <div
        v-for="jumper of totalCircle"
        :key="jumper + 'j'"
        :class="['jumper', { 'jumper--disable': jumper > currentSemester - 1 }]"
        :style="{
          transform: `translate(${setJumperCoordinate(jumper).x}, ${
            setJumperCoordinate(jumper).y
          })`,
        }"
      >
        <svg
          xml:space="preserve"
          width="100%"
          height="100%"
          viewBox="0 0 500000 452000"
        >
          <g>
            <path
              v-if="jumper % 2 !== 0 && totalCircle !== jumper"
              d="M471000 497000c-49000,-220000 -344000,-278000 -471000,-92000 66000,-115000 97000,-273000 79000,-405000 48000,220000 343000,277000 470000,91000 -65000,116000 -96000,274000 -78000,406000z"
            />

            <path
              v-else-if="jumper % 2 === 0 && totalCircle !== jumper"
              d="M75000 473000c46000,-210000 326000,-264000 448000,-88000 -63000,-109000 -92000,-259000 -75000,-385000 -46000,209000 -327000,264000 -448000,87000 63000,110000 92000,260000 75000,386000z"
            />
          </g>
        </svg>
      </div>
      <div
        v-for="circle of totalCircle"
        :key="circle"
        :class="['circle-svg', { 'circle-svg--disable': checkDisable(circle) }]"
        :style="{
          transform: `translate(${setCircleCoordinat(circle).x}, ${
            setCircleCoordinat(circle).y
          })`,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    elements: Array,
    currentSemester: [Number, null],
    selectedSemester: Number,
    totalCircle: Number,
  },
  methods: {
    setPlug() {
      if (!+this.currentSemester) return 'plug5';
      return `plug${6 - +this.currentSemester}`;
    },
    selectSemester(semester) {
      if (semester > this.currentSemester) return;
      this.$emit('select', semester);
    },
    checkDisable(semester) {
      return semester > this.currentSemester;
    },
    setCircleCoordinat(idx) {
      return {
        y: idx % 2 !== 0 ? '0' : '2rem',
        x: `${(idx - 1) * 10.4}rem`,
      };
    },
    setJumperCoordinate(idx) {
      return {
        x: `${idx * 8 - 0.65 + (idx - 1) * 2.41}rem`,
        y: idx % 2 === 0 ? '3.42rem' : '3.4rem',
        rotate: idx % 2 === 0 ? '0 50 50' : '180 50 50',
      };
    },
    transformStyleShadow(idx) {
      return {
        top: '.25rem',
        left: '.25rem',
        transform: `translateX(${idx * 10.4}rem) translateY(${
          idx % 2 === 0 ? '0' : '2rem'
        })`,
      };
    },
    transformStyle(idx) {
      return {
        left: '1rem',
        transform: `translateX(${idx * 10.4}rem) translateY(${
          idx % 2 === 0 ? '1rem' : '3rem'
        })`,
      };
    },
  },
  mounted() {
    const target = document.querySelector('#current-semester');
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  },
};
</script>

<style lang="scss">
.progress-bar {
  padding: 1rem;
  overflow-x: auto;
  width: 100%;
  .svg-base {
    width: 100%;
    height: 10rem;
    position: relative;
  }
  .circle-svg {
    position: absolute;
    width: 8rem;
    height: 8rem;
    border: 1rem solid $brand-clr-1;
    border-radius: 50%;
  }
  .circle-svg--disable {
    border-color: #cccccc;
  }
  .jumper {
    position: absolute;
    width: 3.46rem;
    height: 3.15rem;
    svg {
      fill: $brand-clr-1;
      width: 100%;
    }
  }
  .jumper--disable {
    svg {
      fill: #cccccc;
    }
  }
  .m-progress {
    position: absolute;
  }
  // padding: 1rem;
  // position: relative;
  // width: 100%;
  // overflow-x: auto;
  // .svg-base {
  //   width: 100%;
  //   height: 10rem;
  //   fill: $brand-clr-1;
  //   position: relative;
  //   z-index: 2;
  // }
  // .circle-svg {
  //   width: 8rem;
  //   height: 8rem;
  //   fill: none;
  //   stroke: #7545c9;
  //   stroke-width: 1rem;
  // }
  // .graph__plug {
  //   position: absolute;
  //   z-index: 20;
  //   right: 0;
  //   bottom: 0;
  //   fill: #cccccc;
  // }
  // .progress-bar__outer-ring {
  //   border: 0.9rem solid $brand-clr-1;
  //   border-radius: 50%;
  // }
  .progress-bar__inner-ring {
    z-index: 10;
    cursor: pointer;
    position: absolute;
    .m-progress__circle-bg {
      fill: $basic-white;
    }
    .m-progress__circle {
      stroke: $success-clr;
    }
    .m-progress__circle-bg {
      stroke: $basic-white;
    }
  }
  .m-progress__svg {
    position: relative;
  }
  .m-progress__text {
    position: absolute;
    z-index: 2;
    font-size: 2.2rem;
    font-family: "ProximaNova-Regular";
    color: $brand-clr-1;
  }
  .m-progress__text--inner {
    font-size: inherit;
    font-family: inherit;
    width: 100%;
    height: 100%;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
  }
  .progress-bar__inner-ring--fill {
    .m-progress__circle-bg {
      fill: $brand-clr-1;
    }
    .m-progress__text {
      color: $basic-white;
    }
  }
  .progress-bar__outer-ring--disable {
    border-color: #cccccc;
    .m-progress__circle-bg {
      fill: #cccccc;
    }
  }

  .progress-bar__shadow {
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
    width: 7.5rem;
    height: 7.5rem;
    z-index: 3;
    box-shadow: 0px 0px 1rem 0.4rem #7545c9;
  }
}
</style>
