<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    class="xd476a__calendar-icon"
  >
    <circle cx="20" cy="20" r="20" fill="#3CB46E"></circle>
    <rect
      x="9"
      y="11"
      width="22"
      height="19"
      rx="2.57144"
      stroke="white"
      stroke-width="2"
    ></rect>
    <path
      d="M9 13.5714C9 12.1513 10.1513 11 11.5714 11H28.4286C29.8487 11 31 12.1513 31 13.5714V16H9V13.5714Z"
      stroke="white"
      stroke-width="2"
    ></path>
    <path
      d="M13 9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9V10H13V9Z"
      fill="white"
    ></path>
    <path
      d="M25 9C25 8.44772 25.4477 8 26 8C26.5523 8 27 8.44772 27 9V10H25V9Z"
      fill="white"
    ></path>
    <rect x="12" y="19" width="4" height="3" rx="0.714287" fill="white"></rect>
    <rect x="12" y="24" width="4" height="3" rx="0.714287" fill="white"></rect>
    <rect x="18" y="24" width="4" height="3" rx="0.714287" fill="white"></rect>
    <rect x="18" y="19" width="4" height="3" rx="0.714287" fill="white"></rect>
    <rect
      x="23.7144"
      y="19"
      width="4"
      height="3"
      rx="0.714287"
      fill="white"
    ></rect>
  </svg>
</template>

<script>
export default {
  name: 'otherCalendar',
};
</script>
