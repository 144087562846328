<template>
  <button
    @click="$emit('click')"
    :class="['m-button', 'm-button--' + type, { 'button-no-border': noBorder }]"
  >
    <slot></slot>
  </button>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default',
    },
    noBorder: Boolean,
  },
};
</script>
<style lang="scss">
@mixin button {
  font-family: "Roboto-Light";
  outline: none;
  transition-duration: 0.3s;
  transition-property: background-color, border-color, color;
  cursor: pointer;
  border: 0.1rem solid $placeholder-text-clr;
  width: max-content;
  border-radius: 0.5rem;
  padding: 1rem;
  background-color: $basic-white;
}
.m-button--default {
  @include button;
  color: $regular-text-clr;
  &:hover {
    border-color: rgba($brand-clr-1, 30%);
    background-color: rgba($brand-clr-1, 10%);
    color: $brand-clr-1;
  }
  &:active {
    transition-duration: 0;
    border-color: $brand-clr-1;
  }
}
.m-button--primary {
  @include button;
  background-color: $brand-clr-1;
  color: $basic-white;
  border-color: transparent;
  &:hover {
    background-color: lighten($brand-clr-1, 10%);
  }
  &:active {
    background-color: darken($brand-clr-1, 10%);
  }
}
.button-no-border {
  border: none;
}
</style>
