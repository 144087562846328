<template>
  <div class="online-course-tab">
    <div v-html="value" v-if="value"></div>
    <!-- <h3 class="online-course-tab__title">
      Добро пожаловать на курс «Детская и возрастная психология»
    </h3> -->
    <!-- <p  class="online-course-tab__text">
      Современная психология представляет собой сильно разветвленную систему
      научных дисциплин. Каждая из отраслей психологии связана с анализом
      своеобразной области практики и конкретных видов деятельности, в центре
      которых всегда находится человек и система его отношений с миром вещей,
      людей, с самим собой. Среди этих разнообразнейших дисциплин особое место
      занимает психология развития, связанная с изучением общей динамики
      психического развития, онтогенеза психических процессов и, в частности,
      психологических характеристик качественно изменяющегося во времени
      человека.
    </p> -->
    <!-- <router-link class="online-course-tab__link" to="#"
      >Пройти курс</router-link
    > -->
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
};
</script>

<style lang="scss">
.online-course-tab {
  padding: 1.6rem 2.9rem;
  border-radius: 1rem;
  background-color: #f2f3f9;
  .online-course-tab__text,
  .online-course-tab__title {
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $basic-black;
    margin-bottom: 1.5rem;
  }
  .online-course-tab__text {
    font-family: "ProximaNova-Regular";
  }

  .start_test,
  .online-course-tab__link {
    width: 19rem;
    height: 4rem;
    border-radius: 1rem;
    color: $basic-white;
    font-family: "Gilroy-Bold";
    background-color: $brand-clr-1;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
