import Vue from 'vue';
import VueRouter from 'vue-router';

// Pages
import Auth from '@/views/Auth';
import Home from '@/views/Home';
import Schedule from '@/views/schedule';
import EducationalProcess from '@/views/EducationalProcess';
import Messages from '@/views/Messages';
import Deanery from '@/views/Deanery';
import page404 from '@/views/404';
import discipline from '@/views/EducationalProcess/discipline';
import Payment from '@/views/Payment';
import testDiscipline from '../views/EducationalProcess/test';
import middlewarePipeline from './middlewarePipeline';
import store from '../store';
import guardian from './middleware/guardian';

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/404',
    meta: {
      navigation: false,
      fullScreen: true,
    },
  },
  {
    path: '/404',
    component: page404,
    meta: {
      navigation: false,
      fullScreen: false,
      middleware: [guardian],
      roles: ['*'],
    },
  },
  {
    path: '/',
    redirect: '/home',
    meta: {
      middleware: [guardian],
    },
  },
  {
    path: '/home',
    component: Home,
    meta: {
      navigation: true,
      icon: 'home',
      title: 'Главная',
      fullScreen: false,
      middleware: [guardian],
      roles: ['*'],
    },
  },
  {
    path: '/schedule',
    component: Schedule,
    meta: {
      navigation: true,
      icon: 'calendar',
      title: 'Расписание',
      fullScreen: false,
      middleware: [guardian],
      roles: ['*'],
    },
  },

  {
    path: '/educational-process',
    component: EducationalProcess,
    meta: {
      navigation: true,
      icon: 'progress-circle',
      title: 'Учебный процесс',
      fullScreen: false,
      middleware: [guardian],
      roles: [1],
      children: [
        {
          component: discipline,
        },
      ],
    },
  },
  {
    path: '/educational-process/discipline/:did',
    name: 'discipline',
    component: discipline,
    props: true,
    meta: {
      navigation: false,
      fullScreen: false,
      middleware: [guardian],
      roles: [1],
    },
  },
  {
    path: '/educational-process/discipline/test/:tid',
    component: testDiscipline,
    meta: {
      navigation: false,
      fullScreen: false,
      middleware: [guardian],
      roles: [1],
    },
  },

  {
    path: '/messages',
    component: Messages,
    meta: {
      navigation: true,
      icon: 'message',
      title: 'Сообщения',
      fullScreen: false,
      middleware: [guardian],
      roles: ['*'],
    },
  },
  {
    path: '/deanery',
    component: Deanery,
    meta: {
      navigation: true,
      icon: 'files',
      title: 'Деканат',
      fullScreen: false,
      middleware: [guardian],
      roles: ['*'],
    },
  },
  // {
  //   path: "/payment",
  //   component: Payment,
  //   meta: {
  //     navigation: true,
  //     icon: "hand-pay",
  //     title: "Оплата обучения",
  //     fullScreen: false,
  //     middleware: [guardian],
  //     roles: ["*"],
  //   },
  // },
  {
    path: '/auth',
    component: Auth,
    name: 'Auth',
    meta: {
      navigation: true,
      icon: 'logout',
      title: 'Выход',
      fullScreen: true,
      roles: ['*'],
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const { middleware } = to.meta;

  const context = {
    to,
    from,
    next,
    store,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
