<template>
  <nav :class="['navigation', { 'navigation--show': showMenuFunc }]">
    <button @click="showMenu = !showMenu" class="mobile-menu__btn">
      <div :class="['mobile-menu__icon', { iconActive: showMenu }]"></div>
    </button>

    <m-icon class="nav-logo nav-logo--mobile" name="logo-is-2.0" />

    <div
      v-if="mobileMode"
      @click="showUserInfo = !showUserInfo"
      :class="['user__avatar', { 'user__avatar--active': showUserInfo }]"
    >
      <img class="user__img" :src="setPhoto" alt="avatar" />
    </div>
    <transition name="slide-fade-right">
      <div v-if="showUserInfoFunc" class="navigation__user-info">
        <userTab />
        <curatorTab />
        <socialsTab />
      </div>
    </transition>
    <transition name="slide-fade-left">
      <ul v-if="showMenuFunc" class="navigation__list">
        <waves v-if="mobileMode" />
        <li
          :class="[
            'navigation__item--logo',
            { 'mini-visible': !navigationMax },
          ]"
        >
          <m-icon class="nav-logo" name="logo-is-2.0" />
        </li>

        <li
          @click="showMenu = false"
          :class="[
            'navigation__item',
            { 'mini-visible': !navigationMax },
            { 'item-active': checkCurrentRoute(route.path) },
            { 'navigation__item--penultimate': index === routes.length - 2 },
          ]"
          v-for="(route, index) of routes"
          :key="index"
        >
          <button
            v-if="route.path === '/home'"
            @click="menuToggle"
            class="btn-hide-text"
          >
            <i
              :class="[
                'fas fa-chevron-left',
                { 'icon-rotate': !navigationMax },
              ]"
            ></i>
          </button>

          <router-link class="navigation__link" :to="route.path">
            <div class="icon-wrp">
              <el-badge :hidden="route.path !== '/messages' || !unreadMessages " :value="unreadMessages" class="navigation__badge">
                <icon :name="route.meta.icon" />
              </el-badge>
            </div>
            <transition name="collapse">
              <span class="text-title" v-if="navigationMax">{{
                route.meta.title
              }}</span>
            </transition>
          </router-link>
        </li>
        <li
          @click="logOut()"
          :class="[
            'navigation__item',
            'navigation__item--last',
            { 'mini-visible': !navigationMax },
            { 'item-active': checkCurrentRoute('/auth') },
          ]"
        >
          <div class="navigation__link">
            <div class="icon-wrp">
              <icon name="logout" />
            </div>
            <transition name="collapse">
              <span class="text-title" v-if="navigationMax">Выход</span>
            </transition>
          </div>
        </li>
      </ul>
    </transition>
  </nav>
</template>
<script>
import waves from '@/components/ui/waves';
import userTab from '@/components/asideBar/userTab';
import curatorTab from '@/components/asideBar/curatorTab';
import { resetState } from '@/store/index.js';
import icon from '@/components/ui/icon/index';
import { mapActions, mapMutations, mapState } from 'vuex';
import socialsTab from '@/components/asideBar/socialsTabs';

export default {
  name: 'navigation',
  data() {
    return {
      showUserInfo: false,
      showMenu: false,
      descriptionShow: true,
    };
  },
  components: {
    icon,
    userTab,
    waves,
    curatorTab,
    socialsTab
  },
  computed: {
    ...mapState({
      unreadMessages: (state) => state.unreadMessages,
    }),
    showUserInfoFunc() {
      if (this.mobileMode) return this.showUserInfo;
      return false;
    },
    studentInfo() {
      return this.$store.state.userInfo;
    },
    isTestUser() {
      return ['21821'].includes(this.$store.state.auth.user.uInstudy_id);
    },
    setPhoto() {
      if (this.studentInfo.avatar_link) return this.studentInfo.avatar_link;
      return 'https://college.instudy.online/img/mgok/avatar/avatar.jpg';
    },
    showMenuFunc() {
      if (this.mobileMode) return this.showMenu;
      return true;
    },

    navigationMax() {
      return this.$store.state.navigationMax;
    },

    currentRoute() {
      return this.$route.path;
    },

    mobileMode() {
      return this.$store.state.mobileMode;
    },

    routes() {
      return this.$router.options.routes.filter((el) => {
        if (el.path === '/auth' || (this.isTestUser && el.path === '/deanery')) return false;
        return el.meta.navigation;
      });
    },
  },
  watch: {
    showUserInfo(newValue) {
      this.setShowAside(newValue);
    },
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      checkMode: 'checkMode',
    }),
    ...mapMutations(['setNavigationMax', 'setShowAside']),
    menuToggle() {
      this.descriptionShow = !this.descriptionShow;

      this.setNavigationMax(this.descriptionShow);
    },
    async logOut() {
      await this.signOut().then(() => {
        resetState();
        this.checkMode(window.innerWidth);
        this.$router.push('/auth');
      });
    },
    checkCurrentRoute(path) {
      return (
        this.currentRoute === path
        || this.currentRoute.split('/')[1] === path.split('/')[1]
      );
    },
  },
};
</script>
<style lang="scss">
.icon-wrp {
  width: 2rem;
  height: max-content;
  background-color: inherit;
}
.m-icon {
  fill: $basic-black;
  color: inherit;
  z-index: 5;
  width: 2.3rem;
}
.navigation {
  width: 100%;
  height: 100%;
  padding-right: 3rem;

  .text-title {
    transition-duration: 0s;
  }

  .navigation__badge {
    .el-badge__content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1rem;
      min-width: 1rem;
      padding: .1rem .2rem;
      background-color: $brand-clr-1;
      border: none;
      font-size: .8rem;
      line-height: 1rem;
    }
  }

  .user__avatar {
    transition-property: border-color;
    transition-duration: 0.3s;
    border: 2px solid $brand-clr-1;
    position: absolute;
    top: 50%;
    margin-top: -2.5rem;
    right: 2rem;
    grid-area: avatar;
    display: none;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    overflow: hidden;
    align-self: flex-start;
    z-index: 14;
  }
  .user__avatar--active {
    border-color: $brand-clr-1;
  }
  .navigation__user-info {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
    right: 0;
    height: 100%;
    padding: 8.8rem 2rem 2rem 2rem;
    position: fixed;
    background-color: white;
    z-index: 10;
  }
  .waves-backgound {
    position: absolute;
    bottom: 0;
    transform: translateX(-27rem);
    width: 97.9rem;
    height: 83rem;
    stroke: #f4f4f6;
    stroke-width: 0.2rem;
  }
}
.btn-hide-text {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #f1f0ff;
  right: 0;
  margin-right: -4.5rem;
  top: 50%;
  margin-top: -1.5rem;
  z-index: 5;
  i {
    transition-duration: 0.3s;
    font-weight: bold;
    color: $basic-black;
    font-size: 1.4rem;
  }
  &:hover {
    i {
      color: $brand-clr-1;
    }
  }
}
.icon-rotate {
  transform: rotate(180deg);
}
.navigation__list {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 9rem repeat(7, 4rem) 1fr 3.5rem;
  height: 100%;
  row-gap: 1.3rem;
  align-items: center;
  padding-bottom: 4rem;
}
.nav-logo {
  transition-duration: 0.3s;
  width: 8rem;
  display: block;
  margin: 0 auto;
  fill: $brand-clr-1;
}
.navigation__item {
  transition-duration: 0.3s;
  position: relative;
  text-align: center;
  color: $basic-black;
  padding-left: 3.5rem;
  width: 21rem;
  border-radius: 0 1rem 1rem 0;
}
.navigation__item--logo {
  padding-left: 3rem;
}
.navigation__item--penultimate {
  // align-self: flex-end;
  // grid-row-start: -2;
}
.navigation__item--last {
  align-self: flex-end;
  grid-row-start: -1;
}

.item-active {
  background-color: $basic-black;
  color: $basic-white;
  height: 4rem;
  border-radius: 0 1rem 1rem 0;
  .m-icon {
    fill: $basic-white;
  }
  &:hover {
    .m-icon {
      fill: $basic-white !important;
    }
    span {
      color: $basic-white !important;
    }
  }
}

.navigation__link {
  color: inherit;
  position: relative;
  display: flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  border-radius: 0 1rem 1rem 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  i {
    width: 2rem;
    font-size: 2rem;
    color: inherit;
    position: relative;
    z-index: 5;
    margin-right: 1.5rem;
  }
  span {
    color: inherit;
    text-align: left;
    display: flex;
    height: 100%;
    align-items: center;
    overflow: hidden;
  }
  &:hover {
    .m-icon {
      fill: $brand-clr-1;
    }
    span {
      color: $brand-clr-1;
    }
  }
}
.mini-visible {
  width: 8.5rem;
  padding-left: 2rem;
  .icon-wrp {
    margin: 0 auto;
  }
  .nav-logo {
    width: 6rem;
    margin-left: auto;
    margin-right: 0;
  }
}
.hide-text {
  opacity: 0;
  transform: translateX(-200%);
}
.mobile-menu__btn {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  left: 2rem;
  background-color: $basic-white;
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 30px;
  cursor: pointer;
  transition: 0.4s;
  padding: 0;
  z-index: 10;
  grid-area: btn;
  top: 50%;
  margin-top: -1.5rem;
}
.mobile-menu__icon {
  display: block;
  position: relative;
  background: $brand-clr-1;
  width: 50%;
  height: 0.4rem;
  transition: 0.4s;
}
.mobile-menu__icon::after,
.mobile-menu__icon::before {
  content: "";
  display: block;
  position: absolute;
  background: $brand-clr-1;
  width: 130%;
  height: 0.4rem;
  transition: 0.4s;
}
.mobile-menu__icon::after {
  top: 8px;
}
.mobile-menu__icon::before {
  top: -8px;
}
.iconActive {
  background: transparent;
}
.iconActive::before,
.iconActive::after {
  top: 0;
}
.iconActive::after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.iconActive::before {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.nav-logo--mobile {
  transition-duration: 0.3s;
  position: absolute;
  display: none;
  z-index: 20;
  grid-area: logo;
  width: 8rem;
  height: 5.2rem;
  top: 50%;
  margin-right: -4rem;
  right: 50%;
  margin-top: -2.6rem;
}
@include breakPoints(md) {
  .navigation {
    position: relative;
    background-color: $basic-white;
    padding: 0;
    height: 8.8rem;
    .navigation__list {
      grid-template-rows: repeat(7, 4rem) 1fr 3.5rem;
      padding-top: 8.8rem;
      width: 100%;
      height: 100%;
      z-index: 15;
      position: fixed;
      background-color: $basic-white;
    }
    .navigation__item--logo {
      display: none;
    }
    .user__avatar {
      display: flex;
    }
    .user-tab {
      .user__avatar {
        display: none;
      }
    }
  }
  .navigation--show {
    .nav-logo--mobile {
      right: calc(100vw - 6rem);
    }
    .mobile-menu__btn {
      z-index: 20;
      left: calc(100vw - 5rem);
    }
  }
  .btn-hide-text {
    display: none;
  }
  .mobile-menu__btn {
    display: flex;
  }
  .nav-logo--mobile {
    display: block;
  }
}
</style>
