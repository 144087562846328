<template>
  <div class="curator-tab">
    <span class="text-title">Куратор</span>
    <div class="curator-tab__content">
      <div class="user__avatar--curator">
        <img :src="setPhoto()" alt="curator-avatar" />
      </div>
      <div class="curator-tab__description">
        <span style="align-self: flex-start">{{ getCuratorInfo().name }}</span>
        <span>{{ getCuratorInfo().email }}</span>
        <span>{{ getCuratorInfo().phone }}</span>
      </div>
    </div>
    <router-link v-if="messagesStatus" class="curator-tab__btn" to="/messages"
      >Отправить сообщение</router-link
    >
    <waves />
  </div>
</template>

<script>
import waves from '@/components/ui/waves';
import { mapGetters } from 'vuex';

export default {
  computed: {
    messagesStatus() {
      return this.$store.state.meaages;
    },
  },
  components: {
    waves,
  },
  methods: {
    ...mapGetters(['getCuratorInfo']),
    setPhoto() {
      if (this.getCuratorInfo().photo) return `data:image/png;base64,${this.getCuratorInfo().photo}`;
      return 'https://college.instudy.online/img/mgok/avatar/avatar.jpg';
    },
  },
};
</script>

<style lang="scss">
.curator-tab {
  display: grid;
  width: 100%;
  overflow: hidden;
  .curator-tab__content {
    background-color: $basic-white;
    padding: 1.6rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .curator-tab__description {
    display: grid;
    row-gap: 0.5rem;
    grid-template-columns: 1fr;

    span {
      font-size: 0.9rem;
      font-family: "ProximaNova-Regular";
      color: $brand-clr-1;
    }
    span:first-child {
      font-weight: bold;
      color: $basic-black;
      font-size: 1rem;
    }
  }
  .user__avatar--curator {
    display: flex;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 1rem;
    overflow: hidden;
    align-self: flex-start;
    margin-right: 1rem;
    img {
      width: 5rem;
      height: max-content;
    }
  }
  .curator-tab__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 0.3s;
    transition-property: color;
    background-color: $basic-black;
    width: 100%;
    height: 4rem;
    color: $basic-white;
    font-size: 1.4rem;
    font-family: "Gilroy-Bold";
    border-radius: 0 0 1rem 1rem;
    &:hover {
      color: $brand-clr-1;
    }
  }
  .waves-backgound {
    position: absolute;
    bottom: 0;
    transform: translateX(-27rem) translateY(40rem) rotate(-45deg) !important;
    width: 97.9rem;
    height: 83rem;
    z-index: -1;
    stroke: #f4f4f6;
    stroke-width: 0.1rem;
  }
}
@include breakPoints(md) {
  .curator-tab {
    position: relative;
    .curator-tab__content {
      background-color: transparent;
    }
    .curator-tab__description {
      span:first-child {
        font-size: 1.5rem;
      }
      span {
        font-size: 1.4rem;
      }
    }
  }
}
</style>
