<template>
  <div class="messages">
    <transition name="slide-fade-left">
      <chat-list v-show="showChatList" />
    </transition>
    <transition name="slide-fade-right">
      <chat v-show="showChat" @close="dragStart = false" />
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import chatList from './chatList/index.vue';
import chat from './chat';

export default {
  data() {
    return {
      dragStart: false,
    };
  },
  components: {
    'chat-list': chatList,
    chat,
  },
  computed: {
    mobileMode() {
      return this.$store.state.mobileMode;
    },
    selectedChat() {
      return this.$store.state.messages.selectedChat;
    },
    showChatList() {
      if (!this.mobileMode) return true;
      return !this.selectedChat;
    },
    showChat() {
      if (!this.mobileMode) return true;
      return Boolean(this.selectedChat);
    },
  },
  methods: {
    ...mapActions({
      getMessages: 'messages/getMessages',
    }),
  },
  beforeMount() {
    this.getMessages();
  },
};
</script>

<style lang="scss">
.messages {
  display: grid;
  grid-template-columns: 1fr 60%;
  background-color: $basic-white;
  height: 100%;
  border-radius: 3rem 3rem 0 0;
  overflow: hidden;
}

@include breakPoints(md) {
  .messages {
    grid-template-columns: 1fr;
    border-radius: 0;
  }
}
</style>
