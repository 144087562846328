<template>
  <div class="m-input-phone">
    <span class="m-input__label" v-if="label">{{ label }}</span>
    <div class="wrp-input">
      <el-select
        v-model="codeId"
        placeholder="Select"
        popper-class="resume-list__popper"
        class="m-input__code-select"
        @change="changeCode(codeId)"
      >
        <el-option-group v-for="(group, idx) in phoneCodesArray()" :key="idx">
          <el-option
            v-for="item in group.options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <div class="m-input__phone-code">
              <img
                width="22"
                :src="'https://' + item.flag"
                alt="flag-country"
              />
              <span>{{ item.dial_code_visible }}</span>
              <span>{{ item.name }}</span>
            </div>
          </el-option>
        </el-option-group>
      </el-select>
      <div class="m-input__code-input">
        <img
          width="22"
          :src="'https://' + currentCode.flag"
          alt="flag-country"
        />
        <span>{{ currentCode.dial_code }}</span>
      </div>
      <input
        @input="changeCode"
        maxlength="14"
        type="tel"
        class="item__input"
        :value="tel"
        v-imask="mask"
        @accept="onAccept"
        @complete="onComplete"
        :placeholder="mask.mask"
        :class="[
          'm-input__input',
          { 'icon-input-isLeft': iconPosition === 'left' },
          {
            'icon-input-isRight':
              iconPosition === 'right' || iconPosition === '',
          },
        ]"
      />
    </div>
  </div>
</template>

<script>
import phoneCodes from '@/data/phone_codes.json';
import { IMaskDirective } from 'vue-imask';

export default {
  name: 'm-input-phone',
  data() {
    return {
      codeId: 168,
      options: [],
      tel: '',
      mask: {
        mask: '(000)000-00-00',
      },
    };
  },
  directives: {
    imask: IMaskDirective,
  },
  props: {
    cols: {
      type: Number,
      default: 30,
    },
    rows: {
      type: Number,
      default: 10,
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
    value: Object,
    placeholder: String,
    label: String,
    labelPosition: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentCode() {
      const result = phoneCodes.find((el) => el.id === this.codeId);
      return result || phoneCodes[1];
    },
    classCompiller() {
      if (this.labelPosition === 'left') {
        return 'm-input--label-left';
      } if (this.labelPosition === 'right') {
        return 'm-input--label-right';
      }
      return 'm-input';
    },
    iconClass() {
      let classIcon = '+7';
      if (this.iconPosition === 'right') {
        classIcon = 'm-input__icon--isRight';
      }
      if (this.iconPosition === 'left') {
        classIcon = 'm-input__icon--isLeft';
      }
      return classIcon;
    },
  },
  methods: {
    onAccept(e) {
      const maskRef = e.detail;
      this.tel = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail;
    },
    phoneCodesArray() {
      const filterValues = ['RU', 'BY', 'UA', 'KZ'];
      let clonePhoneCodes = [...phoneCodes];
      const groupTop = [];
      filterValues.forEach((code) => {
        const result = clonePhoneCodes.find((el) => el.iso_code === code);
        result ? groupTop.push(result) : '';
        clonePhoneCodes = clonePhoneCodes.filter((el) => el.iso_code !== code);
      });
      return [{ options: groupTop }, { options: clonePhoneCodes }];
    },
    changeCode(value) {
      this.$emit('input', {
        code: this.currentCode.dial_code,
        number: this.value.number,
      });
      this.currentCode.mask ? (this.mask.mask = this.currentCode.mask) : null;
      this.$emit('change', this.currentCode);
    },
  },
  mounted() {
    this.value.code ? (this.code = this.value.code) : null;
  },
};
</script>

<style lang="scss">
.m-input-phone {
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  .wrp-input {
    padding-left: 1.6rem;
    position: relative;
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 0.5rem;
    font-size: 1.4rem;
    background: #f7f5fa;
    background: linear-gradient(90deg, #f7f5fa 0%, #e4deee 100%);
    border: 1px solid $basic-white;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgb(117 69 201 / 10%);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 10px;
  }
  .m-input__code-select {
    position: absolute;
    max-width: 7rem;
    opacity: 0;
  }
  .m-input__code-input {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    column-gap: 0.5rem;
    img {
      margin-top: -0.3rem;
    }
    span {
      color: $basic-black;
      font-size: 1.4rem;
      font-family: "ProximaNova-Regular";
    }
  }
  .m-input__input {
    border: none;
    background: none;
    padding-left: 0;
    color: $basic-black;
    font-size: 1.4rem;
    font-family: "ProximaNova-Regular";
  }
}
.m-input__phone-code {
  align-items: center;
  display: grid;
  grid-template-columns: max-content 1fr 10fr;
  column-gap: 1rem;
}
</style>
