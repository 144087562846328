import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import helpers from '@/helpers';
import Vuelidate from 'vuelidate';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU';
import moment from 'moment';

import '@/assets/style/style.scss';
import 'normalize.css';
import 'simplebar-vue/dist/simplebar.min.css';
import 'element-ui/lib/theme-chalk/index.css';
import setScripts from '@/plugins/setScripts';

// Импорт глобальных компонентов
import icon from '@/components/ui/icon';
import progress from '@/components/ui/progress';
import validate from '@/components/ui/validate';
import checkbox from '@/components/ui/checkbox';
import inputPhone from '@/components/ui/inputPhone';
import tree from '@/components/ui/tree';
import paginationButtons from '@/components/ui/paginationButtons';
import VueMobileDetection from 'vue-mobile-detection';
import collapse from './components/ui/collapse';
import select from './components/globalComponents/select';
import input from './components/ui/input';
import button from './components/globalComponents/button';
import store from './store';

axios.interceptors.request.use(
  (config) => {
    store.commit('setPreloaderRun', true);
    return config;
  },
  (error) => {
    store.commit('setPreloaderRun', false);
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    store.commit('setPreloaderRun', false);
    return response;
  },
  (error) => {
    store.commit('setPreloaderRun', false);
    return Promise.reject(error);
  },
);

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://spo.instudy.online/';
axios.defaults.proxy = {
  protocol: 'https',
  host: 'test.ru',
  port: 9000,
}
Vue.prototype.axios = axios;
Vue.prototype.$moment = moment;
// Кастомные хелперы, которые могут вызываться в любом компоненте с помощью this.$helpers, подробнее в папке helpers
Vue.prototype.$helpers = helpers;

// Глобальная регистрация компонентов
Vue.component('m-button', button);
Vue.component('m-input', input);
Vue.component('m-select', select);
Vue.component('m-collapse', collapse);
Vue.component('m-icon', icon);
Vue.component('m-progress', progress);
Vue.component('m-validate', validate);
Vue.component('m-checkbox', checkbox);
Vue.component('m-input-phone', inputPhone);
Vue.component('m-tree', tree);
Vue.component('pagination-buttons', paginationButtons);
// Vue.component("simple-bar", simplebar);

Vue.use(ElementUI, { locale });
Vue.use(Vuelidate);
Vue.use(VueMobileDetection);

Vue.config.productionTip = false;
const isDev = process.env.NODE_ENV === 'development';

!isDev && setScripts();

(async() => {
  await store.dispatch('auth/auth');
  await store.dispatch('auth/me');

  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount('#app');
})();
