import axios from 'axios';

export default {
  namespaced: true,
  state: {
    disciplineData: {},
    courseWorkList: [],
  },
  mutations: {
    setMaterials(state, value) {
      state.disciplineData = value;
    },

    setCourseWorkList(state, value) {
      state.courseWorkList = value;
    },
  },
  actions: {
    async getMaterials({ commit }, data) {
      return await axios.post('api/info-discipline', data).then((res) => {
        if (res.data.status) {
          commit('setMaterials', res.data);
          return res.data;
        }
      });
    },

    async getCourseWorkList({ commit }) {
      try {
        const { data, status } = await axios.get('/api/topic-list');

        if (status === 200) {
          commit('setCourseWorkList', data?.topicsList ?? []);
        }
      } catch (e) {
        console.warn('Failed fetch', e);
      }
    },
  },
};
