<template>
    <div class="sending-status">
        <h3 class="resume-list__header" v-html="status.title"></h3>
        <div class="pulse-wrp">
            <div :class="{'pulse': status.done}">
                <m-icon :name="status.done ? 'success-icon': 'warning-icon'"/>
            </div>
        </div>
    </div>
  </template>

<script>

export default {
  name: 'SendingStatus',

  props: {
    status: {
      type: Object,
      default: () => ({
        title: '',
        done: true,
      }),
    },
  },
};
</script>

  <style lang="scss">
    .sending-status {
        @extend %resume-list;
        margin: 0 auto;
        justify-items: flex-start;
        padding-left: 5.3rem;
        padding-right: 5.3rem;
        width: 100%;
        max-width: 50rem;
        .resume-list__header {
            justify-self: center;
            text-align: center;
        }

        .m-icon {
            width: 4rem;
            height: 4rem;

            &--success-icon {
                fill: $success-clr;
            }

            &--warning-icon {
                fill: $danger-clr;
            }
        }

        .pulse-wrp {
            justify-self: center;
            position: relative;
            width: 4rem;
            height: 4rem;
        }
    }
  </style>
