import axios from 'axios';

export default {
  namespaced: true,

  state: {
    selectedDay: null,
    events: [],
    eventsForTheMonth: [],
    month: false,
  },
  mutations: {
    setSelectedDay(state, value) {
      state.selectedDay = value;
    },
    setSchedule(state, value) {
      state.events = value;
    },
    setMonthlySchedule(state, value) {
      state.month = true;
      state.eventsForTheMonth = value;
    },
  },

  actions: {
    async getSchedule({ commit }, date) {
      return await axios
        .get('api/schedule', {
          params: date,
        })
        .then((res) => {
          if (res.data.schedule.length) {
            commit('setSchedule', res.data.schedule);
          }
          return res.data.schedule;
        });
    },
    async getMonthlySchedule({ commit }, month) {
      await axios
        .get('api/events', {
          params: {
            month,
          },
        })
        .then((res) => {
          if (res.data.status) {
            commit('setMonthlySchedule', res.data.events);
          }
        });
    },
  },
  getters: {
    eventsOnSelectedDay(state) {
      if (!state.selectedDay) return [];
      return state.events.filter((event) => event.date === state.selectedDay.format('YYYY-MM-DD'));
    },
  },
};
