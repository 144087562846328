<template>
  <div class="ordering">
    <div class="ordering__header">
      <button
        v-for="(button, idx) in buttons"
        :key="idx"
        :class="[
          'ordering__select-btn',
          { 'ordering__select-btn--selected': currentComponent === button.component },
        ]"
        @click="currentComponent = button.component"
      >
        {{ button.title }}
      </button>
    </div>
    <components
        id="order-current-component"
        ref="dinamic-component"
        :key="keyCounter"
        :is="currentComponent"
        :reference-types="referenceTypes"
        :attestation-types="attestationTypes"
        :reference-count="referenceCount"
        :status="statusSending"
        :history="history"
        @create="$emit('create-reference', $event)"
      />
    </div>
</template>

<script>
import sendingStatus from '@/views/Deanery/sendingStatus';
import { mapState } from 'vuex';
import history from './history';
import order from './order';

export default {
  name: 'OrderingInformation',

  data() {
    return {
      currentComponent: '',

      keyCounter: 1,

      timeoutId: null,

      buttons: [
        {
          title: 'Заказать справку',
          component: 'order',
        },
        {
          title: 'История заказов',
          component: 'history',
        },
      ],
    };
  },

  components: {
    history,
    order,
    sendingStatus,
  },

  props: {
    referenceTypes: {
      type: Array,
      default: () => [],
    },

    referenceCount: {
      type: Number,
      default: () => 0,
    },

    attestationTypes: {
      type: Array,
      default: () => [],
    },

    statusSending: {
      type: [Object, null],
      default: () => null,
    },
  },

  watch: {
    async statusSending(newValue) {
      if (newValue) {
        this.currentComponent = 'sendingStatus',
        this.timeoutId = setTimeout(() => {
          if (newValue?.done) {
            this.currentComponent = 'history';

            this.$nextTick().then(() => {
              const refsHistory = this.$refs['dinamic-component'];
              if (refsHistory) {
                refsHistory.activeGroup = newValue.referenceName;
              }
            });
          }

          this.$emit('clear-status');
        }, 3000);
      }
    },

    async currentComponent() {
      if (this.$isMobile()) {
        await this.$nextTick();
        this.$helpers.helperFunctions.scrollTo('order-current-component', 'start');
      }
    },
  },

  computed: {
    ...mapState({
      history: (state) => state.deanery.history,
    }),
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },

};
</script>

<style lang="scss">
.ordering {
  display: grid;
  row-gap: 3.2rem;
  grid-template-rows: max-content 1fr;
  .ordering__header {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    column-gap: 2rem;
    justify-items: center;
  }
  .ordering__select-btn {
    transition-duration: 0.3s;
    transition-property: background-color;
    border-radius: 1rem;
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    max-width: 29.2rem;
    width: 100%;
    height: 4rem;
    color: $brand-clr-1;
    background-color: transparent;
    border: 1px solid $brand-clr-1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: rgba($color: $brand-clr-1, $alpha: 0.1);
    }
  }
  .ordering__select-btn--selected {
    background-color: $brand-clr-1;
    color: $basic-white;
    &:hover {
      background-color: $brand-clr-1;
    }
  }
}
</style>
