<template>
  <div class="reference">
    <h3 class="resume-list__header">
      {{ referenceType.name }}
    </h3>

    <h4 class="resume-list__sub-header">Выдача справки</h4>

    <el-radio-group v-model="form.reference_form">
      <el-radio :label="0" :disabled="!referenceType.enable_email"
        >На электронный адрес</el-radio
      >
      <el-tooltip
        popper-class="tooltip-popper"
        content="Внимание! Студентам из Москвы и МО справки по почте не отправляются, справку необходимо получить лично."
        placement="top-start"
      >
        <el-radio :label="1" :disabled="!referenceType.enable_post"
          >Оригинал почтой</el-radio
        >
      </el-tooltip>
    </el-radio-group>

    <m-input
      v-model="form.email"
      placeholder="Электронный адрес"
      :valid="!$v.form.email.$error"
      :error-message="
        !$v.form.email.required
          ? 'Обязательное поле'
          : !$v.form.email.email
          ? 'Неверный фориат почты'
          : ''
      "
    />

    <template v-if="form.reference_form">
      <h4 class="resume-list__sub-header">Почтовый адрес получения справки</h4>
      <p class="resume-list__input-desscription">
        Пример: 109316, г. Москва, Волгоградский проспект, д. 32, корп. 5,
        (каб.101)
      </p>

      <m-input
        v-model="form.address"
        placeholder="Адрес"
        :valid="!$v.form.address.$error"
        :error-message="!$v.form.address.required ? 'Обязательное поле' : ''"
      />
    </template>

    <h4 class="resume-list__sub-header">СНИЛС</h4>
    <m-input
      v-model="form.snils"
      mask="000-000-000 00"
      :max="14"
      placeholder="Электронный адрес"
      :valid="!$v.form.snils.$error"
      :error-message="
        !$v.form.snils.required
          ? 'Обязательное поле'
          : !$v.form.snils.minLength || !$v.form.snils.maxLength
          ? 'Количество цифр должно быть 11'
          : ''
      "
    />

    <h4 class="resume-list__sub-header">
      Укажите период за который требуются сведения
    </h4>

    <div class="resume-list__row">
      <span>C</span>
      <validate
        :valid="!$v.form.start_date.$error"
        :error-message="!$v.form.start_date.required ? 'Обязательное поле' : ''"
      >
        <el-date-picker
          popper-class="custom-class"
          format="dd MM yyyy"
          v-model="form.start_date"
          type="date"
          placeholder="Выберите дату"
        />
      </validate>
      <span>По</span>
      <validate
        :valid="!$v.form.end_date.$error"
        :error-message="!$v.form.end_date.required ? 'Обязательное поле' : ''"
      >
        <el-date-picker
          popper-class="custom-class"
          format="dd MM yyyy"
          v-model="form.end_date"
          type="date"
          placeholder="Выберите дату"
        >
        </el-date-picker>
      </validate>
    </div>

    <h4 class="resume-list__sub-header">Количество справок</h4>

    <el-input-number v-model="form.reference_count" :min="1" :max="5" />

    <button class="help-cal__send-btn" @click="createHandler">Заказать</button>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required,
  email,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators';
import validate from '@/components/ui/validate';

const FORM_DEFAULT = {
  reference_form: 0,
  email: '',
  address: '',
  snils: '',
  reference_count: 1,
  start_date: '',
  end_date: '',
};

export default {
  name: 'referenceSubsidies',

  components: {
    validate,
  },

  mixins: [validationMixin],

  props: {
    referenceType: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isCheck: false,
      form: {
        ...FORM_DEFAULT,
      },
    };
  },

  watch: {
    form: {
      handler() {
        if (this.isCheck) {
          this.$v.$reset();
          this.isCheck = false;
        }
      },

      deep: true,
    },
  },

  validations() {
    if (!this.form.reference_form) {
      return {
        form: {
          email: {
            required,
            email,
          },

          snils: {
            required,
            minLength: minLength(14),
            maxLength: maxLength(14),
          },

          start_date: {
            required,
          },

          end_date: {
            required,
          },
        },
      };
    }
    return {
      form: {
        email: {
          required,
          email,
        },

        address: {
          required,
        },

        snils: {
          required,
          minLength: minLength(14),
          maxLength: maxLength(14),
        },

        start_date: {
          required,
        },

        end_date: {
          required,
        },
      },
    };
  },

  created() {
    if (!this.referenceType.enable_email) {
      this.form.reference_form = 1;
    }
  },

  methods: {
    createHandler() {
      this.isCheck = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('create', this.form);
      }
    },
  },
};
</script>

<style lang="scss">
.reference {
  @extend %resume-list;
  justify-items: flex-start;
  padding-left: 5.3rem;
  padding-right: 5.3rem;
  width: 100%;
  max-width: 50rem;

  .resume-list__header {
    justify-self: center;
  }

  .resume-list__row {
    display: flex;
    column-gap: 1rem;
    align-items: center;
    margin-bottom: 1.5rem;

    .m-required {
      margin-bottom: 0;
    }
  }

  .el-date-editor.el-input {
    width: auto;
  }

  .resume-list__input-desscription {
    margin-bottom: 0.7rem;
  }

  .resume-list__sub-header:nth-child(5) {
    margin-bottom: 0.3rem;
  }

  .help-cal__course {
    width: 4rem;
    justify-self: flex-start;
  }

  .help-cal__send-btn {
    width: 100%;
    margin: 3rem auto 0;
    background-color: $brand-clr-1;
    color: $basic-white;
    border-radius: 1rem;
    padding: 1.2rem 0;
    font-size: 1.4rem;
    font-family: "Gilroy-Bold";
  }
}
</style>
