<template>
  <div class="pagination-buttons">
    <button
      v-for="button of buttons"
      :key="button"
      @click="changeBtn(button)"
      :class="[
        'pagination-buttons__button',
        { 'pagination-buttons__button--active': activeBtn === button },
      ]"
    ></button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeBtn: 1,
    };
  },
  props: {
    buttons: Number,
  },
  methods: {
    changeBtn(b) {
      this.activeBtn = b;
      this.$emit('change', b);
    },
  },
};
</script>

<style lang="scss">
.pagination-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.8rem;
  .pagination-buttons__button {
    transition-duration: 0.3s;
    transition-property: width background-color;
    height: 0.8rem;
    width: 0.8rem;
    background-color: $brand-clr-3;
    border-radius: 0.4rem;
    padding: 0;
  }
  .pagination-buttons__button--active {
    background-color: $brand-clr-1;
    width: 1.6rem;
  }
}
</style>
