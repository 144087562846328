<template>
  <div class="credit-book-tab">
    <h2 class="tab__title">ЗАЧЕТНАЯ КНИЖКА № 19801</h2>
    <p class="tab__name">{{ userInfo.student }}</p>

    <div class="tab__block">
      <span class="block__value">{{ userInfo.specialty }}</span>
      <span class="block__placeholder"
        >{{ userInfo.qualification }}</span
      >
    </div>

    <div class="user__avatar">
      <img :src="setPhoto()" alt="avatar" class="user__img" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    setPhoto() {
      if (this.userInfo.avatar_link) return this.userInfo.avatar_link;
      return 'https://college.instudy.online/img/mgok/avatar/avatar.jpg';
    },
  },
};
</script>

<style lang="scss">
.credit-book-tab {
  background-color: #f2f3f9;
  border-radius: 1rem;
  padding: 1.2rem 1.6rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-areas:
    "title avatar"
    "name avatar"
    "block avatar"
  ;
  row-gap: 1.6rem;

  @include breakPoints(sm) {
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      "title title"
      "avatar name"
      "block block"
    ;
    column-gap: 1rem;
  }

  .tab__title {
    font-size: 1.8rem;
    color: $basic-black;
    font-family: "Roboto-Regular";
    grid-area: title;
  }

  .tab__name {
    font-family: "Roboto-Regular";
    color: $basic-black;
    font-size: 1.4rem;
    grid-area: name;
  }

  .tab__block {
    display: grid;
    grid-area: block;
  }

  .block__placeholder,
  .block__value {
    font-family: "Roboto-Regular";
  }

  .block__value {
    font-size: 1.4rem;
    color: $basic-black;
  }

  .block__placeholder {
    color: $primary-text-clr;
    font-size: 1.2rem;
  }

  .user__avatar {
    justify-self: flex-end;
    border-radius: 1.2rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 10rem;
    height: 10rem;
    grid-area: avatar;

    @include breakPoints(sm) {
      justify-self: flex-start;
    }
  }

  .user__img {
    height: 100%;
  }
}
</style>
