<template>
  <div class="page-404 page">
    <div class="page-404__content">
      <h2 class="content__title">404</h2>
      <span class="content__text">Страница не найдена</span>
      <router-link class="content__link" to="/home"
        >На главную страницу</router-link
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.page-404 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-404__content {
    display: grid;
    row-gap: 1rem;
    justify-items: center;
  }
  .content__title {
    font-size: 3.5rem;
    color: $brand-clr-2;
  }
  .content__text {
    color: $brand-clr-2;
  }
  .content__link {
    font-size: 1.5rem;
    color: $brand-clr-1;
  }
}
</style>
