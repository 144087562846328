<template>
  <div :class="['tests-tab', { 'tests-tab--max-height': selectedTest }]">
    <template v-if="!selectedTest">
      <div
        @click="selectedTest = test"
        :class="[
          'tests-tab__list-item',
          { 'tests-tab__list-item--done': test.status },
          { 'tests-tab__list-item--disable': test.disable },
        ]"
        v-for="test of testList"
        :key="test.id"
      >
        <div class="list-item__title">
          <m-icon :name="test.disable ? 'lock' : 'tablet-list'" />
          <h3 class="title__text">Тест по дисциплине "{{ test.lable }}"</h3>
        </div>
        <div class="list-item__attempt">
          <span class="attempt__quantity">{{ test.attempt }}</span>
          <span class="attempt__text">попытка</span>
        </div>
        <span class="list-item__question"
          ><m-icon name="question-background" />
          {{ test.numberOfQuestionsal }}</span
        >
        <span class="list-item__time"
          ><m-icon name="watch" /> {{ test.time }}</span
        >
        <span class="list-item__type">{{ test.type }}</span>
        <span v-if="!test.disable" class="list-item__deadline"
          >Доступен до {{ test.deadline }}</span
        >
        <button class="list-item__btn">{{ currentText(test) }}</button>
      </div>
    </template>
    <template v-else>
      <div class="test-description">
        <h3 class="text-title">
          Тест по дисциплине “{{ selectedTest.lable }}”
        </h3>
        <span class="test-description__type">{{ selectedTest.type }}</span>
        <div class="test-description__params">
          <div class="list-item__attempt">
            <span class="attempt__quantity">1</span>
            <span class="attempt__text">попытка</span>
          </div>
          <span class="list-item__question">
            <m-icon name="question-background" />
            {{ selectedTest.numberOfQuestionsal }}
          </span>

          <span class="list-item__time">
            <m-icon name="watch" />
            {{ selectedTest.deadline }}
          </span>
        </div>
        <ul class="test-description__notify-list">
          <li
            class="test-description__notify"
            v-for="notify of notifyList"
            :key="notify.id"
          >
            <span
              class="notify-value"
              :style="{ backgroundColor: notify.color }"
              >{{ notify.value }}</span
            >
            <span class="notify-points"
              >баллов - <span>{{ notify.title }}</span></span
            >
          </li>
        </ul>
        <button @click="handleClick(selectedTest.id)" class="btn-fill">
          НАЧАТЬ ТЕСТИРОВАНИЕ
        </button>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      notifyList: [
        {
          id: 1,
          title: 'отлично',
          value: '89',
          color: '#BEFAAD',
        },
        {
          id: 2,
          title: 'хорошо',
          value: '59',
          color: '#E1FAAD',
        },
        {
          id: 3,
          title: 'удовлетворительно',
          value: '>30',
          color: '#FFE946',
        },
        {
          id: 4,
          title: 'неудовлетворительно',
          value: '<30',
          color: '#FF6C46',
        },
      ],
      selectedTest: '',
      testList: [
        {
          id: 1,
          lable: 'Математика',
          type: 'Промежуточный',
          attempt: 1,
          numberOfQuestionsal: 100,
          time: '01:45:00',
          status: true,
          disable: false,
          deadline: '20.05.2021',
        },
        {
          id: 2,
          lable: 'Математика',
          type: 'Промежуточный',
          attempt: 1,
          numberOfQuestionsal: 100,
          time: '01:45:00',
          status: false,
          disable: false,
          deadline: '20.05.2021',
        },
        {
          id: 3,
          lable: 'Математика',
          type: 'Промежуточный',
          attempt: 1,
          numberOfQuestionsal: 100,
          time: '01:45:00',
          status: false,
          disable: true,
          deadline: '20.05.2021',
        },
      ],
    };
  },
  methods: {
    currentText(test) {
      if (test.disable) {
        return 'Недоступен';
      }
      if (test.status) {
        return 'Зачтено';
      }
      return 'Пройти';
    },
    handleClick(id) {
      this.$router.push(`${this.$router.currentRoute.path}/test/${id}`);
    },
  },
};
</script>

<style lang="scss">
.tests-tab {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  padding: 1.6rem 2.9rem;
  background-color: #f2f3f9;
  border-radius: 1rem;
  .tests-tab__list-item {
    width: 100%;
    row-gap: 1rem;
    display: grid;
    border-radius: 1rem;
    padding: 1.1rem 2.4rem;
    background-color: $basic-white;
    margin-bottom: 0.8rem;
    column-gap: 1rem;
    grid-template-columns: auto auto auto 1fr;
    align-items: center;
    grid-template-areas:
      "title title title type"
      "attempt question time deadline"
      "btn btn btn .";
  }
  .m-icon--lock {
    fill: $placeholder-text-clr;
    width: 1.5rem;
  }
  .m-icon--tablet-list {
    width: 1.5rem;
    fill: $brand-clr-1;
  }
  .list-item__type,
  .title__text {
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $basic-black;
  }
  .list-item__title {
    grid-area: title;
    display: flex;
    column-gap: 1.1rem;
    align-items: center;
  }

  .list-item__attempt {
    display: flex;
    column-gap: 0.8rem;
    align-items: center;
  }
  .list-item__attempt {
    grid-area: attempt;
  }
  .list-item__time,
  .list-item__question {
    grid-area: question;
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
  }
  .list-item__time {
    grid-area: time;
  }
  .list-item__type {
    justify-self: flex-end;
    grid-area: type;
  }
  .list-item__deadline {
    font-family: "ProximaNova-Regular";
    font-size: 1.4rem;
    justify-self: flex-end;
    grid-area: deadline;
  }
  .attempt__quantity {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    color: $basic-white;
    background-color: $placeholder-text-clr;
  }
  .list-item__time,
  .list-item__question,
  .attempt__text {
    color: $placeholder-text-clr;
    font-size: 1.4rem;
    font-family: "ProximaNova-Regular";
  }
  .list-item__btn {
    width: 16rem;
    height: 4rem;
    background-color: $brand-clr-1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    border-radius: 1rem;
    grid-area: btn;
    color: $basic-white;
  }
  .m-icon--watch,
  .m-icon--question-background {
    fill: $placeholder-text-clr;
  }
  .tests-tab__list-item--done {
    .list-item__btn {
      background-color: $success-clr-lighten;
      color: $basic-black;
    }
    .m-icon--tablet-list {
      fill: $brand-clr-4;
    }
  }
  .tests-tab__list-item--disable {
    .list-item__btn {
      background-color: $placeholder-text-clr;
    }
  }
  .test-description {
    display: flex;
    flex-direction: column;
    row-gap: 0.6rem;
    justify-content: center;
    .text-title {
      font-family: "Gilroy-Bold";
      font-size: 1.8rem;
    }
  }

  .test-description__type {
    font-family: "Gilroy-Bold";
    color: $primary-text-clr;
    text-align: center;
  }
  .test-description__params {
    padding: 2rem 0;
    height: 100%;
    display: flex;
    column-gap: 3.2rem;
  }
  .btn-fill {
    width: 100%;
  }
  .test-description__notify-list {
    display: grid;
    row-gap: 0.8rem;
    margin-bottom: 3.4rem;
  }
  .test-description__notify {
    align-items: center;
    column-gap: 0.8rem;
    display: grid;
    grid-template-columns: 4rem 1fr;
  }
  .notify-value {
    color: $basic-black;
    border-radius: 0.5rem;
    text-align: center;
    padding: 0.2rem;
  }
  .notify-points {
    font-family: "ProximaNova-Regular";
    font-size: 1.4rem;
    color: $secondary-text-clr;
    span {
      color: $basic-black;
    }
  }
}
.tests-tab--max-height {
  height: 100%;
  align-items: center;
}
</style>
