import axios from 'axios';

export default {
  state: {
    folders: [],
    referenceTypes: [],
    history: [],
    statementsList: {},
    attestationTypes: [],
    referenceCount: null,
    statementsHistory: [],
  },
  mutations: {
    setFolders(state, value) {
      state.folders = value;
    },

    setReferenceTypes(state, value) {
      state.referenceTypes = value;
    },

    setReferenceHistory(state, value) {
      state.history = value;
    },

    setReferenceCount(state, value) {
      state.referenceCount = value;
    },

    setAttestationTypes(state, value) {
      state.attestationTypes = value;
    },

    setStatementList(state, value) {
      state.statementsList = value;
    },

    setStatementsHistory(state, value) {
      state.statementsHistory = value;
    },
  },
  actions: {
    async getDeaneryData({ commit }) {
      try {
        const { data } = await axios.get('api/deanery');

        if (data.status) {
          commit('setFolders', data.dirMap);
        }
      } catch (e) {
        console.warn('Fetch is failed [store/deanery/getDeaneryData', e);
      }
    },

    async createReference({ dispatch }, formData) {
      try {
        const { status } = await axios.post('api/reference', formData);

        if (status) {
          await dispatch('getReferenceList');
          await dispatch('getReferenceHistory');
        }

        return status;
      } catch (e) {
        console.warn('Fetch is failed [store/deanery/createReference', e);
      }
    },

    async getReferenceList({ commit }) {
      try {
        const { data, status } = await axios.get('/api/reflist');

        if (status === 200) {
          const { attestationTypes, referenceTypes, referenceCount } = data;

          commit('setReferenceTypes', referenceTypes);
          commit('setAttestationTypes', attestationTypes);
          commit('setReferenceCount', referenceCount);
        }
      } catch (e) {
        console.warn('Fetch is failed [store/deanery/getReferenceList', e);
      }
    },

    async getReferenceHistory({ commit }) {
      try {
        const { data, status } = await axios.get('/api/refhistory');

        if (status === 200) {
          commit('setReferenceHistory', data.referenceList);
        }
      } catch (e) {
        console.warn('Fetch is failed [store/deanery/getReferenceList', e);
      }
    },

    async getStatementsList({ commit }) {
      try {
        const { data, status } = await axios.get('/api/statement-list');

        if (status === 200) {
          commit('setStatementList', data);
        }
      } catch (e) {
        console.warn('Fetch is failed [store/deanery/getStatementsList', e);
      }
    },

    async createStatement({ dispatch }, payload) {
      try {
        const { status } = await axios.post('/api/statement', payload);
        if (status === 200) dispatch('getStatementsHistory');
        return true;
      } catch (e) {
        console.warn('Fetch is failed [store/deanery/createStatement', e);
        return false;
      }
    },

    async getStatementsHistory({ commit }) {
      try {
        const { status, data } = await axios.get('/api/statement-history');

        if (status === 200) {
          commit('setStatementsHistory', data.statementList);
        }
      } catch (e) {
        console.warn('Fetch is failed [store/deanery/getStatementsHistory', e);
      }
    },
  },
};
