<template>
  <div
    @drop.prevent="dropStart($event)"
    @dragleave="leaveFile($event)"
    class="add-file"
  >
    <div
      :class="[
        'add-file__window',
        { 'add-file__window--dragenter': dragenter },
        { 'add-file__window--drop': showBackSide },
      ]"
    >
      <div v-if="!showBackSide" class="window__front-side">
        <div
          v-if="dragStart"
          dropzone="copy"
          @dragleave="dragLeaveF($event)"
          @dragenter="dragEnterF($event)"
          class="drop-zone"
        ></div>
        <button @click="closeWindow(false)" class="add-file__close-btn">
          <m-icon name="close" />
        </button>
        <span class="window__text">Перетащите файл сюда</span>
      </div>
      <div class="window__back-side" v-else>
        <div
          v-if="dragStart"
          dropzone="copy"
          @dragleave="dragLeaveF($event)"
          @dragenter="dragEnterF($event)"
          class="drop-zone"
        ></div>
        <button @click="closeWindow(false)" class="add-file__close-btn">
          <m-icon name="close" />
        </button>
        <div class="file-list">
          <div v-for="(file, idx) of files" class="file-list__item" :key="idx">
            <m-icon
              :name="
                checkImage(file) ? 'file-img-background' : 'file-background'
              "
            />
            <span class="item__size">{{
              Math.ceil(file.size / 1024) + " КВ"
            }}</span>
            <input
              :class="[
                'item__name',
                { 'item__name--edit': editableFile === idx },
              ]"
              :ref="'file-name' + idx"
              :readonly="editableFile !== idx"
              type="text"
              v-model="file.newName"
            />
            <div class="item__control-block">
              <transition name="fade">
                <button
                  v-if="editableFile === idx"
                  @click="saveNewFileName(file)"
                  class="item__edit-btn"
                >
                  <m-icon name="save" />
                </button>
                <button
                  v-else
                  @click="editUploadFile(idx, file)"
                  class="item__edit-btn"
                >
                  <m-icon name="edit" />
                </button>
              </transition>
              <button @click="removeUploadFile(idx)" class="item__remove-btn">
                <m-icon name="close" />
              </button>
            </div>
          </div>
        </div>
        <m-input v-model="message" placeholder="Сообщение" />
        <div class="back-side__button-block">
          <button class="add-file__upload-files">
            Добавить
            <input
              multiple
              class="input-load-file"
              ref="input-load-file"
              @change="loadFile"
              type="file"
            />
          </button>
          <button @click="sendFile" class="add-file__upload-files">
            Отправить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      showBackSide: false,
      dropZoneActive: false,
      message: '',
      editableFile: null,
      dragenter: false,
      drop: false,
      files: [],
    };
  },
  watch: {
    editableFile() {
      this.editableFile !== null
        ? this.$nextTick(() => this.$refs[`file-name${this.editableFile}`][0].focus())
        : null;
    },
  },
  props: {
    dragStart: Boolean,
    upoadedFiles: Array,
    text: String,
  },
  computed: {
    selectedChat() {
      return this.$store.state.messages.selectedChat;
    },
  },
  methods: {
    ...mapMutations(['pushNotify']),
    leaveFile(e) {
      if (
        (e.relatedTarget?.id === 'chat-list' || e.relatedTarget === null)
        && !this.files.length
      ) {
        this.closeWindow(false);
      }
    },
    closeWindow(value) {
      this.files = [];
      this.$emit('close', { update: value });
    },
    dragLeaveF() {
      this.dragenter = false;
    },
    dragEnterF() {
      this.dragenter = true;
    },
    checkQuantity() {
      const result = this.files.length <= 3;
      if (!result) {
        const dataNotify = {
          content: 'Превышено колличество файлов',
          type: 'error',
        };
        this.pushNotify(dataNotify);
      }
      return result;
    },
    checkRepeatFile(name) {
      const result = Boolean(this.files.find((file) => file.name === name));
      if (result) {
        const dataNotify = {
          content: 'Файл с таким именем уже загружен',
          type: 'error',
        };
        this.pushNotify(dataNotify);
      }
      return result;
    },
    sendFile() {
      const formData = new FormData();
      this.files.forEach((file) => {
        formData.append('file[]', file, file.newName);
      });
      formData.append('recipient', this.selectedChat.id);
      formData.append('files', this.files);
      formData.append('text', this.message ? this.message : null);
      this.axios.post('/api/messages', formData).then((res) => {
        if (res.data.status) {
          const dataNotify = {
            content: 'Файлы успешно загружены',
            type: 'success',
          };
          this.pushNotify(dataNotify);
        } else {
          const dataNotify = {
            content: 'Ошибка загрузки файлов',
            type: 'error',
          };
          this.pushNotify(dataNotify);
        }

        this.closeWindow(res.data.status);
      });
    },
    addFile(files) {
      if (!this.showBackSide) this.showBackSide = true;
      this.checkQuantity()
        ? files.forEach((file) => {
          if (!this.checkRepeatFile(file.name) && this.checkQuantity()) {
            file.newName = file.name;
            this.files.push(file);
          }
        })
        : null;
    },
    loadFile() {
      const file = this.$refs['input-load-file'].files[0];
      this.addFile([...this.$refs['input-load-file'].files]);
    },
    editUploadFile(index, file) {
      this.editableFile = index;
    },
    checkImage(v) {
      return v.type.match('image.*');
    },
    removeUploadFile(idx) {
      this.files.splice(idx, 1);
      this.files.length === 0 ? this.$emit('close', { update: false }) : null;
    },
    saveNewFileName(file) {
      this.editableFile = null;
    },
    dropStart(e) {
      this.showBackSide = true;
      this.dragenter = false;
      this.$emit('dropFile');
      this.addFile(e.dataTransfer.files);
    },
  },
  created() {
    if (this.upoadedFiles.length) {
      this.files = this.upoadedFiles;
      this.showBackSide = true;
    }
    this.text ? (this.message = this.text) : null;
  },
};
</script>

<style lang="scss">
.add-file {
  transition-duration: 0.3s;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  z-index: 10;
  .add-file__window {
    position: relative;
    transition-duration: 0.3s;
    transition-property: all;
    perspective: 500px;
    transition-duration: 0.3s;
    transition-duration: border;
    width: 90%;
    max-width: 36.8rem;
    max-height: 38rem;
    min-height: 24rem;
    background-color: $extra-light-border;
    border-radius: 1rem;
    border: 0.2rem solid transparent;
  }
  .add-file__window--dragenter {
    border: 2px dashed $brand-clr-1;
    .window__text {
      color: $brand-clr-1;
    }
  }
  .add-file__window--drop {
    transform: translateZ(-100px) rotateY(180deg);
  }
  .window__text {
    transition-duration: 0.3s;
    transition-property: color;
    font-family: "Gilroy-Bold";
    font-size: 2.4rem;
    color: $secondary-text-clr;
  }
  .add-file__close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    justify-self: flex-end;
    background-color: $brand-clr-1;
    border-radius: 0.5rem;
    .m-icon {
      width: 1rem;
      fill: $basic-white;
    }
  }

  .window__front-side {
    align-items: center;
    justify-items: center;
    padding: 2rem;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: max-content 1fr;
    position: absolute;
  }
  .window__back-side {
    row-gap: 1.5rem;
    padding: 2rem;
    transform: scaleX(-1);
    display: grid;
    grid-template-rows: max-content 1fr max-content max-content;
    width: 100%;
    height: 100%;
  }
  .window__back-side .m-input .m-input__input {
    background-color: $base-border-clr;
  }
  .window__back-side .m-input .m-input__input::placeholder {
    color: $regular-text-clr;
  }
  .file-list {
    display: grid;
    grid-auto-rows: max-content;
    align-self: center;
    row-gap: 1rem;
    align-items: center;
    padding: 1rem 0;
  }
  .file-list__item {
    display: grid;
    row-gap: 0.5rem;
    width: auto;
    height: max-content;
    justify-items: center;
    align-items: center;
    align-self: center;
    column-gap: 0.5rem;
    grid-template-columns: max-content 1fr max-content;
    grid-template-areas:
      "icon name button"
      "icon size .";
    .m-icon {
      width: 2.8rem;
      grid-area: icon;
      align-self: center;
      height: 3.2rem;
    }
  }
  .item__img {
    width: 12rem;
    border-radius: 1rem;
  }
  .item__size {
    padding: 0 0.5rem;
    grid-area: size;
    justify-self: flex-start;
    font-size: 1.2rem;
    font-family: "ProximaNova-Regular";
    color: $secondary-text-clr;
  }
  .item__name {
    width: 100%;
    padding: 0.2rem 0.5rem;
    transition-property: border-color;
    transition-duration: 0.3s;
    background-color: transparent;
    justify-self: flex-start;
    grid-area: name;
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $basic-black;
    border: 1px solid transparent;
    border-radius: 1rem;
    outline: none;
    &::placeholder {
      color: $basic-black;
    }
    &:focus {
      border-color: $brand-clr-1;
    }
  }
  .item__name--edit {
    background-color: $base-border-clr;
    padding-left: 1rem;
  }
  .item__save-btn,
  .item__edit-btn,
  .item__remove-btn {
    background-color: transparent;
    grid-area: button;
    .m-icon {
      width: 1rem;
      height: 1rem;
      fill: $brand-clr-1;
    }
  }
  .add-file__upload-files {
    position: relative;
    font-size: 1.8rem;
    color: $brand-clr-1;
    font-family: "Gilroy-Bold";
    background-color: transparent;
    cursor: pointer;
  }
  .add-file__upload-files:first-child {
    justify-self: flex-start;
  }
  .add-file__upload-files:last-child {
    justify-self: flex-end;
  }
  .back-side__button-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .drop-zone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
