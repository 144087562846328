<template>
  <section class="history" id="history-statements">
    <el-collapse v-model="activeItem" accordion>
      <el-collapse-item
        v-for="(record, idx) in history"
        :key="idx"
        :name="idx"
        class="history__collapse"
      >
        <template #title>
          <div class="header__content-wrp">
            <h3 class="history__sub-title">{{ record.title }}</h3>

            <m-icon name="chevron" />
          </div>
        </template>
        <div class="body__content">
          <div class="history__reference">
            <div class="reference__left-section">
              <div class="reference__graph">
                <transition name="fade">
                  <graph
                    v-if="activeItem === idx"
                    :radiusDot="8"
                    :points="record.progress"
                  />
                </transition>
              </div>
              <ul class="reference__list">
                <li
                  v-for="(progress, idx) in record.progress"
                  :key="idx"
                  class="list-item"
                >
                  <b>{{ progress.title }}</b>
                  <time>{{ progress.date }}</time>
                  <span v-if="progress.time">|</span>
                  <time>{{ progress.time }}</time>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </section>
</template>

<script>
import graph from '@/views/Deanery/graph.vue';

export default {
  name: 'historyStatements',

  components: {
    graph,
  },

  props: {
    history: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    if (this.$isMobile()) {
      this.$helpers.helperFunctions.scrollTo('history-statements', 'start');
    }
  },

  data() {
    return {
      activeItem: null,
    };
  },
};
</script>
