var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference"},[_c('h3',{staticClass:"resume-list__header"},[_vm._v("Справка о выпуске")]),_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("Выдача справки")]),_c('el-radio-group',{model:{value:(_vm.form.reference_form),callback:function ($$v) {_vm.$set(_vm.form, "reference_form", $$v)},expression:"form.reference_form"}},[_c('el-radio',{attrs:{"label":0}},[_vm._v("На электронный адрес")]),_c('el-radio',{attrs:{"label":1}},[_vm._v("Оригинал почтой")])],1),_c('m-input',{attrs:{"placeholder":"Электронный адрес","valid":!_vm.$v.form.email.$error,"error-message":!_vm.$v.form.email.required
        ? 'Обязательное поле'
        : !_vm.$v.form.email.email
        ? 'Неверный фориат почты'
        : ''},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.form.reference_form)?_c('m-input',{attrs:{"placeholder":"Адрес","valid":!_vm.$v.form.address.$error,"error-message":!_vm.$v.form.address.required
        ? 'Обязательное поле'
        : ''},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}):_vm._e(),_c('el-select',{attrs:{"popper-class":"documents__popper","placeholder":"Select"},model:{value:(_vm.form.reference_count),callback:function ($$v) {_vm.$set(_vm.form, "reference_count", $$v)},expression:"form.reference_count"}},_vm._l((_vm.referenceCountOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('button',{staticClass:"help-cal__send-btn",on:{"click":_vm.createHandler}},[_vm._v("Заказать")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }