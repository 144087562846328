<template>
  <div class="tasks-tab">
    <div class="tasks-tab__list">
      <VkrModule :vkr="vkrData" v-if="vkrData"/>
      <TasksModule :list="taskList" :practice-data="practiceData" v-else/>
    </div>
  </div>
</template>

<script>
import TasksModule from '@/views/EducationalProcess/discipline/modules/tabs/tasks/TasksModule/TasksModule.vue';
import VkrModule from '@/views/EducationalProcess/discipline/modules/tabs/tasks/VkrModule.vue';

export default {
  name: 'DisciplineTasks',

  components: {
    TasksModule,
    VkrModule,
  },

  computed: {
    taskList() {
      const { tests } = this.$store.state.discipline.disciplineData;
      return tests.reduce((acc, el) => {
        if (el.id === '13') {
          const result = acc.find((item) => item.id === '13');
          if (!result) {
            acc.push(el);
          }
        } else {
          acc.push(el);
        }
        return acc;
      }, []);
    },

    practiceData() {
      const {
        practicePeriod, practicePlace, practiceDiscipline, setPracticePlaceFlag,
      } = this.$store.state.discipline.disciplineData;

      return {
        practicePeriod,
        practicePlace,
        practiceDiscipline,
        setPracticePlaceFlag,
      };
    },

    userInfo() {
      return this.$store.state.userInfo;
    },

    vkrData() {
      return this.$store.state.discipline.disciplineData.vkr;
    },
  },
};
</script>

<style lang="scss">
.tasks-tab {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 1.6rem;
  background-color: #f2f3f9;
  border-radius: 1rem;
  padding: 1.6rem 3rem;

  &__list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
