<template>
  <div class="discipline-test">
    <div class="discipline-test__content page">
      <div class="discipline-test__progres">
        <div class="progress__content">
          <h3 class="text-title content__title">Введение в психоанализ</h3>
          <span class="content__type">Классический тест</span>
          <p class="content__time">
            <span>00:02:54 </span> / <span>01:00:00</span>
          </p>
          <m-progress
            class="content__progres"
            type="horizontal"
            :percent="percent"
          />
        </div>
        <button class="btn-fill">Завершить тест</button>
      </div>
      <ul class="discipline-test__list">
        <li
          class="list-item"
          v-for="question of listOfQuestions"
          :key="question.id"
        >
          <h3 class="list-item__title">{{ question.title }}</h3>
          <p class="list-item__description">{{ question.description }}</p>
          <div class="list-item__checkboxes" v-if="question.variants">
            <m-checkbox
              class="list-item__checkbox"
              v-model="variant.answer"
              @change="handleChange"
              v-for="variant of question.variants"
              :key="variant.id"
              >{{ variant.title }}</m-checkbox
            >
          </div>
          <m-input v-else-if="question.answer" placeholder="Введите ответ" />
        </li>
      </ul>
    </div>
    <aside-bar>
      <listOfQuestions />
    </aside-bar>
  </div>
</template>

<script>
import asideBar from '@/components/asideBar';
import listOfQuestions from './listOfQuestions.vue';

export default {
  data() {
    return {
      percent: 20,
      listOfQuestions: [
        {
          id: 1,
          title: 'Вопрос 1',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?',
          variants: [
            {
              id: 1,
              title: 'Дизайн, графика, живопись',
              status: true,
              answer: false,
            },
            {
              id: 2,
              title: 'Другое',
              status: false,
              answer: false,
            },
            {
              id: 3,
              title: 'Журналистика',
              status: false,
              answer: false,
            },
            {
              id: 4,
              title: 'Издательская деятельность',
              status: false,
              answer: false,
            },
          ],
        },
        {
          id: 2,
          title: 'Вопрос 2',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?',
          answer: 'lorem',
        },
        {
          id: 3,
          title: 'Вопрос 3',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat?',
          variants: [
            {
              id: 1,
              title: 'Дизайн, графика, живопись',
              status: true,
              answer: false,
            },
            {
              id: 2,
              title: 'Другое',
              status: false,
              answer: false,
            },
            {
              id: 3,
              title: 'Журналистика',
              status: false,
              answer: false,
            },
            {
              id: 4,
              title: 'Издательская деятельность',
              status: false,
              answer: false,
            },
          ],
        },
      ],
    };
  },
  components: {
    'aside-bar': asideBar,
    listOfQuestions,
  },
  methods: {
    handleChange(v) {
      //
    },
  },
};
</script>

<style lang="scss">
.discipline-test {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 30rem;
  .page {
    border-radius: 2rem 0 0 0;
  }
  .discipline-test__content {
    display: grid;
    grid-template-rows: max-content 1fr;
    row-gap: 3rem;
  }
  .discipline-test__progres {
    display: grid;
    grid-template-columns: 1fr max-content;
    background-color: $extra-light-border;
    border-radius: 1rem;
  }
  .btn-fill {
    text-transform: uppercase;
    border-radius: 0 1rem 1rem 0;
    width: 15rem;
    height: 100%;
  }
  .progress__content {
    grid-template-columns: 1fr max-content;
    padding: 1.2rem;
    row-gap: 0.6rem;
    display: grid;
    grid-template-areas:
      "title time"
      "type progress";
  }
  .content__title {
    grid-area: title;
  }
  .content__time {
    grid-area: time;
    span {
      font-family: "ProximaNova-Regular";
      color: $basic-black;
    }
  }
  .content__type {
    grid-area: type;
    font-family: "ProximaNova-Regular";
    font-size: 1.2rem;
    color: $basic-black;
  }
  .content__progres {
    grid-area: progress;
    max-width: 12.2rem;
    .m-progress__background {
      height: 0.8rem;
      background-color: $basic-white;
    }
    .m-progress__progress {
      background-color: $success-clr-lighten;
    }
  }
  .discipline-test__list {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  .list-item {
    display: grid;
    row-gap: 2rem;
    border-bottom: 1px solid $base-border-clr;
    padding: 3rem 0;
  }
  .list-item__description {
    font-family: "ProximaNova-Regular";
    color: $basic-black;
    font-size: 1.4rem;
  }
  .list-item__title {
    font-size: 1.4rem;
    color: $brand-clr-1;
    font-family: "Gilroy-Bold";
  }
  .m-checkbox {
    .m-checkbox__label {
      color: $basic-black;
    }
  }
  .m-input {
    width: 100%;
    max-width: 100%;
    .m-input__input {
      background-color: $extra-light-border;
      &::placeholder {
        color: lighten($color: $brand-clr-1, $amount: 20);
        font-family: "ProximaNova-Regular";
        font-size: 1.4rem;
      }
    }
  }
  .list-item__checkboxes {
    display: grid;
    row-gap: 0.6rem;
  }
}
</style>
