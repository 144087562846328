<template>
  <simplebar data-simplebar-auto-hide="false" class="discipline">
    <div class="discipline__educational-process">
      <h3 class="text-title">Учебный процесс</h3>
      <progress-bar
        v-if="progressElements.length"
        :selectedSemester="selectedSemester"
        :currentSemester="currentSemester"
        @select="selectSemester"
        :elements="progressElements"
        :totalCircle="totalSemesters"
      />
    </div>
    <!--<div class="discipline__continue-viewing">
      <div class="continue-viewing__header">
        <h3 class="text-title">Продолжить просмотр</h3>
        <div class="continue-viewing__controls">
          <button @click="prevSlide()">
            <i
              :class="[
                'fas',
                'fa-chevron-left',
                { isBlock: currentSlide === 0 },
              ]"
            ></i>
          </button>
          <button @click="nextSlide()">
            <i
              :class="[
                'fas',
                'fa-chevron-right',
                { isBlock: currentSlide === tabs.length - 1 },
              ]"
            ></i>
          </button>
        </div>
      </div>
      <slider @change="handeChange" ref="slider">
        <div class="slider__item" v-for="tab of tabs" :key="tab.id">
          <div class="item__content">
            <tabLesson :tabData="tab.name" />
          </div>
        </div>
      </slider>
    </div>!-->
    <documents ref="documents" v-model="normalizeCurriculum" />
  </simplebar>
</template>

<script>
import simplebar from 'simplebar-vue';
import documents from '@/components/documents/index.vue';
import tabLesson from '@/components/ui/lessonTab';
import { mapActions, mapMutations } from 'vuex';
import slider from './slider.vue';
import progressBar from './discipline/progressBar/index.vue';

export default {
  data() {
    return {
      currentSlide: 0,
      tabs: [
        {
          id: 1,
          name: 'История',
        },
        {
          id: 2,
          name: 'Бухгалтерский учёт',
        },
        {
          id: 3,
          name: 'Экономика организации',
        },
      ],
    };
  },
  components: {
    'progress-bar': progressBar,
    tabLesson,
    slider,
    documents,
    simplebar,
  },
  watch: {
    userInfo(newValue) {
      if (newValue.current_semestr) {
        this.setSelectedSemester(this.currentSemester);
      }
    },
    selectedSemester(newValue) {
      if (newValue) {
        this.getPlans(newValue);
      }
    },
  },
  computed: {
    progressElements() {
      return Array(this.totalSemesters).fill(null).map((el, idx) => {
        const semester = idx + 1;
        return {
          semester,
          percent: semester < +this.currentSemester ? 100 : 0,
          disable: semester > +this.currentSemester,
          currentSemester: semester === +this.currentSemester,
        };
      });
    },
    totalSemesters() {
      return +this.userInfo.semesters_count;
    },
    currentSemester() {
      return +this.userInfo.current_semestr;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    selectedSemester() {
      return this.$store.state.education.selectedSemester;
    },

    curriculum() {
      return this.$store.state.education.curriculum;
    },
    normalizeCurriculum() {
      return this.curriculum.map((el, idx) => ({
        id: idx,
        lable: el.discipline,
        code: el.code,
        progress: 0,
        ...el,
      }));
    },
  },
  methods: {
    ...mapActions({
      getPlans: 'education/getPlans',
    }),
    ...mapMutations({
      setSelectedSemester: 'education/setSelectedSemester',
    }),

    selectSemester(value) {
      this.setSelectedSemester(value);
    },
    handeChange(value) {
      this.currentSlide = value;
    },
    prevSlide() {
      this.$refs.slider.prevSlide();
    },
    nextSlide() {
      this.$refs.slider.nextSlide();
    },
    isEmpty(value) {
      return this.$helpers.helperFunctions.isEmpty(value);
    },
  },
  created() {
    if (!this.selectedSemester) {
      this.setSelectedSemester(+this.userInfo.current_semestr);
    }
  },
  mounted() {
    this.$refs.documents.headerData.displayMode = 'grid';
  },
};
</script>

<style lang="scss">
.discipline {
  padding-right: 3rem;
  height: 100%;
  overflow-y: auto;
  .documents .m-progress__percent {
    display: none;
  }
  .discipline__educational-process {
    overflow-x: hidden;
  }
  .progress-bar {
    margin-left: 1rem;
  }
  .simplebar-content {
    min-height: 100%;
    display: grid;
    row-gap: 3.2rem;
    grid-template-rows: auto auto 1fr;
  }
  .simplebar-content::before {
    display: none;
  }
  .documents {
    padding-bottom: 0;
    height: 100%;
    overflow: hidden;
  }
  .documents__list {
    height: 100%;
    overflow-y: auto;
  }
  .text-title {
    margin-bottom: 0.8rem;
    padding-left: 1rem;
  }
  .tab-wrp {
    max-width: 23.5rem;
    filter: drop-shadow(0px 0px 10px #edf0ff);
    height: 10.7rem;
  }
  .el-carousel__item {
    width: 30rem;
  }
  .continue-viewing__controls {
    display: flex;
    column-gap: 2rem;
    margin-bottom: 2rem;
  }
  .continue-viewing__header {
    max-width: 24rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    button {
      background-color: transparent;
    }
    i {
      transition-duration: 0.3s;
      transition-property: color;
      color: $brand-clr-1;
      font-size: 1rem;
    }
    .isBlock {
      color: $base-border-clr;
    }
  }
  .list__item {
    cursor: pointer;
  }
}
</style>
