<template>
  <div class="m-tree">
    <m-collapse
      v-for="(item, idx) of dataArray"
      :key="idx"
      :blockShowBody="
        item.children && item.children.length
          ? isTemplate(item)
            ? true
            : false
          : true
      "
      :class="[
        isFile(item)
          ? 'm-collapse--isFile'
          : isTemplate(item)
          ? 'm-collapse--isTemplate'
          : 'm-collapse--isFolder',
      ]"
    >
      <template v-slot:header>
        <div class="header__content-wrp" @click="handleClick(item)">
          <m-icon :name="item.icon ? item.icon : setIcon(item)" />
          <span class="text-title header__text"> {{ item.lable }}</span>
          <template v-if="isFile(item)">
            <!-- <span class="item__date">{{ item.fileTime }}</span> -->
            <!-- <m-icon name="star" /> -->
            <!-- <a :href="item.body" target="blank" download
              ><m-icon name="download"
            /></a> -->
          </template>
          <template v-if="isTemplate(item)">
            <div></div>
          </template>
        </div>
      </template>
      <template v-slot:body>
        <div class="body__content">
          <template v-if="item.children && !isTemplate(item)">
            <m-tree :dataArray="item.children" />
          </template>
        </div>
      </template>
    </m-collapse>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'tree',
  props: {
    dataArray: Array,
  },
  methods: {
    ...mapMutations(['setCurrentModalComponent']),
    isTemplate(item) {
      if (
        item.type === 'directory'
        && item.children.length === 1
        && item.children[0].extension === 'tpl'
      ) return true;
      return false;
    },
    isFile(item) {
      return Boolean(item.type === 'file');
    },
    setIcon(item) {
      if (this.isFile(item)) return 'file';
      if (!this.isFile(item) && !this.isTemplate(item)) return 'folder';
      return 'eye-solid';
    },
    handleClick(item) {
      if (item.extension === 'tpl' || this.isTemplate(item)) {
        const obj = {
          name: 'contentReader',
          data: item.body ? item.body : item.children[0].body,
        };

        return this.setCurrentModalComponent(obj);
      }
      if (item.type === 'file') {
        const a = document.createElement('a');
        a.href = item.body;
        a.target = 'blank';
        a.download;
        a.click();
      }
    },
  },
};
</script>

<style lang="scss">
.m-tree {
  padding-top: 0.8rem;
  width: 100%;
  display: grid;
  row-gap: 0.8rem;
  height: 100%;
  height: max-content;
  .m-collapse {
    border-radius: 1rem;
  }
  .m-collapse__header {
    background-color: #f8f8f8;
    padding: 0.8rem 2rem;
    border-radius: 1rem;
    min-height: 5.1rem;
    color: $brand-clr-1;
  }
  .header__content-wrp {
    border-radius: 1rem;
    display: grid;
    column-gap: 1.6rem;
    align-items: center;
    grid-template-columns: auto 1fr;
    grid-template-areas: "icon title date star menu";
    .m-icon--star {
      stroke: $placeholder-text-clr;
      fill: none;
    }
    .m-icon--eye-solid,
    .m-icon--folder,
    .m-icon--file {
      width: 2rem;
      height: 3.5rem;
      grid-area: icon;
      margin: 0;
    }
    .m-icon--play {
      width: 2.8rem;
    }
    .m-icon--eye-solid {
      fill: $brand-clr-1;
    }
    .m-icon--file {
      fill: $brand-clr-4;
    }
    .m-icon--folder {
      fill: $brand-clr-1;
    }
  }
  .body__content {
    padding-left: 2rem;
  }
  .m-collapse--isFile {
    .m-collapse__header {
      grid-template-columns: 1fr;
      background-color: #f2f3f9 !important;
    }
    .header__content-wrp {
      grid-template-areas: "icon title date star download" !important;
    }
  }
  .m-collapse--isFolder {
    .header__content-wrp {
      grid-template-areas: "icon title";
    }
    .m-collapse__header {
      background-color: #cccccc;
    }
  }
  .m-icon--star {
    grid-area: star;
  }
  .m-icon--download {
    width: 1.5rem;
    fill: $brand-clr-1;
    grid-area: download;
    transition-duration: 0.3s;
    transition-property: fill;
    &:hover {
      fill: $brand-clr-1;
    }
  }
  .header__text {
    grid-area: title;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .documents__item--list {
    min-height: 4rem;
    width: 100%;
    grid-template-columns: max-content 1fr max-content max-content max-content;
    grid-template-areas: "icon title date star menu";
  }
  .documents__item--grid {
    padding: 1.6rem;
    height: 19rem;
    width: 19rem;
    grid-template-rows: max-content max-content 2fr 1fr;
    grid-template-areas:
      "star menu"
      "icon icon"
      "title title"
      "date date";
    .item__star-btn {
      justify-self: flex-start;
    }
    .item__menu-btn {
      justify-self: flex-end;
    }
    .m-icon {
      justify-self: center;
    }
    .item__title {
      justify-self: center;
      text-align: center;
    }
    .item__date {
      justify-self: center;
    }
  }
  .item__date {
    font-family: "Gilroy-Regular";
    font-size: 1.4rem;
    color: $basic-black;
    grid-area: date;
  }
  .item__star-btn {
    grid-area: star;
    .fas {
      color: $brand-clr-1;
      &:hover {
        color: $brand-clr-1;
      }
    }
  }
  .item__menu-btn {
    grid-area: menu;
    .m-icon--download {
      transition-duration: 0.3s;
      transition-property: fill;
      fill: darken($color: $base-border-clr, $amount: 20);
      &:hover {
        fill: darken($color: $base-border-clr, $amount: 50);
      }
    }
  }
  .item__menu-btn,
  .item__star-btn {
    background: none;
    i {
      transition-duration: 0.3s;
      transition-property: color;
      font-size: 2rem;
      color: $base-border-clr;
    }
  }
  .item__menu-btn:hover,
  .item__star-btn:hover {
    i {
      color: $placeholder-text-clr;
    }
  }
}
</style>
