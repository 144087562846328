export default {
  getProperty(obj, path) {
    for (let i = 0; i < path.length; i++) {
      obj = obj[path[i]];
      if (!obj) break;
    }
    return obj;
  },
  percentageOfTheNumber(firstValue, secondValue) {
    return (100 * secondValue) / firstValue;
  },
  plural(number, values) {
    const cases = [2, 0, 1, 1, 1, 2];
    return values[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  },
  isEmpty(value) {
    if (value === undefined || value === null) {
      return true;
    }
    if (value instanceof Set || value instanceof Map) {
      return !value.size;
    }

    switch (typeof value) {
      case 'object':
        return !Object.keys(value).length;
      case 'string':
        return !value.length;
    }
    return true;
  },
  scrollBottom(block) {
    block.scrollTop = block.scrollHeight;
  },

  copyText(text) {
    const tmpTextField = document.createElement('textarea');
    tmpTextField.textContent = text;
    tmpTextField.setAttribute('style', 'position:absolute; right:200%;');
    document.body.appendChild(tmpTextField);
    tmpTextField.select();
    tmpTextField.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand('copy');
    tmpTextField.remove();
  },

  scrollTo(id = false, block = 'start') {
    if (!id) return;

    const target = document.getElementById(id);

    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block,
      });
    }
  },
};
