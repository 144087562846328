var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-checkbox"},[_c('span',{staticClass:"m-checkbox__input",on:{"click":_vm.handleChange}},[_c('span',{class:[
        'm-checkbox__inner',
        {
          'm-checkbox__inner--checked': _vm.groupMode
            ? _vm.checkLabelFunction
            : _vm.value,
        },
      ]}),_c('input',{staticClass:"m-checkbox__original",attrs:{"type":"checkbox"}})]),_c('span',{staticClass:"m-checkbox__label"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }