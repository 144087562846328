<template>
  <div class="tab-materials">
    <div class="tab-materials__tabs-block">
      <div
        @click="selectedTab = tab"
        :class="[
          'tabs-block__item',
          { 'tabs-block__item--active': tab.id === selectedTab.id },
        ]"
        v-for="tab of tabsCollection"
        :key="tab.id"
      >
        <m-icon :name="tab.icon" />
        <span class="item__title">{{ tab.title }}</span>
        <!-- <span class="item__total">{{ tab.totalFiles }} Файлов</span> -->
      </div>
    </div>
    <div v-if="selectedTab" class="tab-materials__content">
      <component
        :is="selectedTab.component"
        :treeMode="selectedTab.treeMode"
        v-model="selectedTab.data"
        :changeViews="false"
      ></component>
    </div>
  </div>
</template>

<script>
import documents from '@/components/documents';
import onlineCourse from './onlineCourse.vue';

export default {
  data() {
    return {
      selectedTab: '',
      tabsCollection: [],
      testtabsCollection: [],
    };
  },
  components: {
    onlineCourse,
    documents,
  },
  computed: {
    disciplineData() {
      return this.$store.state.discipline.disciplineData;
    },
  },
  methods: {
    setOnlineСourse(data) {
      const tab = {
        id: 1,
        title: 'Онлайн-курс',
        component: 'onlineCourse',
        icon: 'play',
        data,
      };
      this.tabsCollection.push(tab);
    },

    setWebinar(data) {
      data = data.map((w, idx) => ({
        icon: 'play',
        lable: w.title
          ? w.title
          : `Вебинар ${idx + 1} от ${this.$moment(w.date).format(
            'DD.MM.YYYY',
          )} ${w.theme ? `Тема: ${w.theme}` : ''}`,
        type: 'file',
        body: w.url ? w.url : w?.hrefList?.webinar ?? '',
      }));

      const tab = {
        id: 3,
        title: 'Вебинары',
        component: 'documents',
        treeMode: true,
        icon: 'play',
        data,
      };
      this.tabsCollection.push(tab);
    },

    setMaterials(data) {
      const tab = {
        id: 2,
        title: 'Материалы курса',
        component: 'documents',
        treeMode: true,
        icon: 'file',
        data,
      };
      this.tabsCollection.push(tab);
    },
  },
  mounted() {
    if (this.disciplineData.onlineCourse) this.setOnlineСourse(this.disciplineData.onlineCourse);
    if (this.disciplineData?.materials?.length) this.setMaterials(this.disciplineData.materials);
    if (this.disciplineData?.webinars?.length) this.setWebinar(this.disciplineData.webinars);
    if (this.disciplineData?.webinarsData?.length) this.setWebinar(this.disciplineData?.webinarsData);
  },
};
</script>

<style lang="scss">
.tab-materials {
  .tab-materials__tabs-block {
    display: flex;
    column-gap: 2.4rem;
    padding: 1.6rem 3.2rem;
    border-radius: 1rem;
    background-color: #f2f3f9;
    margin-bottom: 3.2rem;
  }
  .tabs-block__item {
    transition-duration: 0.3s;
    transition-property: background-color;
    cursor: pointer;
    width: 19rem;
    display: grid;
    row-gap: 0.3rem;
    padding: 1.6rem;
    border-radius: 1rem;
    background-color: $basic-white;
  }
  .tabs-block__item--active {
    background-color: $brand-clr-3;
  }
  .m-icon--file {
    width: 2rem;
    margin-bottom: 1rem;
  }
  .item__title {
    color: $basic-black;
    font-size: 1.4rem;
    font-family: "Gilroy-Bold";
  }
  .item__total {
    font-size: 1.2rem;
    font-family: "Gilroy-Regular";
  }
}
</style>
