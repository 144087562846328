<template>
  <el-collapse v-model="activeGroup" accordion :class="$style.buttonTab">
    <el-collapse-item name="0" :class="$style.collapse">
      <template #title>
        <div :class="$style.head">
          <m-icon name="email" :class="$style.email"/>

          <h3 :class="$style.title">Почтовый адрес</h3>

          <m-icon name="chevron" :class="$style.chevron" />
        </div>
      </template>

      <div :class="$style.body">
        ЧПОУ «Московский городской открытый колледж», 109316, г. Москва,
        Волгоградский проспект, д. 32, корп. 5, (каб.103), А/Я 26.
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>

export default {
  name: 'buttonTab',

  data() {
    return {
      activeGroup: '',
    };
  },
};
</script>

<style lang="scss" module>
.buttonTab {
  overflow: hidden;
  width: 100%;
  border-radius: 1rem;

  .collapse {
    :global(.el-collapse-item__arrow) {
      display: none;
    }

    &:global(.el-collapse-item.is-active) {
      .chevron {
        transform: rotate(90deg);
      }
    }

    &:global(.el-collapse-item .el-collapse-item__content) {
      padding-bottom: 0;
    }
  }
}

.head {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  width: 100%;
  padding: 0 2rem;
  background-color: $base-border-clr;
}

.body {
  padding: 1.2rem 2rem;
  font-size: 1.2rem;
}

.title {
  font-family: "Gilroy-Bold";
  font-size: 1.4rem;
  color: $basic-black;
}

.email {
  width: 2rem;
  margin-right: .8rem;
}

.chevron {
  width: .8rem;
  transition-duration: .3s;
  transition-property: transform;
  transform: rotate(-90deg);
}
</style>
