<template>
  <div class="task-module-practice">
    <input
      ref="input"
      v-model="name"
      placeholder="Укажите место прохождения практики"
      :disabled="isDisabled"
      class="input"
      @keydown.esc="onEscape"
    />

    <button
      v-if="practiceData.setPracticePlaceFlag"
      class="button"
      @click="onClick"
    >{{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'TaskModulePractice',

  props: {
    date: {
      type: String,
      default: '',
    },

    practiceData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      name: this.practiceData?.practicePlace ?? '',
      isDisabled: true,
    };
  },

  computed: {
    nameIsChange() {
      return this.name !== this.practiceData?.practicePlace;
    },

    buttonText() {
      return !this.isDisabled
        ? 'Сохранить'
        : this.practiceData?.practicePlace
          ? 'Изменить'
          : 'Указать';
    },
  },

  methods: {
    async onClick() {
      if (!this.isDisabled && this.nameIsChange) {
        this.$emit('change', this.name);
        this.isDisabled = true;
        return;
      }

      this.isDisabled = !this.isDisabled;

      if (!this.isDisabled) {
        await this.$nextTick();

        this.$refs.input.focus();
      }
    },

    onEscape() {
      this.name = this.practiceData?.practicePlace ?? '';
      this.isDisabled = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.task-module-practice {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  column-gap: 1.6rem;
  padding: 1.6rem;
  border-radius: 1rem;
  background-color: $basic-white;

  @include breakPoints(sm) {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }
}

.input {
  padding: 1.1rem 0.5rem;
  font-family: "Gilroy-Bold";
  font-size: 1.4rem;
  border-color: $brand-clr-1;
  color: $basic-black;
  transition-duration: 0.3s;
  transition-property: border-color;

  &:disabled {
    border-color: transparent;
    cursor: auto;
  }
}

.button {
  padding: 1.2rem 4.9rem;
  background-color: $brand-clr-1;
  border-radius: 0.8rem;
  color: $basic-black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  font-family: Gilroy-Regular;
  font-size: 1.4rem;
  color: $basic-white;
}
</style>
