<template lang="pug">
  .socials-tabs
    a.socials-tabs__link(href="https://vk.com/open_college" target="_blank")
      icon(name="vkLogo")
    a.socials-tabs__link(href="https://rutube.ru/channel/44274269/" target="_blank")
      icon(name="rutubeLogo")
    a.socials-tabs__link(href="https://t.me/open_college_official" target="_blank")
      icon(name="telegrammLogo")
</template>

<script>
import icon from '@/components/ui/icon'

export default {
  name: 'socials-tab',
  components: { icon }
}
</script>

<style lang="scss">
.socials-tabs {
  display: flex;
  width: 100%;
  column-gap: 16px;
  align-items: center;
  justify-content: center;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      .m-icon {
        fill: rgba($brand-clr-2, 80%);

        rect {
          fill: rgba($brand-clr-2, 80%);
        }
      }
    }
  }

  .m-icon {
    width: 3rem;
    fill: $brand-clr-2;
    transition: fill .3s;

    &--vkLogo {
      width: 3.2rem;
    }

    rect {
      fill: $brand-clr-2;
      transition: fill .3s;
    }
  }
}
</style>
