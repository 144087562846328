<template>
  <div class="task-module-coursework">
    <p class="text">Выберите тему курсовой работы</p>

    <button class="button" @click="$emit('select')">Выбрать</button>
  </div>
</template>

<script>
export default {
  name: 'TaskModuleCoursework',
};
</script>

<style lang="scss" scoped>
.task-module-coursework {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  column-gap: 1.6rem;
  padding: 1.6rem;
  border-radius: 1rem;
  background-color: $basic-white;

  @include breakPoints(sm) {
    grid-template-columns: 1fr;
    row-gap: 1.6rem;
  }
}

.text {
  padding: 1.1rem 0.5rem;
  font-family: "Gilroy-Bold";
  font-size: 1.4rem;
  border-color: $brand-clr-1;
  color: $basic-black;
  transition-duration: 0.3s;
  transition-property: border-color;
}

.button {
  padding: 1.2rem 4.9rem;
  background-color: $brand-clr-1;
  border-radius: 0.8rem;
  color: $basic-black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  font-family: Gilroy-Regular;
  font-size: 1.4rem;
  color: $basic-white;
}
</style>
