<template>
  <m-collapse class="m-collapse--classmates">
    <template v-slot:header>
      <div class="header__content-wrp">
        <span class="header__text"> Одногруппники</span>
        <span v-if="newMsgCount" class="header__count">{{ newMsgCount }}</span>
      </div>
    </template>
    <template v-slot:body>
      <div class="body__content">
        <div
          v-for="classmate of сlassmates"
          :key="classmate.id"
          @click="$emit('select', classmate)"
          :class="[
            'user-tab-item',
            {
              'user-tab-item--selected': value === classmate,
            },
          ]"
        >
          <div class="user-tab-item__body">
            <div class="avatar-wrp">
              <img :src="setAvatar(classmate.avatar_link)" alt="user-avatar" />
            </div>

            <span class="user-tab-item__name">{{ classmate.name }}</span>

            <transition name="fade">
              <span
                v-if="classmate.newMsgCount > 0 && !classmate.isRead"
                class="user-tab-item__unrd-msg"
              >
              </span>
            </transition>
          </div>
        </div>
      </div>
    </template>
  </m-collapse>
</template>

<script>
export default {
  props: {
    value: [Object, null],
    dataClassmates: Array,
  },

  computed: {
    plugAvatar() {
      return this.$store.state.avatarPlug;
    },

    сlassmates() {
      return this.dataClassmates?.map((el) => ({ ...el, isRead: false })) || [];
    },

    newMsgCount() {
      return this.dataClassmates.reduce((qount, el) => {
        qount += el.newMsgCount;
        return qount;
      }, 0);
    },
  },

  methods: {
    setAvatar(link) {
      if (!link) return this.plugAvatar;
      return link;
    },
  },
};
</script>

<style lang="scss">
.m-collapse--classmates {
  .user-tab-item {
    border-radius: 1rem;
    border: 1px solid transparent;
    transition-duration: 0.3s;
    transition-property: border-color;
  }

  .user-tab-item__body {
    grid-template-areas:
      "avatar name date"
      "avatar text unrd";
  }

  .user-tab-item__quantity-users {
    color: $basic-black;
    font-size: 1.2rem;
    font-family: "Gilroy-Regular";
  }

  .user-tab-item__body {
    padding: 0.8rem;
    row-gap: 0.8rem;
    column-gap: 1rem;
    grid-template-columns: max-content 1fr max-content;
    grid-template-areas:
      "avatar name date"
      "avatar text unrd";
    display: grid;
  }

  .user-tab-item--selected {
    border: 1px solid $brand-clr-1;
  }
}
</style>
