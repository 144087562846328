<template>
  <div class="reference">
    <h3 class="resume-list__header">
      Справка о контактах (для госпитализации)
    </h3>

    <h4 class="resume-list__sub-header">Выдача справки</h4>

    <el-radio-group v-model="form.reference_form">
      <el-radio :label="0">На электронный адрес</el-radio>
      <el-radio :label="1">Оригинал почтой</el-radio>
    </el-radio-group>

    <h4 class="resume-list__sub-header">Электронный адрес</h4>

    <m-input
      v-model="form.email"
      placeholder="Электронный адрес"
      :valid="!$v.form.email.$error"
      :error-message="
        !$v.form.email.required
          ? 'Обязательное поле'
          : !$v.form.email.email
          ? 'Неверный фориат почты'
          : ''
      "
    />

    <template v-if="form.reference_form">
      <h4 class="resume-list__sub-header">Почтовый адрес получения справки</h4>
      <p class="resume-list__input-desscription">
        Пример: 109316, г. Москва, Волгоградский проспект, д. 32, корп. 5,
        (каб.101)
      </p>

      <m-input
        v-model="form.address"
        placeholder="Адрес"
        :valid="!$v.form.address.$error"
        :error-message="!$v.form.address.required ? 'Обязательное поле' : ''"
      />
    </template>

    <h4 class="resume-list__sub-header">Количество справок</h4>

    <el-input-number
        v-model="form.reference_count"
        :min="1"
        :max="5"
    />

    <el-select
      v-if="false"
      popper-class="documents__popper"
      v-model="form.reference_count"
      placeholder="Select"
    >
      <el-option
        v-for="item in referenceCountOptions"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>

    <button class="help-cal__send-btn" @click="createHandler">Заказать</button>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

const FORM_DEFAULT = {
  reference_form: 0,
  email: '',
  address: '',
  reference_count: 1,
};

export default {
  name: 'ReferenceAboutContacts',

  mixins: [validationMixin],

  data() {
    return {
      isCheck: false,
      form: {
        ...FORM_DEFAULT,
      },
    };
  },

  watch: {
    form: {
      handler() {
        if (this.isCheck) {
          this.$v.$reset();
          this.isCheck = false;
        }
      },

      deep: true,
    },
  },

  validations() {
    if (!this.form.reference_form) {
      return {
        form: {
          email: {
            required,
            email,
          },
        },
      };
    }
    return {
      form: {
        email: {
          required,
          email,
        },

        address: {
          required,
        },

      },
    };
  },

  methods: {
    createHandler() {
      this.isCheck = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('create', this.form);
      }
    },
  },
};
</script>

<style lang="scss">
.reference {
  @extend %resume-list;
  justify-items: flex-start;
  padding-left: 5.3rem;
  padding-right: 5.3rem;
  width: 100%;
  max-width: 50rem;

  .resume-list__header {
    justify-self: center;
    font-size: 1.9rem;
  }

  .resume-list__input-desscription {
    margin-bottom: 0.7rem;
  }

  .resume-list__sub-header:nth-child(5) {
    margin-bottom: 0.3rem;
  }

  .help-cal__course {
    width: 4rem;
    justify-self: flex-start;
  }

  .help-cal__send-btn {
    width: 100%;
    margin: 3rem auto 0;
    background-color: $brand-clr-1;
    color: $basic-white;
    border-radius: 1rem;
    padding: 1.2rem 0;
    font-size: 1.4rem;
    font-family: "Gilroy-Bold";
  }
}
</style>
