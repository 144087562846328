var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference"},[_c('h3',{staticClass:"resume-list__header"},[_vm._v(" "+_vm._s(_vm.referenceType.name)+" ")]),_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("Выдача справки")]),_c('el-radio-group',{model:{value:(_vm.form.reference_form),callback:function ($$v) {_vm.$set(_vm.form, "reference_form", $$v)},expression:"form.reference_form"}},[_c('el-radio',{attrs:{"label":0,"disabled":!_vm.referenceType.enable_email}},[_vm._v("На электронный адрес")]),_c('el-tooltip',{attrs:{"popper-class":"tooltip-popper","content":"Внимание! Студентам из Москвы и МО справки по почте не отправляются, справку необходимо получить лично.","placement":"top-start"}},[_c('el-radio',{attrs:{"label":1,"disabled":!_vm.referenceType.enable_post}},[_vm._v("Оригинал почтой")])],1)],1),_c('m-input',{attrs:{"placeholder":"Электронный адрес","valid":!_vm.$v.form.email.$error,"error-message":!_vm.$v.form.email.required
        ? 'Обязательное поле'
        : !_vm.$v.form.email.email
        ? 'Неверный фориат почты'
        : ''},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.form.reference_form)?[_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("Почтовый адрес получения справки")]),_c('p',{staticClass:"resume-list__input-desscription"},[_vm._v(" Пример: 109316, г. Москва, Волгоградский проспект, д. 32, корп. 5, (каб.101) ")]),_c('m-input',{attrs:{"placeholder":"Адрес","valid":!_vm.$v.form.address.$error,"error-message":!_vm.$v.form.address.required ? 'Обязательное поле' : ''},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]:_vm._e(),_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("СНИЛС")]),_c('m-input',{attrs:{"mask":"000-000-000 00","max":14,"placeholder":"Электронный адрес","valid":!_vm.$v.form.snils.$error,"error-message":!_vm.$v.form.snils.required
        ? 'Обязательное поле'
        : !_vm.$v.form.snils.minLength || !_vm.$v.form.snils.maxLength
        ? 'Количество цифр должно быть 11'
        : ''},model:{value:(_vm.form.snils),callback:function ($$v) {_vm.$set(_vm.form, "snils", $$v)},expression:"form.snils"}}),_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v(" Укажите период за который требуются сведения ")]),_c('div',{staticClass:"resume-list__row"},[_c('span',[_vm._v("C")]),_c('validate',{attrs:{"valid":!_vm.$v.form.start_date.$error,"error-message":!_vm.$v.form.start_date.required ? 'Обязательное поле' : ''}},[_c('el-date-picker',{attrs:{"popper-class":"custom-class","format":"dd MM yyyy","type":"date","placeholder":"Выберите дату"},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1),_c('span',[_vm._v("По")]),_c('validate',{attrs:{"valid":!_vm.$v.form.end_date.$error,"error-message":!_vm.$v.form.end_date.required ? 'Обязательное поле' : ''}},[_c('el-date-picker',{attrs:{"popper-class":"custom-class","format":"dd MM yyyy","type":"date","placeholder":"Выберите дату"},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1)],1),_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("Количество справок")]),_c('el-input-number',{attrs:{"min":1,"max":5},model:{value:(_vm.form.reference_count),callback:function ($$v) {_vm.$set(_vm.form, "reference_count", $$v)},expression:"form.reference_count"}}),_c('button',{staticClass:"help-cal__send-btn",on:{"click":_vm.createHandler}},[_vm._v("Заказать")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }