<template>
  <div
    :class="[
      'documents__header',
      { 'documents__header--hide-btn': !changeViews },
    ]"
  >
    <!-- <el-select
      popper-class="documents__popper"
      v-model="value.sort"
      placeholder="Select"
    >
      <el-option
        v-for="item in value.options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select> -->
    <div :class="['header__input', { 'header__input--active': showInput }]">
      <button @click="handlerShowInput" class="header__input-btn">
        <m-icon name="magnifier" />
      </button>
      <m-input ref="search" v-model="value.search" iconPosition="right">
        <template v-slot:icon>
          <button @click="closeSearch">
            <m-icon name="close" />
          </button>
        </template>
      </m-input>
    </div>
    <template v-if="changeViews">
      <button
        @click="value.displayMode = 'list'"
        :class="[
          'header__display-btn',
          { 'header__display-btn--active': value.displayMode === 'list' },
        ]"
      >
        <i class="fas fa-bars"></i>
      </button>
      <button
        @click="value.displayMode = 'grid'"
        :class="[
          'header__display-btn',
          { 'header__display-btn--active': value.displayMode === 'grid' },
        ]"
      >
        <i class="fab fa-buromobelexperte"></i>
      </button>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showInput: false,
    };
  },
  props: {
    changeViews: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
    },
  },
  methods: {
    handlerShowInput() {
      this.showInput = true;
      this.$refs.search.focus();
    },
    closeSearch() {
      this.$emit('close');
      this.showInput = false;
    },
  },
};
</script>

<style lang="scss">
.documents__header {
  height: 4rem;
  display: grid;
  grid-template-columns: 1fr auto auto;
  column-gap: 1.5rem;
  .el-select {
    height: 100%;
    max-width: 13.5rem;
    .el-input .el-select__caret {
      font-size: 1.6rem;
      color: $brand-clr-1;
      font-weight: 800;
    }
    .el-input {
      height: 100%;
    }
  }
  .el-input__inner {
    border: none;
    background: $brand-clr-5;
    border-radius: 1rem;
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $basic-black;
    height: 100%;
  }
}
.documents__header--hide-btn {
  grid-template-columns: 1fr;
}
.header__input {
  position: relative;
  display: flex;
  button {
    background-color: transparent;
    position: relative;
    z-index: 5;
  }
  .m-input {
    z-index: 3;
    position: absolute;
    left: 0;
    transition-duration: 0.3s;
    width: 0;
    opacity: 0;
    .m-input__input {
      background-color: $brand-clr-5;
      color: $basic-black;
      font-size: 1.4rem;
      font-family: "ProximaNova-Regular";
    }
  }
  .m-icon--magnifier {
    cursor: pointer;
    width: 2rem;
    fill: $brand-clr-3;
  }
  .m-icon--close {
    width: 0;
    fill: $brand-clr-1;
  }
}
.header__input--active {
  .m-input {
    width: 100%;
    opacity: 1;
    z-index: 8;
  }
  .m-icon--close {
    width: 1.5rem;
    fill: $brand-clr-1;
  }
}
.header__display-btn {
  transition-duration: 0.3s;
  transition-property: background-color;
  width: 4rem;
  height: 4rem;
  border-radius: 1rem;
  background: none;
  i {
    transition-duration: inherit;
    transition-property: color;
    font-size: 2rem;
    color: $brand-clr-5;
  }
}
.header__display-btn--active {
  background: $brand-clr-5;
  i {
    color: $brand-clr-1;
  }
}

.documents__popper {
  @include dropDown($brand-clr-5, $brand-clr-5);
}
@include breakPoints(md) {
  .header__input {
    position: relative;
    justify-content: flex-end;
    width: 100%;
    .m-input {
      position: absolute;
      left: 0;
    }
  }
  .header__input-btn {
    height: 3.8rem;
  }
}
</style>
