import Vue from 'vue';
import Vuex from 'vuex';

import axios from 'axios';
import auth from './modules/auth';
import resume from './modules/resume';
import deanery from './modules/deanery';
import education from './modules/education';
import shedule from './modules/shedule';
import messages from './modules/messages';
import discipline from './modules/discipline';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    mobileWidth: 700,
    tabletWidth: 1300,
    userInfo: {
      name: '',
      group: '',
      plan: '',
      current_course: '',
      current_semestr: '',
      student: '',
      semesters_count: 0,
    },
    unreadMessages: null,
    avatarPlug: 'https://college.instudy.online/img/mgok/avatar/avatar.jpg',
    announces: null,
    meaages: false,
    curatorInfo: '',
    credit: null,
    notifyElements: [],
    navigationMax: true,
    sessionInfo: {},
    tabletMode: false,
    mobileMode: false,
    newUser: false,
    currentModalComponent: {
      name: null,
      data: null,
      drawerMode: false,
    },
    preloaderRun: false,
    showAside: false,
  },
  mutations: {
    setShowAside(state, value) {
      state.showAside = value;
    },
    setAnnounces(state, value) {
      state.announces = value;
    },
    setMessagesStatus(state, value) {
      state.meaages = value;
    },
    setPreloaderRun(state, value) {
      state.preloaderRun = value;
    },
    setCuratorInfo(state, value) {
      state.curatorInfo = value;
    },
    setCredit(state, value) {
      state.credit = value;
    },
    pushNotify(state, element) {
      element.id = +new Date();
      state.notifyElements.push(element);
    },
    shiftNotify(state, element) {
      state.notifyElements = state.notifyElements.filter(
        (el) => el.id !== element.id,
      );
    },
    setNotifyData(state, value) {
      state.notifyData = value;
    },
    setUserInfo(state, value) {
      if (
        +value.current_course_num === 1
        && value.form !== 'Заочная форма обучения'
      ) {
        state.newUser = true;
      }

      state.userInfo = value;
    },
    setMobileMode(state, value) {
      state.mobileMode = value;
    },
    setTabletMode(state, value) {
      state.tabletMode = value;
    },
    setCurrentModalComponent(state, value) {
      state.currentModalComponent = value;
    },
    setSessionInfo(state, value) {
      state.sessionInfo = value;
    },
    setNavigationMax(state, value) {
      state.navigationMax = value;
    },

    setUnreadMessages(state, value) {
      state.unreadMessages = value;
    },
  },
  actions: {
    async getUserInfo({ commit }) {
      await axios.get('api/info').then((res) => {
        if (res.data.status) {
          commit('setUserInfo', res.data.studentData);
          commit('setSessionInfo', res.data.sessionDate);
          commit('setCredit', res.data.credit);
          commit('setCuratorInfo', res.data.curatorData);
          commit('setUnreadMessages', res.data.newMessage);
        }
      });
    },
    async getMessages({ commit }) {
      await axios.get('api/messages').then((res) => {
        commit('setMessagesStatus', res.data.status);
      });
    },
    async getAnnounces({ commit }) {
      await axios.get('api/announces').then((res) => {
        if (res.data.status) {
          commit('setAnnounces', res.data.announce.tickets);
        }
      });
    },
    checkMode({ commit, state }, value) {
      if (value <= state.tabletWidth && value > state.mobileWidth) {
        commit('setTabletMode', true);
      } else {
        commit('setTabletMode', false);
      }
      if (value <= state.mobileWidth) {
        commit('setMobileMode', true);
      } else {
        commit('setMobileMode', false);
      }
    },
  },
  getters: {
    haveFullTimeForm(state) {
      return state.userInfo.form === 'Очная форма обучения';
    },

    getCorrectCredit: (state) => ({
      value: +state.credit
        ? `<span> К ОПЛАТЕ | <b>ДОЛГ ${state.credit}</b> ₽</span>`
        : `<span> К ОПЛАТЕ | <b> АКТИВЕН: ${0}</b> ₽</span>`,
      color: +state.credit !== 0 ? '#FFE946' : '#BEFAAD',
      shortValue: +state.credit
        ? `<span><b>ДОЛГ ${state.credit}</b> ₽</span>`
        : `<span><b>АКТИВЕН: ${state.credit}</b> ₽</span>`,
    }),

    getCuratorInfo: (state) => state.curatorInfo,
  },
  modules: {
    auth,
    resume,
    deanery,
    education,
    shedule,
    messages,
    discipline,
  },
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
