<template>
  <div class="order">
    <el-select
      popper-class="order__popper"
      v-model="currentComponent"
      placeholder="Укажите тип"
    >

      <el-option
        v-for="item in filtredReferenceTypes"
        :key="item.value"
        :label="item.name"
        :value="item.name_eng"
      >
      </el-option>
    </el-select>

    <component
      id="order-selected-component"
      :is="selectedComponent"
      :reference-type="referenceType"
      :reference-count="referenceCount"
      :attestation-types="normalizeAttestationTypes"
      @create="createHandler"
    />
  </div>
</template>

<script>
import referenceAboutStudy from '@/views/Deanery/orderingInformation/forms/referenceAboutStudy';
import referenceForCommissariat from '@/views/Deanery/orderingInformation/forms/referenceForCommissariat';
import referenceAboutGraduate from '@/views/Deanery/orderingInformation/forms/referenceAboutGraduate';
import referenceAboutContacts from '@/views/Deanery/orderingInformation/forms/referenceAboutContacts';
import referenceAboutPeriodsOfStudy from '@/views/Deanery/orderingInformation/forms/referenceAboutPeriodsOfStudy';
import referenceStandart from '@/views/Deanery/orderingInformation/forms/referenceStandart';
import referenceSubsidies from '@/views/Deanery/orderingInformation/forms/referenceSubsidies';
import referenceCall from '@/views/Deanery/orderingInformation/forms/referenceCall';

const REFERENCE_TYPES = {
  referenceForCommissariat: 'referenceForCommissariat',
  referenceAboutGraduate: 'referenceStandart',
  referenceAboutContacts: 'referenceStandart',
  referenceAboutPeriodsOfStudy: 'referenceStandart',
  referenceAboutStudy: 'referenceStandart',
  referenceAboutMasteringProgram: 'referenceStandart',
  referenceSubsidies: 'referenceSubsidies',
  referenceCall: 'referenceCall',
};

export default {
  data() {
    return {
      currentComponent: '',
    };
  },

  components: {
    referenceAboutStudy,
    referenceForCommissariat,
    referenceAboutGraduate,
    referenceAboutContacts,
    referenceAboutPeriodsOfStudy,
    referenceStandart,
    referenceSubsidies,
    referenceCall,
  },

  props: {
    referenceTypes: {
      type: Array,
      default: () => [],
    },

    referenceCount: {
      type: Number,
      default: () => 0,
    },

    attestationTypes: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    async currentComponent() {
      await this.$nextTick();
      this.$helpers.helperFunctions.scrollTo('order-selected-component', 'start');
    },
  },

  computed: {
    referenceType() {
      return this.referenceTypes.find((option) => option.name_eng === this.currentComponent);
    },

    normalizeAttestationTypes() {
      return this.attestationTypes.map((el, idx) => ({ label: el, value: idx }));
    },

    selectedComponent() {
      return REFERENCE_TYPES[this.currentComponent];
    },

    filtredReferenceTypes() {
      return this.referenceTypes.filter((t, idx) => idx !== 0 && (t.enable_post || t.enable_email || t.enable_personally));
    },
  },

  methods: {
    createHandler(form) {
      this.$emit('create', { ...form, reference_type: this.referenceType.index });
    },
  },
};
</script>

<style lang="scss">
.order {
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 2.4rem;
  justify-items: center;

  .el-select {
    width: 100%;
    .el-input__inner {
      border-radius: 1rem;
      border-color: $basic-white;
      background: linear-gradient(90deg, #f7f5fa 0%, #e4deee 100%);
      box-shadow: 0px 4px 4px rgb(117 69 201 / 10%);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);

      &::placeholder {
        color: $brand-clr-1;
        font-size: 1.4rem;
        font-family: "ProximaNova-Regular";
      }
    }
    .el-select__caret {
      color: $brand-clr-1;
      font-weight: bold;
    }
    .el-input.is-focus .el-input__inner {
      border-color: $brand-clr-1;
    }
  }
  .order__popper {
    .el-select-dropdown__wrap {
      background: linear-gradient(90deg, #f7f5fa 0%, #e4deee 100%);
      box-shadow: 0px 4px 4px rgb(117 69 201 / 10%);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }
}
</style>
