<template>
  <div class="statements">
    <div class="statements__header">
      <button
        v-for="(button, idx) in buttons"
        :key="idx"
        :class="[
          'statements__select-btn',
          { 'statements__select-btn--selected': currentComponent === button.component },
        ]"
        @click="currentComponent = button.component"
      >
        {{ button.title }}
      </button>
    </div>

    <components
      :is="currentComponent"
      :statements-data="statementsData"
      :status="statusSending"
      :history="history"
      @create="$emit('create-statement', $event)"
    />

  </div>
</template>

<script>
import historyStatements from '@/views/Deanery/statements/historyStatements';
import sendingStatements from '@/views/Deanery/statements/sendingStatements';
import sendingStatus from '@/views/Deanery/sendingStatus';

import { mapState } from 'vuex';

export default {
  name: 'Statements',

  components: {
    historyStatements,
    sendingStatements,
    sendingStatus,
  },

  props: {
    statusSending: {
      type: Object,
      default: () => ({}),
    },

    statementsData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      currentComponent: null,
      statementType: null,
      timeoutId: null,

      buttons: [
        {
          title: 'Отправить заявление',
          component: 'sendingStatements',
        },
        {
          title: 'История заявлений',
          component: 'historyStatements',
        },
      ],
    };
  },

  watch: {
    statusSending(newValue) {
      if (newValue) {
        this.currentComponent = 'sendingStatus';

        this.timeoutId = setTimeout(() => {
          if (newValue?.done) {
            this.currentComponent = 'historyStatements';
          }

          this.$emit('clear-status');
        }, 3000);
      }
    },
  },

  computed: {
    ...mapState({
      history: (state) => state.deanery.statementsHistory,
    }),

    statementsTypes() {
      return this.statementsData?.statementType || [];
    },
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId);
  },
};
</script>

<style lang="scss">
.statements {
  display: grid;
  row-gap: 3.2rem;
  grid-template-rows: max-content 1fr;

  &__header {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    column-gap: 2rem;
    justify-items: center;
  }

  &__select-btn {
    transition-duration: 0.3s;
    transition-property: background-color;
    border-radius: 1rem;
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    max-width: 29.2rem;
    width: 100%;
    height: 4rem;
    color: $brand-clr-1;
    background-color: transparent;
    border: 1px solid $brand-clr-1;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgba($color: $brand-clr-1, $alpha: 0.1);
    }

    &--selected {
      background-color: $brand-clr-1;
      color: $basic-white;

      &:hover {
        background-color: $brand-clr-1;
      }
    }
  }

  // .ordering__select-btn {
  //   transition-duration: 0.3s;
  //   transition-property: background-color;
  //   border-radius: 1rem;
  //   font-family: "Gilroy-Bold";
  //   font-size: 1.4rem;
  //   max-width: 29.2rem;
  //   width: 100%;
  //   height: 4rem;
  //   color: $brand-clr-1;
  //   background-color: transparent;
  //   border: 1px solid $brand-clr-1;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   &:hover {
  //     background-color: rgba($color: $brand-clr-1, $alpha: 0.1);
  //   }
  // }
  // .ordering__select-btn--selected {
  //   background-color: $brand-clr-1;
  //   color: $basic-white;
  //   &:hover {
  //     background-color: $brand-clr-1;
  //   }
  // }

  // display: grid;
  // justify-items: center;
  // row-gap: 2.4rem;

  // .el-select {
  //   width: 100%;

  //   .el-input__inner {
  //     border-radius: 1rem;
  //     border-color: $basic-white;
  //     background: linear-gradient(90deg, #f7f5fa 0%, #e4deee 100%);
  //     box-shadow: 0px 4px 4px rgb(117 69 201 / 10%);
  //     -webkit-backdrop-filter: blur(10px);
  //     backdrop-filter: blur(10px);

  //     &::placeholder {
  //       color: $brand-clr-1;
  //       font-size: 1.4rem;
  //       font-family: "ProximaNova-Regular";
  //     }
  //   }

  //   .el-select__caret {
  //     color: $brand-clr-1;
  //     font-weight: bold;
  //   }

  //   .el-input.is-focus .el-input__inner {
  //     border-color: $brand-clr-1;
  //   }
  // }

  // .order__popper {
  //   .el-select-dropdown__wrap {
  //     background: linear-gradient(90deg, #f7f5fa 0%, #e4deee 100%);
  //     box-shadow: 0px 4px 4px rgb(117 69 201 / 10%);
  //     -webkit-backdrop-filter: blur(10px);
  //     backdrop-filter: blur(10px);
  //   }
  // }
}
</style>
