<template>
  <div :class="['m-required', { 'm-required--error': !valid }]">
    <slot></slot>
    <transition name="fade">
      <div v-if="!valid" class="m-required__error-box">
        <p class="m-required__error-text">{{ errorMessage }}</p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MValidate',
  data() {
    return {
      error: false,
    };
  },

  props: {
    valid: {
      type: Boolean,
      default: () => false,
    },

    errorMessage: {
      type: String,
      default: () => '',
    },
  },
};
</script>

<style lang="scss">
.m-required {
  margin-bottom: 1.5rem;
  position: relative;

  &__error-box {
    position: absolute;
  }

  &__error-text {
    color: $danger-clr;
    font-size: 1.2rem;
  }

  .el-date-editor,
  .el-input__inner,
  .el-radio-group,
  .m-input,
  .el-select {
    margin-bottom: 0 !important;
  }
}
.m-required--error {
  .el-select {
    border-color: $danger-clr !important;
  }

  .el-radio__inner {
    border-color: $danger-clr !important;
  }

  .m-input {
    .m-input__input {
      border-color: $danger-clr !important;
    }
  }

  .el-input__inner {
    border-color: $danger-clr !important;
  }
}
</style>
