<template>
  <div class="documents">
    <header-documents
      @close="closeSearch"
      v-model="headerData"
      :changeViews="changeViews"
    />
    <ul
      v-if="!treeMode && !customContent"
      :class="['documents__list', 'documents__list--' + headerData.displayMode]"
    >
      <li
        :class="['list__item', 'list__item--' + headerData.displayMode]"
        v-for="file of sortDocumentList()"
        :key="file.id"
        @click="handleRouter(file)"
      >
        <m-progress :percent="0" :size="5.5" :strokeWidth="0.2" unit="rem" />
        <div class="item__header">
          <span class="item__title">{{ file.lable }}</span>
          <span class="item__sub-title">{{ file.control }}</span>
          <span v-if="file.lector" class="item__teacher">{{
            lectorName(file)
          }}</span>
        </div>
      </li>
    </ul>
    <m-tree v-else :dataArray="sortDocumentList()" />
  </div>
</template>

<script>
import headerDocuments from './header.vue';

export default {
  data() {
    return {
      headerData: {
        search: '',
        displayMode: 'list',
        sort: 'all',
        options: [
          {
            value: 'all',
            label: 'Все файлы',
          },
          {
            value: 'favorites',
            label: 'Избранное',
          },
          {
            value: 'date',
            label: 'По дате',
          },
        ],
      },
    };
  },
  props: {
    value: Array,
    changeViews: {
      type: Boolean,
      default: true,
    },
    treeMode: {
      type: Boolean,
      default: false,
    },
    customContent: {
      type: Boolean,
      default: false,
    },
    template: {
      type: String,
    },
  },
  components: {
    'header-documents': headerDocuments,
  },
  methods: {

    handleRouter(file) {
      const query = {
        did: file.discipline_id,
        dname: file.discipline,
        code: file.code_full,
        type: file.type,
        folder: file.folder,
        oud: file.oud,
        semestr_num: file.semestr_num,
        teacher: file.lector?.length ? file.lector[0] : '',

      };
      localStorage.setItem('selectedDiscipline', JSON.stringify(query));
      this.$router.push({
        name: 'discipline',
        params: { did: file.discipline_id },
      });
    },

    lectorName(file) {
      return file.lector?.length ? file.lector[0] : '';
    },

    closeSearch() {
      this.headerData.search = '';
    },

    trimExtends(text) {
      const newText = text.split('.').reverse();
      newText.shift();
      return newText.reverse().join('.');
    },

    handlerSearch(array) {
      const { search } = this.headerData;
      if (!search) {
        return array;
      }
      return [...array].filter((el) => (
        el.lable.toLowerCase().indexOf(search.toLowerCase()) !== -1
          || (el.children
            ? Boolean(this.handlerSearch(el.children).length)
            : false)
      ));
    },

    sortDocumentList() {
      let newArray;
      if (this.headerData.sort === 'all') {
        return this.handlerSearch(this.value);
      }
      if (this.headerData.sort === 'favorites') {
        newArray = this.value.filter((el) => el.favorites);
        return this.handlerSearch(newArray);
      }
      if (this.headerData.sort === 'date') {
        newArray = [...this.value].sort((a, b) => {
          a = +new Date(this.refactoringDate(a.date));
          b = +new Date(this.refactoringDate(b.date));
          return b - a;
        });
        return this.handlerSearch(newArray);
      }
    },

    refactoringDate(value) {
      const newValue = value
        .split('.')
        .reverse()
        .map((el) => +el);
      return newValue;
    },
    handlerFavorites(id) {
      const document = this.value.find((el) => el.id === id);
      document.favorites = !document.favorites;
    },
  },
};
</script>

<style lang="scss">
.documents {
  padding-bottom: 2rem;
  display: grid;
  grid-template-rows: max-content 1fr;
  row-gap: 2.4rem;
  .highlight {
    background-color: #b69ee0;
    height: 100%;
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $basic-black;
    display: inline-block;
    display: flex;
    align-items: center;
  }
  .m-progress {
    transition-duration: 0.3s;
    background: rgb(200, 180, 246);
    background: linear-gradient(
      356deg,
      rgba(200, 180, 246, 1) 0%,
      rgba(246, 246, 246, 1) 100%
    );
    box-shadow: 0px 4px 16px rgba(117, 69, 201, 0.38);
    .m-progress__percent {
      transition-duration: 0.3s;
      transition-property: color;
      font-size: 1.6rem;
      font-family: "Gilroy-Regular";
      color: $brand-clr-1;
    }
    .m-progress__circle {
      transition-duration: 0.3s;
      transition-property: color;
      stroke: $brand-clr-1;
    }
    .m-progress__circle-bg {
      stroke: transparent;
    }
  }

  //Настройки для select

  .documents__list {
    gap: 1.6rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 0.5rem;
  }
  .documents__list--grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  }
  .list__item {
    padding: 0.5rem;
    max-height: 16rem;
    border-radius: 1rem;
    background-color: #f8f8f8;
    display: grid;
    column-gap: 1.7rem;
    justify-content: flex-start;
    grid-template-columns: auto auto 1fr;
  }
  .list__item--list {
    padding: 1.6rem 1rem 1.6rem 1.6rem;
    width: 100%;
    grid-template-areas:
      "progress header favorite"
      "progress header favorite"
      "progress hourse favorite"
      "progress hourse favorite";
  }
  .list__item--grid {
    column-gap: 0;
    width: 100%;
    grid-template-areas:
      "progress header favorite"
      "progress header favorite"
      "hourse hourse hourse";
    .item__header {
      padding: 1.6rem 1rem;
    }
    .item__title {
      font-size: 1.4rem;
    }
    .item__teacher,
    .item__sub-title {
      font-size: 1.2rem;
    }
    .item__hourse {
      width: 100%;
      grid-template-columns: 3fr 2fr;
      padding: 1.6rem 1.4rem;
      border-radius: 0 0 1rem 1rem;
      background-color: $basic-white;
      box-shadow: 0px 5px 9px rgba(117, 69, 201, 0.3);
    }
    .m-progress {
      margin: 0 0 0 1.6rem;
    }
    .item__favorit-btn {
      padding-top: 1.3rem;
      padding-right: 1rem;
      //padding: 1.3rem 1rem;
    }
  }
  .item__teacher,
  .item__title,
  .item__sub-title,
  .item__webinar,
  .item__byYourself,
  .item__tasks,
  .item__tests {
    font-family: "ProximaNova-Regular";
    color: $basic-black;
    font-size: 1.2rem;
  }
  .item__favorit-btn {
    background-color: transparent;
    grid-area: favorite;
    justify-self: flex-end;
    align-self: flex-start;
    .m-icon {
      fill: none;
      stroke: $placeholder-text-clr;
    }
  }
  .m-progress {
    grid-area: progress;
    align-self: center;
  }
  .item__hourse {
    column-gap: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-area: hourse;
    row-gap: 0.3rem;
  }
  .item__header {
    grid-area: header;
    display: grid;
    grid-template-columns: 1fr;
  }
  .item__title {
    font-family: "Gilroy-Bold";
    text-transform: uppercase;
    font-size: 1.4rem;
  }
  .item__teacher,
  .item__sub-title {
    color: $secondary-text-clr;
    font-size: 1.4rem;
  }
  .item__teacher {
    margin-bottom: 0.5rem;
    font-family: "Gilroy-Bold";
    color: $basic-black;
  }
}
</style>
