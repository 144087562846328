var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-file",on:{"drop":function($event){$event.preventDefault();return _vm.dropStart($event)},"dragleave":function($event){return _vm.leaveFile($event)}}},[_c('div',{class:[
      'add-file__window',
      { 'add-file__window--dragenter': _vm.dragenter },
      { 'add-file__window--drop': _vm.showBackSide },
    ]},[(!_vm.showBackSide)?_c('div',{staticClass:"window__front-side"},[(_vm.dragStart)?_c('div',{staticClass:"drop-zone",attrs:{"dropzone":"copy"},on:{"dragleave":function($event){return _vm.dragLeaveF($event)},"dragenter":function($event){return _vm.dragEnterF($event)}}}):_vm._e(),_c('button',{staticClass:"add-file__close-btn",on:{"click":function($event){return _vm.closeWindow(false)}}},[_c('m-icon',{attrs:{"name":"close"}})],1),_c('span',{staticClass:"window__text"},[_vm._v("Перетащите файл сюда")])]):_c('div',{staticClass:"window__back-side"},[(_vm.dragStart)?_c('div',{staticClass:"drop-zone",attrs:{"dropzone":"copy"},on:{"dragleave":function($event){return _vm.dragLeaveF($event)},"dragenter":function($event){return _vm.dragEnterF($event)}}}):_vm._e(),_c('button',{staticClass:"add-file__close-btn",on:{"click":function($event){return _vm.closeWindow(false)}}},[_c('m-icon',{attrs:{"name":"close"}})],1),_c('div',{staticClass:"file-list"},_vm._l((_vm.files),function(file,idx){return _c('div',{key:idx,staticClass:"file-list__item"},[_c('m-icon',{attrs:{"name":_vm.checkImage(file) ? 'file-img-background' : 'file-background'}}),_c('span',{staticClass:"item__size"},[_vm._v(_vm._s(Math.ceil(file.size / 1024) + " КВ"))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(file.newName),expression:"file.newName"}],ref:'file-name' + idx,refInFor:true,class:[
              'item__name',
              { 'item__name--edit': _vm.editableFile === idx },
            ],attrs:{"readonly":_vm.editableFile !== idx,"type":"text"},domProps:{"value":(file.newName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(file, "newName", $event.target.value)}}}),_c('div',{staticClass:"item__control-block"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.editableFile === idx)?_c('button',{staticClass:"item__edit-btn",on:{"click":function($event){return _vm.saveNewFileName(file)}}},[_c('m-icon',{attrs:{"name":"save"}})],1):_c('button',{staticClass:"item__edit-btn",on:{"click":function($event){return _vm.editUploadFile(idx, file)}}},[_c('m-icon',{attrs:{"name":"edit"}})],1)]),_c('button',{staticClass:"item__remove-btn",on:{"click":function($event){return _vm.removeUploadFile(idx)}}},[_c('m-icon',{attrs:{"name":"close"}})],1)],1)],1)}),0),_c('m-input',{attrs:{"placeholder":"Сообщение"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('div',{staticClass:"back-side__button-block"},[_c('button',{staticClass:"add-file__upload-files"},[_vm._v(" Добавить "),_c('input',{ref:"input-load-file",staticClass:"input-load-file",attrs:{"multiple":"","type":"file"},on:{"change":_vm.loadFile}})]),_c('button',{staticClass:"add-file__upload-files",on:{"click":_vm.sendFile}},[_vm._v(" Отправить ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }