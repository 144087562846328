<template>
  <div
    class="messages__chat"
    id="messages-chat"
    @dragover.prevent="dragStartF()"
  >
    <transition name="fade">
      <addFile
        ref="add-file"
        :text="textMessage"
        :upoadedFiles="files"
        v-if="addFileShow"
        @close="closeWindow"
        @dropFile="dragStart = false"
        :dragStart="dragStart"
      />
    </transition>
    <template v-if="selectedChat !== null">
      <div class="chat__header">
        <button
          @click="setSelectedChat(null)"
          v-if="mobileMode"
          class="header__go-back-btn"
        >
          <m-icon name="arrow" />
        </button>
        <div class="header__user-section">
          <div class="avatar-wrp">
            <img :src="setPhoto()" alt="curator-avatar" />
          </div>

          <span class="user-section__name">{{ selectedChat.name }}</span>
        </div>
        <transition name="fade">
          <m-input
            v-if="showSearch"
            v-model="search"
            ref="searchInput"
            iconPosition="right"
            placeholder="Поиск"
          >
            <template v-slot:icon>
              <button class="serch-btn" @click="showSearch = false">
                <m-icon name="close" />
              </button>
            </template>
          </m-input>
        </transition>
        <div v-if="!mobileMode" class="header-search">
          <button class="header-search__btn" @click="showSearch = true">
            <m-icon name="magnifier" />
          </button>
        </div>
      </div>
      <div ref="chat-body" class="chat__body">
        <div
          :class="
            isUser(message.from)
              ? 'message__item--student'
              : 'message__item--incoming'
          "
          v-for="(message, idx) of normalizedMessages"
          :key="idx"
        >
          <div class="item__avatar">
            <img
              :src="isUser(message.from) ? setPhotoUser() : setPhoto()"
              alt="chat-avatar"
            />
          </div>
          <div class="item__content">
            <div v-if="message.files.length" class="item__files-list">
              <a
                v-for="(file, i) of message.files"
                :key="i"
                class="item__file"
                :href="file.path"
                ><m-icon name="file-no-background" /><span
                  class="item__file-name"
                  >{{ file.name }}</span
                ></a
              >
            </div>
            <span class="item__text" v-html="textNormalize(message.text)"></span>
          </div>
          <div class="item__date-time">
            <span class="item__date">{{ message.date }}</span>
            <span class="item__time">{{ message.time }}</span>
          </div>
        </div>
      </div>
      <div class="chat__footer">
        <button class="footer__file-btn">
          <input
            multiple
            class="input-load-file"
            ref="input-load-file"
            @change="loadFile"
            type="file"
          />
          <m-icon name="paper-clip" />
        </button>
        <m-input
          @enter="handlerSend"
          v-model="textMessage"
          iconPosition="right"
          placeholder="Сообщение"
        >
          <template v-slot:icon>
            <button @click="handlerSend" class="send-message-btn">
              <m-icon name="telegramm" />
            </button>
          </template>
        </m-input>
      </div>
    </template>
    <template v-else>
      <span class="empty-chat-msg">Выберите чат</span>
    </template>
  </div>
</template>

<script>
import Autolinker from 'autolinker';
import { mapActions, mapMutations } from 'vuex';
import addFile from './addFile';

const regExpLink = /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/;
export default {
  data() {
    return {
      search: '',
      textMessage: '',
      files: [],
      addFileShow: false,
      dragStart: false,
      showSearch: false,
    };
  },
  components: {
    addFile,
  },

  computed: {
    mobileMode() {
      return this.$store.state.mobileMode;
    },

    userInfo() {
      return this.$store.state.userInfo;
    },

    chatList() {
      return this.$store.state.messages.chatList;
    },

    selectedChat() {
      return this.$store.state.messages.selectedChat;
    },

    filtredMessages() {
      return this.chatList?.filter((item) => (item.text ? item.text?.toLowerCase()?.indexOf(this.search.toLowerCase()) !== -1 : false)) || [];
    },

    normalizedMessages() {
      return this.filtredMessages.map((el) => {
        el.text = Autolinker.link(el.text, {
          replaceFn(match) {
            if (match.type === 'url') {
              return `<a href="${match.getAnchorHref()}" target="_blank">ссылка</a>`;
            }
          },

          className: 'text-link',
        });

        return el;
      });
    },
  },

  watch: {
    selectedChat(newValue) {
      if (newValue !== null) {
        this.getChatList(newValue.id);
      } else {
        this.setChatlist([]);
      }
    },
    showSearch() {
      this.showSearch
        ? this.$nextTick(() => this.$refs.searchInput.$refs.input.focus())
        : null;
    },
    chatList(newValue) {
      if (newValue.length) {
        this.$nextTick(() => {
          const chatBody = this.$refs['chat-body'];
          this.$helpers.helperFunctions.scrollBottom(chatBody);
        });
      }
    },
  },

  methods: {
    ...mapActions({
      sendMessage: 'messages/sendMessage',
      getChatList: 'messages/getChatList',
    }),
    ...mapMutations({
      setSelectedChat: 'messages/setSelectedChat',
      setChatlist: 'messages/setChatlist',
    }),
    toggleSearch() {
      this.showSearch = !this.showSearch;
      this.search = '';
    },
    isLink(text) {
      const result = regExpLink.test(text);
      return result;
    },

    textNormalize(text) {
      const findElement = text.indexOf('#файлы#');

      if (findElement !== -1) {
        return text.substr(0, findElement);
      }

      return text;
    },
    setPhoto() {
      if (this.selectedChat.avatar) return `data:image/png;base64,${this.selectedChat.avatar}`;
      return this.selectedChat.avatar_link
        ? this.selectedChat.avatar_link
        : 'https://college.instudy.online/img/mgok/avatar/avatar.jpg';
    },
    setPhotoUser() {
      if (this.userInfo.avatar_link) return this.userInfo.avatar_link;
      return 'https://college.instudy.online/img/mgok/avatar/avatar.jpg';
    },
    closeWindow(status) {
      this.addFileShow = false;
      this.files = [];
      this.textMessage = '';
      if (status.update) this.getChatList(this.selectedChat.id);
    },
    handlerSend() {
      if (!this.textMessage) return;
      const data = {
        sender: +this.userInfo.uInstudy_id,
        text: this.textMessage,
        recipient: this.selectedChat.id,
      };
      this.sendMessage(data).then((res) => {
        this.textMessage = '';
        this.getChatList(this.selectedChat.id);
      });
    },
    isUser(sender) {
      return sender === +this.userInfo.uInstudy_id;
    },
    loadFile() {
      const { files } = this.$refs['input-load-file'];
      this.addFileShow = true;
      this.$nextTick(() => this.$refs['add-file'].addFile([...files]));
    },
    dragStartF() {
      this.dragStart = true;
      !this.addFileShow ? (this.addFileShow = true) : null;
    },
  },
};
</script>

<style lang="scss">
.empty-chat-msg {
  justify-self: center;
  align-self: center;
  font-size: 1.4rem;
  font-family: "Gilroy-Bold";
  color: $secondary-text-clr;
  width: 17.3rem;
  height: 4rem;
  border-radius: 1rem;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row-start: 1;
  grid-row-end: 3;
}
.messages__chat {
  position: relative;
  row-gap: 2rem;
  overflow: hidden;
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  padding: 2rem 1.5rem 2rem 1.5rem;
  .chat__header {
    position: relative;
    grid-template-areas: "info search";
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    .m-input {
      height: 4rem;
      position: absolute;
      bottom: 0.5rem;
      right: 0;
      max-width: calc(100% - 5.8rem);
    }
    .m-icon {
      cursor: pointer;
      width: 2rem;
      transition-duration: 0.3s;
      transition-property: fill;
      fill: $placeholder-text-clr;
      &:hover {
        cursor: pointer;
        fill: $brand-clr-1;
      }
    }
    .m-icon--close {
      width: 1.2rem;
    }
  }
  .header__user-section {
    grid-template-areas: "avatar name";
    grid-area: info;
    display: grid;
    grid-template-columns: 5rem 1fr;
    column-gap: 0.8rem;
    align-items: center;
  }
  .avatar-wrp {
    grid-area: avatar;
    align-self: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .user-section__name {
    grid-area: name;
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $basic-black;
  }
  .chat__footer {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    column-gap: 0.8rem;
  }
  .m-icon--paper-clip {
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: fill;
    fill: $placeholder-text-clr;
    width: 2rem;
  }
  .header__go-back-btn {
    background-color: transparent;
    padding: 1rem;
    grid-area: back-btn;
    justify-self: flex-start;
    .m-icon--arrow {
      fill: $brand-clr-1;
    }
  }
  .header-search {
    grid-area: search;
  }
  .header-search__btn {
    background: transparent;
  }
  .m-input {
    max-width: 100%;
    .m-input__input {
      background-color: #f2f3f5;
      color: $basic-black;
    }
    .m-input__input::placeholder {
      color: $regular-text-clr;
      font-family: "ProximaNova-Regular";
      font-size: 1.4rem;
    }
  }
  .m-icon--telegramm {
    fill: $brand-clr-1;
    width: 2rem;
  }
  .chat__body {
    display: grid;
    row-gap: 1rem;
    grid-auto-rows: max-content;
    padding: 2.4rem 0;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 1rem;
    }
  }
  .message__item {
    max-width: 50%;
    display: grid;
    padding: 1.3rem 0.8rem;
    column-gap: 0.8rem;
    row-gap: 0.8rem;
    .item__avatar {
      align-self: flex-end;
      overflow: hidden;
      border-radius: 50%;
      max-width: 5rem;
      height: 5rem;
      grid-area: a;
      img {
        width: 100%;
      }
    }
    .item__text {
      font-family: "ProximaNova-Regular";
      color: $basic-black;
      font-size: 1.2rem;

      a {
        color: $brand-clr-1;
        font-weight: bold;
      }
    }
    .item__date-time {
      display: flex;
      justify-content: space-between;
    }
    .item__time,
    .item__date {
      font-family: "Gilroy-Regular";
      font-weight: 400;
      color: $placeholder-text-clr;
      font-size: 1rem;
    }
    .item__content {
      display: grid;
      row-gap: 1rem;
      border-radius: 1rem;
      align-self: center;
      height: max-content;
      padding: 1.3rem 0.8rem;
      grid-area: b;
      word-break: break-word;
      background-color: #e3ddfa;
    }
    .item__file {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 0.5rem;
    }
    .m-icon {
      width: 2rem;
      height: 2rem;
      align-self: center;
    }
    .item__file-name {
      font-family: "Gilroy-Bold";
      color: $basic-black;
      align-self: flex-start;
    }
    .item__files-list {
      display: grid;
      row-gap: 1rem;
    }
  }
  .message__item--incoming {
    justify-self: flex-start;
    @extend .message__item;
    grid-template-areas: "a b";
    grid-template-columns: 5rem auto;
    .item__text {
      border-radius: 1rem 1rem 1rem 0;
      justify-self: flex-start;
      width: auto;
    }
    .item__date-time {
      grid-column-start: 2;
    }
  }
  .message__item--student {
    justify-self: flex-end;
    @extend .message__item;
    grid-template-columns: auto 5rem;
    grid-template-areas: "b a";
    .item__text {
      border-radius: 1rem 1rem 0 1rem;
      grid-column-start: 1;
      display: flex;
    }
  }
  .footer__file-btn {
    cursor: pointer;
    position: relative;
    background-color: transparent;
    padding: 0;
    width: 3rem;
    &:hover {
      .m-icon--paper-clip {
        cursor: pointer;
        fill: $brand-clr-1;
      }
    }
  }
  .input-load-file {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  .hidden-search {
    max-width: max-content;
    .m-input__input {
      transition-duration: 0.3s;
      display: none;
    }
  }
  .serch-btn,
  .send-message-btn {
    background-color: transparent;
    cursor: pointer;
  }
}
@include breakPoints(md) {
  .messages__chat {
    padding: 0 1rem 1rem 1rem;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: $basic-white;
    z-index: 20;
    .chat__header {
      grid-template-columns: auto 1fr;
      height: 8rem;
      grid-template-areas: "back-btn info";
    }
    .header__user-section {
      grid-template-columns: 1fr 5rem;
      grid-template-areas: "name avatar";
    }
    .user-section__name {
      text-align: center;
    }
  }
}
</style>
