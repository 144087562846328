<template>
  <div class="select-coursework-modal">
    <h2 class="title">
      {{
        mode === "select" ? "Выберите тему курсовой" : "Создайте тему курсовой"
      }}
    </h2>

    <template v-if="mode === 'select'">
      <el-select
        popper-class="documents__popper"
        v-model="value"
        placeholder="Выбрать тему"
        class="select"
      >
        <el-option
          v-for="item in list"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
          <div class="option">
            <span class="label">{{ item.label }}</span>
            <el-button
              v-if="item.value === 'create'"
              class="button"
              size="mini"
              circle
            >
              <i class="el-icon-plus icon"></i>
            </el-button>
            <!-- <span v-else class="teacher-name">{{ item.teacher }}</span> -->
          </div>
        </el-option>
      </el-select>

      <div v-if="value !== null" class="organization-block">
        <h3 class="label">
          Укажите название организации, на примере которой вы пишете работу
        </h3>
        <validate
          :valid="!$v.form.newName.$error"
          :error-message="!$v.form.newName.required ? 'Обязательное поле' : !$v.form.newName.minLength ? 'Минимальное число символов 5' : ''"
        >
          <el-input
            v-model="form.newName"
            placeholder="Название организации"
            class="input"
          />
        </validate>

        <button
          class="button"
          @click="onSave"
        >
          Сохранить
        </button>
      </div>
    </template>

    <template v-else-if="(mode = 'create')">
      <el-input v-model="form.newName" placeholder="Введите тему" class="input" />

      <button class="button" @click="onClick">Сохранить</button>
    </template>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import validate from '@/components/ui/validate';

export default {
  name: 'selectCourseworkModal',

  components: {
    validate,
  },

  mixins: [validationMixin],

  props: {
    modalData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isCheck: false,
      value: null,
      mode: 'select',

      form: {
        newName: '',
      },
    };
  },

  computed: {
    list() {
      return this.modalData.list.reduce(
        (acc, item, idx) => {
          acc.push({
            label: item.Topic_name,
            value: idx,
          });

          return acc;
        },
        [{ label: 'Задать свою тему', value: 'create' }],
      );
    },
  },

  watch: {
    value(newValue) {
      if (newValue === 'create') {
        this.mode = 'create';
      }
    },

    form: {
      handler() {
        if (this.isCheck) {
          this.$v.$reset();
          this.isCheck = false;
        }
      },

      deep: true,
    },
  },

  validations() {
    return {
      form: {
        newName: {
          required,
          minLength: minLength(5),
        },
      },
    };
  },

  methods: {
    onClick() {
      this.modalData.cb('newName');
    },

    onSave() {
      this.isCheck = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.modalData.cb(this.value, this.form.newName);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-coursework-modal {
  display: grid;
  border-radius: 1rem;
  background-color: $basic-white;
  padding: 4rem;
}

.title {
  color: $basic-black;
  margin-bottom: 2.4rem;
}

.select,
.input {
  width: 400px;

  @include breakPoints(xs) {
    width: 300px;
  }
}

.input {
  padding: 0;
  margin-bottom: 10px;
  border: none;
  color: $basic-black;
}

.option {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 360px;

  .button {
    width: 18px;
    height: 18px;
    padding: 0;
    transition-duration: 0.3s background-color;
  }

  &:hover {
    .button {
      background-color: $success-clr;
      color: $basic-white;
      fill: $basic-white;
    }

    .icon {
      color: $basic-white;
    }
  }
}

.label {
  margin-right: 20px;
}

.teacher-name {
  justify-self: flex-end;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button {
  padding: 1.2rem 4.9rem;
  background-color: $brand-clr-1;
  border-radius: 0.8rem;
  color: $basic-black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  font-family: Gilroy-Regular;
  font-size: 1.4rem;
  color: $basic-white;
  transition-duration: 0.3s;
  transition-property: background-color;

  &:hover {
    background-color: lighten($color: $brand-clr-1, $amount: 10%);
  }

  &[disabled] {
    background-color: lighten($color: $brand-clr-1, $amount: 10%);
    cursor: not-allowed;
  }
}

.organization-block {
  display: grid;
  row-gap: 10px;
  padding-top: 20px;

  span {
    font-weight: 500;
  }
}
</style>
