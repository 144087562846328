<template>
  <div :class="$style.syncCalendarModalList">
    <p :class="$style.desc">
      В календаре появятся только те мероприятия, где вы организатор или
      участник.
    </p>

    <ul :class="$style.list">
      <li v-for="(item, idx) in list" :key="idx" :class="$style.item" @click="$emit('select', idx)">
        <m-icon :name="item.icon" :class="$style.itemIcon" />
        <span>{{ item.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'syncCalendarModalList',

  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" module>
.syncCalendarModalList {
  .itemIcon {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    fill: none;
  }
}

.list {
  display: flex;
  flex-direction: column;
}

.desc {
  margin-bottom: 3.6rem;
  font-size: 15px;
}

.item {
  display: flex;
  align-items: center;
  padding: 2rem 2.4rem;
  border: 0.1rem solid $base-border-clr;
  border-radius: 1rem;
  background-color: transparent;
  transition-duration: 0.3s;
  transition-property: background-color;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &:hover {
    background-color: $base-border-clr;
  }
}
</style>
