<template>
  <div class="event__item">
    <div class="item__main">
      <span class="main__time"
        >{{ modalData.time_begin }} - {{ modalData.time_end }}</span
      >
      <h4 class="main__title">{{ modalData.discipline }}</h4>
      <span class="main__room">{{ modalData.room }}</span>
      <span class="main__teacher">{{ modalData.teacher }}</span>
    </div>
    <div v-if="!modalData.room" class="item__footer">
      <a class="footer__link" target="blank" :href="setLink(modalData)"
        >Перейти к событию <m-icon name="arrow"
      /></a>
      <a v-if="modalData.hrefList.presentation" class="footer__link" target="blank" :href="modalData.hrefList.presentation"
        >Скачать презентацию <m-icon name="download"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalData: Object,
  },
  methods: {
    setLink(data) {
      if (data.token) return data.hrefList?.webinar;
      if (data.record) return data.record;
      return data.href;
    },
  },
};
</script>

<style lang="scss">
.event__item {
  min-width: 24rem;
  max-width: 24.4rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0px 5px 9px rgba(75, 90, 202, 0.3);
  background-color: #4b5aca;
  color: white;

  .item__main {
    color: inherit;
    display: grid;
    row-gap: 0.5rem;
    padding: 1rem 0.8rem 1.1rem 0.8rem;
  }

  .main__room,
  .main__teacher,
  .task-list__item,
  .main__title,
  .main__time {
    color: inherit;
  }

  .task-list__item {
    list-style: disc;
    font-family: "ProximaNova-Regular";
    font-size: 1.2rem;
  }

  .main__teacher {
    font-size: 1.2rem;
  }

  .main__room {
    font-size: 1.2rem;
  }

  .main__title {
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .main__time {
    font-family: "Gilroy-Regular";
  }

  .item__footer {
    background-color: $basic-white;
  }

  .footer__link {
    padding: 0.8rem 1rem;
    color: $basic-black;
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .m-icon--download {
    width: 1.8rem;
  }

  .m-icon--arrow {
    width: 1.8rem;
    transform: rotate(180deg);
  }

}
.event__item + .m-button {
  right: 0.5rem !important;
  top: 0.5rem !important;
  background-color: $basic-black !important;
}
</style>
