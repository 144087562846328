<template>
  <div class="schedule">
    <div
      :class="[
        'schedule__content',
        'page',
        { 'schedule__content--border-radius': !showAside && !mobileMode },
      ]"
    >
      <h3 class="text-title">Расписание</h3>
      <schedule-calendar
        ref="schedule-calendar"
        :events="events"
        :teachers="teachers"
        :groups="groups"
        :is-load="isLoad"
        :selectedDay="selectedDay"
        @selectDay="selectDay"
        @change="handleChange"
        @search="handleSearch"
      />
    </div>
    <aside-bar
      :class="{ 'aside---show': showAside || mobileMode }"
      :componentsList="['eventsTab']"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import asideBar from '@/components/asideBar';
import scheduleСalendar from './scheduleСalendar.vue';

export default {
  data() {
    return {
      showAside: false,
    };
  },
  components: {
    'aside-bar': asideBar,
    'schedule-calendar': scheduleСalendar,
  },
  computed: {
    ...mapState({
      teachers: state => state.shedule.teachers,
      groups: state => state.shedule.groups,
      isLoad: state => state.shedule.isLoad,
      events: state => state.shedule.events
    }),
    userInfo() {
      return this.$store.state.userInfo;
    },

    mobileMode() {
      return this.$store.state.mobileMode;
    },

    selectedDay() {
      return this.$store.state.shedule.selectedDay;
    },

    getSheduleInterval() {
      const dateStart = this.$refs['schedule-calendar'].firstDayOfTheWeek.format('YYYY-MM-DD');
      const dateEnd = this.$refs['schedule-calendar'].lastDayOfTheWeek.format('YYYY-MM-DD');
      return { dateStart, dateEnd };
    },
  },
  watch: {
    showAside(newValue) {
      this.setNavigationMax(!newValue);
    },
  },
  methods: {
    ...mapActions({
      getSchedule: 'shedule/getSchedule',
      getTeachers: 'shedule/getTeachers',
      getGroups: 'shedule/getGroups'
    }),

    ...mapMutations({
      setSelectedDay: 'shedule/setSelectedDay',
      setNavigationMax: 'setNavigationMax',
      setSchedule: 'shedule/setSchedule',
    }),

    handleSearch({ type, value }) {
      this[type === 'teacher' ? 'getTeachers' : 'getGroups'](value);
    },

    setFirstDayOfTheWeek() {
      this.setSelectedDay(this.$refs['schedule-calendar'].firstDayOfTheWeek);
    },

    selectDay(day) {
      this.selectedDay !== null
      && this.selectedDay.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
        ? this.setSelectedDay(null)
        : this.setSelectedDay(day);
      if (this.events.length && !this.mobileMode) {
        this.selectedDay && this.events.length
          ? (this.showAside = true)
          : (this.showAside = false);
      }
    },

    async handleChange(payload) {
      this.setSelectedDay(null);
      await this.getSchedule(payload);

      if (this.mobileMode) {
        this.setFirstDayOfTheWeek();
      }
    },
  },

  async mounted() {
    if (this.userInfo.form === 'Очная форма обучения') {
      const link = this.$store.state.auth.user.uInstudy_id === '18711'
        ? 'https://docs.google.com/spreadsheets/d/1ckkGcH6Sl-e4IzYSWU_bjDfCQW12lXF0MFDRUPw7C9U/edit#gid=50816323'
        : 'https://docs.google.com/spreadsheets/d/1XuDZVnfjl9cn4SGrqjA3CLgYyzyPNsQKI0xNNC48Aa4/edit#gid=634201536';

      window.open(link, '_blank');
    }

    await Promise.all([
      this.getSchedule(this.getSheduleInterval),
      this.getTeachers(),
      this.getGroups()
    ])

    this.setSelectedDay(this.$moment())
  },
  beforeDestroy() {
    this.setSelectedDay(null);
    this.setSchedule([]);
  },
};
</script>

<style lang="scss">
.schedule {
  grid-template-areas: "main aside";
  overflow: hidden;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  .text-title {
    margin-bottom: 0.5rem;
  }
  .schedule__content {
    grid-area: main;
    border-radius: 2rem 0 0 0;
    height: 100%;
    overflow: auto;
    padding: 4rem 4rem 0 4rem;
  }
  .schedule__content--border-radius {
    border-radius: 2rem 2rem 0 0;
  }
  .aside {
    grid-area: aside;
    transition-property: width;
    transition-duration: 0.3s;
    width: 0;
    grid-template-rows: 1fr;
  }
  .aside__body {
    position: relative;
    height: 100%;
  }
  .events-tab {
    position: absolute;
    width: 24.4rem;
    height: 100%;
    top: 2.4rem;
    left: 2.4rem;
  }
  .aside---show {
    width: 30rem;
  }
}
@include breakPoints(md) {
  .schedule {
    grid-auto-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "main"
      "aside";
    .text-title {
      display: none;
    }
    .schedule__content {
      border-radius: 0;
      padding: 1rem 2rem;
    }
    .aside {
      width: 100%;
      background-color: $basic-white;
      border-radius: 0;
    }
    .aside__body {
      width: 100%;
      padding-top: 0;
      .events-tab {
        padding: 1rem;
        overflow-y: auto;
        position: static;
        width: 100%;
        &::-webkit-scrollbar {
          width: 1rem;
        }
      }
    }
    .event__item {
      max-width: 35rem;
    }
  }
}
</style>
