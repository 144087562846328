<template>
  <m-collapse class="notification">
    <template v-slot:header>
      <div class="header__content-wrp">
        <span class="header__text">Объявления</span>
      </div>
    </template>
    <template v-slot:body>
      <div class="body__content">
        <m-collapse
          class="notification__inner-collapse"
          v-for="tab of tabs"
          :key="tab.id"
        >
          <template v-slot:header>
            <div class="header__content-wrp">
              <el-checkbox v-model="tab.status" />
              <span class="header__text">{{ tab.title }}</span>
            </div>
          </template>
          <template v-slot:body>
            <div class="body__content">
              <p>{{ tab.text }}</p>
            </div>
          </template>
        </m-collapse>
      </div>
    </template>
  </m-collapse>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: 'Опросник',
          text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias aperiam laudantium eos enim autem aliquam nesciunt ipsam necessitatibus, vel aspernatur? Exercitationem tempora aspernatur quia nemo voluptatibus laudantium optio architecto repellat.',
          status: true,
        },
        {
          id: 2,
          title: 'Начало обучения',
          text: 'Уважаемые студенты, рады приветствовать вас на вашем первом учебном семестре.5 октября в 18:00 (по московскому времени) у вас пройдет организационное собрание в формате онлайн на котором мы вам подробно расскажем о том, как будет проходить ваше обучение и познакомим со всем функционалом учебного портала. Ссылка для участия в вебинаре у вас появится на календаре, который расположен на главной странице. Успехов в учебе! Если возникнут вопросы, вы можете обратиться к вашему куратору, через конверт , расположенный в правом верхнем углу!',
          status: true,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss">
.notification {
  border-radius: 1rem;
  .m-collapse__header {
    border-radius: 1rem;
    padding: 1.1rem 1.5rem;
    background-color: $brand-clr-3;
  }
  .body__content {
    padding: 0.8rem 0;
    display: grid;
    row-gap: 0.8rem;
    p {
      font-family: "ProximaNova-Regular";
      color: $basic-black;
      font-size: 1rem;
    }
  }
  .m-collapse__header-icon {
    color: $brand-clr-1;
  }
  .header__text {
    font-family: "Gilroy-Bold";
    font-size: 1.4rem;
    color: $basic-black;
  }
  .notification__inner-collapse {
    .el-checkbox__input.is-checked .el-checkbox__inner {
      border: 0.2rem solid $basic-white;
      width: 1.6rem;
      height: 1.6rem;
      border-radius: 0.3rem;
    }
    .el-checkbox__inner::after {
      border: 2px solid #fff;
      border-left: 0;
      border-top: 0;
      height: 7px;
      left: 4px;
      top: 1px;
    }
    .m-collapse__header {
      background-color: $brand-clr-1 !important;
    }
    .header__content-wrp {
      display: flex;
      justify-content: flex-start;
      column-gap: 0.8rem;
      align-items: center;
    }
    .header__text {
      color: $basic-white;
      font-size: 1rem;
    }
    .m-collapse__header-icon {
      color: $basic-white !important;
    }
  }
}
</style>
