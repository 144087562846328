<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    class="xd476a__calendar-icon"
  >
    <path
      d="M19.9995 37.9632C29.9203 37.9632 37.9628 29.9207 37.9628 19.9998C37.9628 10.079 29.9203 2.0365 19.9995 2.0365C10.0786 2.0365 2.03613 10.079 2.03613 19.9998C2.03613 29.9207 10.0786 37.9632 19.9995 37.9632Z"
      fill="#FC3F1D"
    ></path>
    <path
      d="M26.5004 31.2766H22.5571V11.7733H20.8004C17.5804 11.7733 15.8937 13.3833 15.8937 15.7866C15.8937 18.5133 17.0571 19.7766 19.4637 21.3866L21.4471 22.7233L15.7471 31.2733H11.5071L16.6371 23.64C13.6871 21.5333 12.0271 19.4766 12.0271 16.0066C12.0271 11.67 15.0504 8.71997 20.7737 8.71997H26.4737V31.27H26.5004V31.2766Z"
      fill="white"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'yandexCalendar',
};
</script>
