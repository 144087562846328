<template>
    <div class="reference" id="installment-plan">
      <h3 class="resume-list__header">Заявление на рассрочку</h3>
      <template v-if="showDescription">
        <h4 class="resume-list__sub-header">Правила предоставления рассрочки:</h4>

        <ul class="reference__rules">
          <li class="reference__rules-item">
            <b>1.</b> Вы можете оформить рассрочку, если у вас нет финансовых долгов.
          </li>
          <li class="reference__rules-item">
            <b>2.</b> При оформлении рассрочки стоимость семестра остается прежней.
          </li>
          <li class="reference__rules-item">
            <b>3.</b> Оформить рассрочку можно только до начала учебного семестра. Для студентов очной формы обучения до 01 сентября, для заочной формы обучения до 01 октября.
          </li>
          <li class="reference__rules-item">
            <b>4.</b> Срок оплаты в рассрочку зависит от того, на какой семестр вы ее берете. По четным семестрам оплата до 31 марта, по нечетным до 30 ноября.
          </li>
          <li class="reference__rules-item">
            <b>5.</b> Вы можете выбрать, сколько вы хотите платить ежемесячно, пока не выполните все платежи.
          </li>
          <li class="reference__rules-item">
            <b>6.</b> Оформляя заявление на рассрочку, вы соглашаетесь с условиями и графиком платежей и понимаете, что за несвоевременную оплату вас могут отчислить.
          </li>
          <li class="reference__rules-item">
            <b>7.</b> На каждый семестр заявление на рассрочку оформляется отдельно.
          </li>
        </ul>

        <button class="reference__btn" @click="showDescription = false">
          Продолжить
        </button>
      </template>

      <template v-else-if="!data.installmentMonths.length">
        <p class="reference__rules-item">Оформление рассрочки доступно с 01 августа по 15 сентября и с 01 декабря по 01 февраля.</p>
      </template>

      <template v-else>
        <h4 class="resume-list__sub-header">Укажите причину</h4>

        <validate
          :valid="!$v.form.statement_reason_installment.$error"
          error-message="Обязательное поле"
        >
        <el-select
          popper-class="order__popper"
          v-model="form.statement_reason_installment"
          placeholder="Выберите причину"
        >
          <el-option
            v-for="reason in data.vacationType"
            :key="reason"
            :label="reason"
            :value="reason"
          >
          </el-option>
        </el-select>
        </validate>

        <h4 class="resume-list__sub-header">
          На сколько месяцев разделить платеж
        </h4>

        <el-input-number v-model="form.statement_number_of" :min="1" :max="data.StatementNumberOfMax" />

        <h4 class="resume-list__sub-header">
          Месяц первого платежа
        </h4>

        <validate
          :valid="!$v.form.statement_date.$error"
          error-message="Обязательное поле"
        >
          <el-select
            popper-class="documents__popper"
            v-model="form.statement_date"
            placeholder="Укажите месяц"
          >
            <el-option
              v-for="month in data.installmentMonths"
              :key="month"
              :label="month"
              :value="month"
            >
            </el-option>
          </el-select>
        </validate>

        <h4 class="resume-list__sub-header">
          Сумма, которую вы планируете вносить ежемесячно
        </h4>

        <validate
          :valid="!$v.form.statement_summ.$error"
          error-message="Обязательное поле"
        >
          <el-input-number v-model="form.statement_summ" :step="1000" :min="0" />
        </validate>

        <div class="reference__approval">
          <m-checkbox v-model="approval" />

          <p class="text-description">
            Я ознакомлен(а) с правилами за нарушение предупреждений и об
            отчислении в случае <br />
            нарушения предупреждений
          </p>
        </div>

        <button
          class="reference__btn"
          :disabled="!approval"
          @click="createHandler"
        >
          Заказать
        </button>
      </template>
    </div>
  </template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import validate from '@/components/ui/validate';

const FORM_DEFAULT = {
  statement_date: '',
  statement_reason_installment: '',
  statement_number_of: 1,
  statement_summ: 1000,
};

export default {
  name: 'installmentPlan',

  components: {
    validate,
  },

  mixins: [validationMixin],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      showDescription: true,
      isCheck: false,
      form: {
        ...FORM_DEFAULT,
      },

      approval: false,
    };
  },

  watch: {
    form: {
      handler() {
        if (this.isCheck) {
          this.$v.$reset();
          this.isCheck = false;
        }
      },

      deep: true,
    },
  },

  validations() {
    return {
      form: {
        statement_date: {
          required,
        },
        statement_reason_installment: {
          required,
        },
        statement_number_of: {
          required,
        },
        statement_summ: {
          minValue: minValue(1000),
        },
      },
    };
  },

  mounted() {
    if (this.$isMobile()) {
      this.$helpers.helperFunctions.scrollTo('installment-plan', 'start');
    }
  },

  methods: {
    createHandler() {
      this.isCheck = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('create', this.form);
      }
    },
  },
};
</script>

  <style lang="scss">
  .reference {
    @extend %resume-list;
    justify-items: flex-start;
    padding-left: 5.3rem;
    padding-right: 5.3rem;
    width: 100%;
    max-width: 50rem;

    @include breakPoints(md) {
      padding: 2rem;
    }

    .resume-list__header {
      justify-self: center;
    }

    .resume-list__input-desscription {
      margin-bottom: 0.7rem;
    }

    .resume-list__sub-header:nth-child(5) {
      margin-bottom: 0.3rem;
    }

    .help-cal__course {
      width: 4rem;
      justify-self: flex-start;
    }

    &__approval {
      display: flex;
      column-gap: 0.9rem;
      align-items: flex-start;
    }

    &__rules {
      display: flex;
      flex-direction: column;
      row-gap: 0.1rem;
    }

    &__rules-item {
      font-family: "ProximaNova-Regular";
      font-size: 1.4rem;
      line-height: 1.64rem;
      color: $brand-clr-2;

      &:not(:last-child) {
        margin-bottom: .5rem;
      }

      b {
        font-family: "Gilroy-Bold";
        font-size: 1.4rem;
        color: $basic-black;
      }
    }

    &__btn {
      width: 100%;
      margin: 3rem auto 0;
      background-color: $brand-clr-1;
      color: $basic-white;
      border-radius: 1rem;
      padding: 1.2rem 0;
      font-size: 1.4rem;
      font-family: "Gilroy-Bold";
      transition-duration: 0.3s;
      transition-property: background-color;

      &:disabled {
        background-color: $placeholder-text-clr;
        cursor: not-allowed;

        &:hover {
          background-color: $placeholder-text-clr;
        }
      }

      &:hover {
        background-color: lighten($color: $brand-clr-1, $amount: 2);
      }
    }

    .m-required {
      .el-input {
        margin-bottom: 0;
      }
    }
  }
  </style>
