<template>
  <div class="preloader-wrp">
    <div class="preloader-wrp__background">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.preloader-wrp {
  z-index: 300;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preloader-wrp__background {
  width: 20rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: linear-gradient(
    104.04deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.14) 100%
  );
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 8rem;

  height: 8rem;
}
.lds-ellipsis div {
  position: absolute;
  top: 3.3rem;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  background: $brand-clr-1;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 0.8rem;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 0.8rem;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 3.2rem;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 5.6rem;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(2.4rem, 0);
  }
}
</style>
