<template>
  <div class="list-of-question">
    <h3 class="text-title">Список вопросов</h3>
    <div class="list-of-question__list">
      <div
        :class="['list__item', { 'list__item--decided': question.status }]"
        v-for="question of listOfQuestion"
        :key="question.number"
      >
        {{ question.number }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listOfQuestion: [
        {
          number: 1,
          status: true,
        },
        {
          number: 2,
          status: false,
        },
        {
          number: 3,
          status: true,
        },
        {
          number: 4,
          status: true,
        },
        {
          number: 5,
          status: true,
        },
        {
          number: 6,
          status: true,
        },
        {
          number: 7,
          status: true,
        },
        {
          number: 8,
          status: false,
        },
        {
          number: 9,
          status: false,
        },
        {
          number: 10,
          status: false,
        },
        {
          number: 11,
          status: false,
        },
        {
          number: 12,
          status: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.list-of-question {
  .list-of-question__list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .list__item {
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    border: 1px solid $brand-clr-1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    color: $brand-clr-1;
    font-family: "ProximaNova-Regular";
  }
  .list__item--decided {
    background-color: $brand-clr-1;
    color: $basic-white;
  }
}
</style>
