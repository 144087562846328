import axios from 'axios';

export default {
  namespaced: true,

  state: {
    selectedDay: null,
    events: [],
    eventsForTheMonth: [],
    month: false,
    teachers: [],
    groups: [],
    isLoad: false,
  },
  mutations: {
    setIsLoad(state, value) {
      state.isLoad = value;
    },
    setGroups(state, value) {
      state.groups = value;
    },
    setSelectedDay(state, value) {
      state.selectedDay = value;
    },
    setSchedule(state, value) {
      state.events = value;
    },
    setMonthlySchedule(state, value) {
      state.month = true;
      state.eventsForTheMonth = value;
    },
    setTeachers(state, value) {
      state.teachers = value;
    }
  },

  actions: {
    async getGroups({ commit }, search= '') {
      try {
        commit('setIsLoad', true);
        const { data: { groups } } = await axios.get('/api/dropdown/groups', { params: { search } });

        commit('setGroups', groups);
      } catch (e) {
        console.warn('Failed fetch [store/shedule/getGroups]', e);
      } finally {
        commit('setIsLoad', false);
      }
    },
    async getTeachers({ commit }, search= '') {
      try {
        commit('setIsLoad', true);
        const { data: { teachers } } = await axios.get('/api/dropdown/teachers', { params: { search } });

        commit('setTeachers', teachers);
      } catch (e) {
        console.warn('Failed fetch [store/shedule/getTeachers]', e);
      } finally {
        commit('setIsLoad', false);
      }
    },

    async getSchedule({ commit }, date) {
      try {
        commit('setIsLoad', true);
        const { data: { schedule } } = await axios.get('api/schedule', { params: date })
        commit('setSchedule', schedule)
      } catch (e) {
        console.warn('Failed fetch [store/shedule/getSchedule]', e);
      } finally {
        commit('setIsLoad', false);
      }
    },
    async getMonthlySchedule({ commit }, month) {
      await axios
        .get('api/events', {
          params: {
            month,
          },
        })
        .then((res) => {
          if (res.data.status) {
            commit('setMonthlySchedule', res.data.events);
          }
        });
    },
  },
  getters: {
    eventsOnSelectedDay(state) {
      if (!state.selectedDay) return [];
      return state.events.filter((event) => event.date === state.selectedDay.format('YYYY-MM-DD'));
    },
  },
};
