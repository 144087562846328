<template>
  <div class="library">
    <h3 class="library__header">Электронная библиотека</h3>

    <div class="libary__wrap">
      <img
        class="library__logo"
        src="@/assets/img/services/libary-logo.png"
        alt="platform-logo"
      />
      <img
        class="library__logo"
        src="@/assets/img/services/lan-logo.jpg"
        alt="platform-logo"
      />
    </div>

    <p class="library__text">
      Каждый студент нашего учебного заведения имеет возможность бесплатно
      работать с лицензионной полнотекстовой базой электронных изданий Юрайт и Лань.
    </p>

    <p class="library__text library__text--last">
      В данной системе опубликованы надежные и полезные ресурсы, предназначенные
      для студентов разных специальностей, предоставляющие знания для успешной
      сдачи сессии, прохождения аттестации, написания научных трудов, подготовки
      проектов и последующей успешной работы.
    </p>

    <div class="library__buttons">
      <a
        href="https://lanbook.com/"
        target="blank"
        class="library__button library__button--filling"
      >
        Перейти в Лань
      </a>

      <a
        v-show="link"
        :href="link"
        target="blank"
        class="library__button library__button--filling"
      >
        Перейти в Юрайт
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'library',

  data() {
    return {
      link: '',
    };
  },

  async mounted() {
    try {
      const { status, data } = await this.axios.get('/api/library-url');

      if (status === 200) {
        this.link = data;
      }
    } catch (e) {
      console.warn('Failed fetch library link', e);
    }
  },
};
</script>

<style lang="scss">
.library {
  @extend %library_and_plagism;
}
</style>
