<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" class="xd476a__calendar-icon">
        <path d="M17.4329 28.2407C17.4329 29.3694 18.3489 30.2855 19.4776 30.2855H36.8579C37.9866 30.2855 38.9026 29.3694 38.9026 28.2407V12.9052C38.9026 11.7765 37.9866 10.8605 36.8579 10.8605H19.4776C18.3489 10.8605 17.4329 11.7765 17.4329 12.9052V28.2407Z" fill="#03A9F4"></path>
        <path d="M38.9031 13.9021V12.9052C38.9031 12.4677 38.763 12.0638 38.5279 11.7316L27.0119 18.8186L19.3012 14.0585L18.5835 15.9723L27.2317 21.0842L38.9031 13.9021Z" fill="#B3E5FC"></path>
        <path d="M23.4697 38.9349L2 34.8454V6.21911L23.4697 2.12964V38.9349Z" fill="#0277BD"></path>
        <path d="M17.6536 15.2679C16.531 13.9644 15.0506 13.3121 13.2287 13.3121C11.2944 13.3121 9.74452 13.9961 8.57288 15.3599C7.40329 16.7268 6.81543 18.517 6.81543 20.7233C6.81543 22.814 7.39205 24.5204 8.54323 25.8208C9.69442 27.1223 11.1932 27.7735 13.0192 27.7735C14.9003 27.7735 16.4247 27.107 17.5932 25.7544C18.7618 24.412 19.3435 22.6433 19.3435 20.4391C19.3435 18.3044 18.7802 16.5806 17.6536 15.2679ZM15.4381 23.8804C14.8574 24.6666 14.0691 25.0581 13.0733 25.0581C12.1133 25.0581 11.3363 24.6553 10.7372 23.8446C10.1432 23.042 9.84777 21.9419 9.84777 20.5617C9.84777 19.1959 10.1432 18.0938 10.7372 17.2646C11.3363 16.4365 12.1358 16.0265 13.1449 16.0265C14.1284 16.0265 14.9013 16.4283 15.4626 17.2309C16.03 18.0437 16.3143 19.1683 16.3143 20.6149C16.3153 22.0104 16.0198 23.0962 15.4381 23.8804Z" fill="white"></path>
    </svg>
</template>

<script>
export default {
  name: 'outlook',
};
</script>
