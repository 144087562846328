<template>
    <div class="reference">
      <h3 class="resume-list__header">Справка о выпуске</h3>
      <h4 class="resume-list__sub-header">Выдача справки</h4>
      <el-radio-group v-model="form.reference_form">
        <el-radio :label="0">На электронный адрес</el-radio>
        <el-radio :label="1">Оригинал почтой</el-radio>
      </el-radio-group>

      <m-input
        v-model="form.email"
        placeholder="Электронный адрес"
        :valid="!$v.form.email.$error"
        :error-message="
          !$v.form.email.required
            ? 'Обязательное поле'
            : !$v.form.email.email
            ? 'Неверный фориат почты'
            : ''
        "
      />

      <m-input
        v-if="form.reference_form"
        v-model="form.address"
        placeholder="Адрес"
        :valid="!$v.form.address.$error"
        :error-message="
          !$v.form.address.required
            ? 'Обязательное поле'
            : ''
        "
      />

      <el-select
        popper-class="documents__popper"
        v-model="form.reference_count"
        placeholder="Select"
      >
        <el-option
          v-for="item in referenceCountOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <button class="help-cal__send-btn" @click="createHandler">Заказать</button>
    </div>
  </template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

const FORM_DEFAULT = {
  reference_form: 0,
  email: '',
  address: '',
  reference_count: 1,
};

export default {
  name: 'referenceAboutGraduate',

  mixins: [validationMixin],

  data() {
    return {
      isCheck: false,
      form: {
        ...FORM_DEFAULT,
      },

      referenceCountOptions: [
        {
          label: 1,
          value: 1,
        },
        {
          label: 2,
          value: 2,
        },
        {
          label: 3,
          value: 3,
        },
        {
          label: 4,
          value: 4,
        },
        {
          label: 5,
          value: 5,
        },
      ],
    };
  },

  watch: {
    form: {
      handler() {
        if (this.isCheck) {
          this.$v.$reset();
          this.isCheck = false;
        }
      },

      deep: true,
    },
  },

  validations() {
    if (!this.form.reference_form) {
      return {
        form: {
          email: {
            required,
            email,
          },
        },
      };
    }
    return {
      form: {
        email: {
          required,
          email,
        },

        address: {
          required,
        },
      },
    };
  },

  methods: {
    createHandler() {
      this.isCheck = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('create', this.form);
      }
    },
  },
};
</script>

  <style lang="scss">
  .reference {
    @extend %resume-list;
    justify-items: flex-start;
    padding-left: 5.3rem;
    padding-right: 5.3rem;
    width: 100%;
    max-width: 50rem;
    .resume-list__header {
      justify-self: center;
    }
    .help-cal__course {
      width: 4rem;
      justify-self: flex-start;
    }
    .help-cal__send-btn {
      width: 100%;
      margin: 3rem auto 0;
      background-color: $brand-clr-1;
      color: $basic-white;
      border-radius: 1rem;
      padding: 1.2rem 0;
      font-size: 1.4rem;
      font-family: "Gilroy-Bold";
    }
  }
  </style>
