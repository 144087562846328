<template>
  <aside class="aside">
    <div v-if="header" class="aside__header">
      <component :is="header"></component>
    </div>
    <div class="aside__body">
      <slot></slot>
      <component
        :is="component"
        v-for="(component, idx) of componentsList"
        :key="idx"
      />
    </div>
  </aside>
</template>

<script>
import notificationsTab from './notificationsTab';
import buttonTab from './buttonTab';
import userTab from './userTab';
import curatorTab from './curatorTab';
import navigationTab from './navigationTab';
import lessonTab from './lessonTab';
import completedTasksTab from './completedTasksTab';
import eventsTab from './eventsTab';
import supportTab from './supportTab';

export default {
  data() {
    return {
      vebinars: [],
      activeNames: ['1'],
    };
  },
  props: {
    componentsList: Array,
    header: String,
  },
  components: {
    buttonTab,
    userTab,
    curatorTab,
    lessonTab,
    completedTasksTab,
    notificationsTab,
    eventsTab,
    navigationTab,
    supportTab
  },
};
</script>

<style lang="scss">
.aside {
  position: sticky;
  background-color: $lighter-border-clr;
  border-radius: 0 3rem 0 0;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  align-items: flex-start;
  .aside__header {
    padding: 2.4rem 2.4rem 0 2.4rem;
  }
  .aside__body {
    padding: 2.4rem 2.4rem 2.4rem 2.4rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
    overflow: auto;
    align-items: flex-start;
    max-height: 100%;
  }
  .tab-wrp {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
  .text-title {
    margin-bottom: 1rem;
  }
  .list__title {
    color: $basic-white;
    margin: 0.5rem 5rem 0 1rem;
  }

  .body__content {
    font-family: "Roboto-Light";
    font-size: 1.4rem;
  }
}
@include breakPoints(md) {
  .aside {
    background-color: $basic-white;
  }
}
</style>
