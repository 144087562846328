<template>
  <div class="auth">
    <div v-if="!mobileMode" class="auth__info">
      <simplebar class="info__content" data-simplebar-auto-hide="false">
        <h3 class="content__title">Добро пожаловать на портал inStudy!</h3>
        <p class="content__description">
          Приготовьтесь отправиться
          <br />
          <br />
          в захватывающее путешествие
          <br />
          <br />
          за знаниями и ростом,
          <br />
          <br />
          не выходя из собственного дома.
        </p>

        <div class="contacts">
          <h4 class="contacts__title">Наши контакты:</h4>

          <div class="contacts__list">
            <a class="contacts__item" href="mailto:instudy@open-college.ru"
              >instudy@open-college.ru</a
            >
            <a class="contacts__item" href="tel:84996507707"
              >8 (499) 650-77-07</a
            >
            <a class="contacts__item" href="https://yandex.ru/maps/-/CDQyzI66"
              >ЧПОУ «Московский городской открытый колледж», <br />
              109316, г. Москва, <br />
              Волгоградский проспект, д. 32, корп. 5</a
            >
          </div>
        </div>
      </simplebar>
    </div>
    <div :class="['auth__form', { 'auth__form--single': !mobileMode }]">
      <waves v-if="mobileMode" />

      <div class="auth__form-wrp">
        <m-icon class="logo-is" name="logo-is-2.0" />

        <template v-if="!groupList.length">
          <template v-if="!isDemo">
            <m-input
              :valid="!$v.email.$error"
              v-model.trim="email"
              label="Логин"
              placeholder="Введите логин"
              :error-message="!$v.email.required ? 'Email не заполнен' : ''"
            />

            <m-input
              :valid="!$v.password.$error"
              iconPosition="right"
              icon="hidden-eye"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              label="Пароль"
              placeholder="Введите пароль"
              :error-message="!$v.password.required ? 'Пароль не заполнен' : ''"
            >
              <template #icon>
                <button @click="showPassword = !showPassword">
                  <m-icon
                    name="hidden-eye"
                    :class="{ 'hidden-eye--active': showPassword }"
                  />
                </button>
              </template>
            </m-input>
          </template>
          <button class="auth__btn btn-fill" @click="submit">
            <transition name="slide-fade-top">
              <span v-if="messageError" class="btn__text-error">{{
                messageError
              }}</span>
            </transition>
            Войти
          </button>
        </template>
        <template v-else>
          <ul class="form__group-list">
            <li
              @click="selectGroup(group)"
              class="btn-fill group-list__item"
              v-for="(group, idx) of groupList"
              :key="idx"
            >
              {{ group }}
            </li>
          </ul>
        </template>

        <a
          v-if="showTicket"
          class="form__tiket-link"
          target="_blank"
          :href="ticketUrl"
          >Квитанция на оплату обучения</a
        >
      </div>

      <button class="form__forgot-pass" @click="onShowModal">Забыли пароль?</button>
    </div>
  </div>
</template>

<script>
import waves from '@/components/ui/waves';
import simplebar from 'simplebar-vue';
import { required, minLength } from 'vuelidate/lib/validators';
import { mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      messageError: '',
      showPassword: false,
      email: '',
      password: '',
      groupList: [],
      ticketUrl: '',
      showTicket: false,
    };
  },

  validations: {
    email: {
      required,
      minLength: minLength(4),
    },
    password: {
      required,
      between: minLength(4),
    },
  },
  components: {
    simplebar,
    waves,
  },

  computed: {
    mobileMode() {
      return this.$store.state.mobileMode;
    },

    isDemo() {
      return this.$route.name === 'Demo';
    }
  },

  created() {
    if (this.isDemo) {
      this.email = 's003984';
      this.password = 'RKPFMyD'
    }
  },

  methods: {
    ...mapActions({
      prelogin: 'auth/prelogin',
      signIn: 'auth/signIn',
      signOut: 'auth/signOut',
    }),

    ...mapMutations(['setCurrentModalComponent']),

    onShowModal() {
      this.setCurrentModalComponent({
        name: 'PasswordRecoveryModal',
      });
    },

    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const response = await this.prelogin({
          login: this.email,
          password: this.password,
        });

        if (!response?.data?.status) {
          this.messageError = response.data.messages;
        } else {
          response?.data?.groupList?.length === 1
            ? this.selectGroup(response.data.groupList[0])
            : (this.groupList = response.data.groupList);
        }
      }
    },

    async selectGroup(group) {
      const {
        ban,
        status,
        messages,
        ticket_url,
      } = await this.signIn({
        login: this.email,
        group,
      });

      if (status && !ban) return this.$router.push('/');
      if (!status && ban) {
        this.messageError = messages;
        this.ticketUrl = ticket_url;
        this.showTicket = true;
      }
    },
  },
};
</script>
<style lang="scss">
.auth {
  padding: 2rem;
  background: linear-gradient(110.56deg, #e6dbff -1.99%, #b9dffd 101.19%);
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .simplebar-vertical {
    margin-top: 5%;
    margin-right: 0.7rem;
    height: 90%;
    border-radius: 0.6rem;
  }

  .waves-backgound {
    position: absolute;
    bottom: 0;
    width: 97.9rem;
    height: 83rem;
    z-index: -1;
  }

  .auth__info,
  .auth__form {
    background: linear-gradient(
      146.86deg,
      rgba(255, 255, 255, 0.7) -2.26%,
      rgba(255, 255, 255, 0.14) 102.8%
    );
    box-shadow: 0px 4px 4px rgba(117, 69, 201, 0.1);
    backdrop-filter: blur(10px);
    padding: 2rem;
    display: flex;
    border-radius: 2rem;
    height: 100%;
    max-width: 48.5rem;
    max-height: 58.9rem;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .auth__form {
    justify-content: flex-start;
    flex-direction: column;
  }

  .auth__info {
    border: 1px solid $basic-white;
    border-radius: 2rem 0 0 2rem;
    background: linear-gradient(
      145.2deg,
      #e6dbff -0.57%,
      #7545c9 30.58%,
      #c7defe 68.72%,
      #b9dffd 101.49%
    );
  }

  .info__content {
    width: 100%;
    height: 100%;
    max-width: 38rem;
    max-height: 48rem;
    display: grid;
    row-gap: 1.6rem;
    background: linear-gradient(
      146.86deg,
      rgba(255, 255, 255, 0.7) -2.26%,
      rgba(255, 255, 255, 0.14) 102.8%
    );
    border: 1px solid $basic-white;
    box-shadow: 0px 4px 4px rgba(117, 69, 201, 0.1);
    backdrop-filter: blur(1rem);
    border-radius: 10px;
    padding: 4rem 2rem;

    .simplebar-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  .content__title {
    text-transform: uppercase;
    color: #361969;
    font-family: "Gilroy-Bold";
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }

  .content__description {
    margin-bottom: 2rem;
    overflow-wrap: anywhere;
    font-family: "ProximaNova-Regular";
    font-size: 1.6rem;
    color: #361969;
    line-height: 1.7rem;
  }

  .content__learn-more-link {
    max-width: 20rem;
    background-color: $basic-white;
    color: $brand-clr-1;
  }

  .contacts {
    margin-top: auto;
  }

  .contacts__title {
    margin-bottom: 1.5rem;
    font-size: 1.6rem;
    color: #361969;
  }

  .contacts__list {
    display: grid;
    row-gap: 0.8rem;
  }

  .contacts__item {
    font-size: 1.4rem;
    color: #361969;
    transition-duration: 0.3s;
    transition-property: color;
    cursor: pointer;

    &:hover {
      color: $brand-clr-1;
    }
  }

  .pagination-buttons {
    .pagination-buttons__button {
      background-color: $basic-white;
    }
    .pagination-buttons__button--active {
      background-color: $brand-clr-1;
    }
  }

  .auth__form-wrp {
    position: relative;
    max-width: 38rem;
    width: 100%;
    align-items: center;
    display: grid;
    flex-wrap: wrap;
    row-gap: 2rem;
    justify-items: center;
    grid-template-rows: 3fr auto auto;
    height: 100%;
    max-height: 40rem;
  }

  .m-input {
    max-width: 100%;
    .m-input__input {
      color: $brand-clr-1;
      font-family: "Gilroy-Regular";
      font-weight: 400;
      background: linear-gradient(
        90.9deg,
        rgba(255, 255, 255, 0.7) 1.3%,
        rgba(242, 243, 245, 0.14) 101.96%
      );
      border: 0.1rem solid $basic-white;
      box-shadow: 0px 4px 4px rgba(117, 69, 201, 0.1);
    }
    .m-input__input::placeholder {
      color: $brand-clr-1;
      font-family: "Gilroy-Regular";
      font-weight: 400;
    }
    .m-input__label {
      color: $basic-black;
      font-family: "Gilroy-Bold";
    }
  }
  .m-icon--hidden-eye {
    transition-duration: 0.3s;
    transition-property: fill;
    width: 2rem;
    cursor: pointer;
    fill: $secondary-text-clr;
    &:hover {
      fill: $brand-clr-1;
    }
  }
  .m-input__icon--isRight {
    button {
      background-color: transparent;
    }
  }
  .hidden-eye--active {
    fill: $brand-clr-1;
  }
  .m-input:nth-child(3) {
    margin-bottom: 2.5rem;
  }

  .auth__form--single {
    border-radius: 0 2rem 2rem 0;
    background: $basic-white;
    .m-input__input {
      background: $extra-light-border;
    }
  }
  .form__group-list {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 2rem;
  }

  .form__tiket-link {
    position: absolute;
    bottom: -7rem;
    font-size: 1.6rem;
    color: $brand-clr-1;
    transition-duration: 0.3s;
    transition-property: color;

    &:hover {
      color: lighten($brand-clr-1, 10);
    }
  }

  .form__forgot-pass {
    margin-top: auto;
    background-color: transparent;
    font-size: 1.4rem;
    color: $brand-clr-1;
    transition-duration: 0.3s;
    transition-property: color;

    &:hover {
      color: lighten($brand-clr-1, 10);
    }
  }

  .group-list__item {
    width: 100%;
  }
  .auth__btn {
    width: 100%;
    height: 4rem;
    position: relative;
  }
  .btn__text-error {
    position: absolute;
    bottom: -2rem;
    width: 40rem;
    margin-left: -20rem;
    left: 50%;
    z-index: -1;
    font-size: 1.2rem;
    color: #ff6c46;
  }
  .logo-is {
    width: 10rem;
    fill: $brand-clr-1;
  }
}

.button-tab {
  width: 100%;
  background-color: $base-border-clr;
  height: 4rem;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: max-content auto;
  padding: 0.5rem 1.6rem;
  align-items: center;
  column-gap: 0.8rem;

  .m-icon--telegramm {
    width: 1.1rem;
    fill: $basic-white;
  }

  .m-icon--support-men {
    width: 1.7rem;
  }
}
@include breakPoints(md) {
  .auth {
    padding: 0;
    .auth__form {
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }
    .auth__form-wrp {
      max-height: 45rem;
    }
    .auth__btn {
      text-transform: uppercase;
    }
  }
}
</style>
