var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'documents__header',
    { 'documents__header--hide-btn': !_vm.changeViews },
  ]},[_c('div',{class:['header__input', { 'header__input--active': _vm.showInput }]},[_c('button',{staticClass:"header__input-btn",on:{"click":_vm.handlerShowInput}},[_c('m-icon',{attrs:{"name":"magnifier"}})],1),_c('m-input',{ref:"search",attrs:{"iconPosition":"right"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('button',{on:{"click":_vm.closeSearch}},[_c('m-icon',{attrs:{"name":"close"}})],1)]},proxy:true}]),model:{value:(_vm.value.search),callback:function ($$v) {_vm.$set(_vm.value, "search", $$v)},expression:"value.search"}})],1),(_vm.changeViews)?[_c('button',{class:[
        'header__display-btn',
        { 'header__display-btn--active': _vm.value.displayMode === 'list' },
      ],on:{"click":function($event){_vm.value.displayMode = 'list'}}},[_c('i',{staticClass:"fas fa-bars"})]),_c('button',{class:[
        'header__display-btn',
        { 'header__display-btn--active': _vm.value.displayMode === 'grid' },
      ],on:{"click":function($event){_vm.value.displayMode = 'grid'}}},[_c('i',{staticClass:"fab fa-buromobelexperte"})])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }