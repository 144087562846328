<template>
    <div class="payment page">
      <!-- <left-section /> -->
      <right-section />
    </div>
  </template>

<script>

export default {

  name: 'PaymentPage',

  components: {
    'left-section': () => import('@/views/Payment/leftSection'),
    'right-section': () => import('@/views/Payment/rightSection'),
  },

};
</script>

  <style lang="scss">
  .payment {
    display: grid;
    column-gap: 2.5rem;
    grid-template-columns: 1fr 1fr;
  }
  .tab-header {
    font-family: "Gilroy-Bold";
    font-size: 0.9rem;
    color: $basic-black;
  }
  </style>
