<template>
  <div class="sending-statements">
    <el-select
      popper-class="order__popper"
      v-model="statementType"
      placeholder="Укажите тип"
    >
      <el-option
        v-for="statementType in statementsTypes"
        :key="statementType.value"
        :label="statementType.label"
        :value="statementType.value"
      >
      </el-option>
    </el-select>

    <component
      :is="statementType"
      :data="statementsData"
      :status="statusSending"
      @create="onCreate"
    />
  </div>
</template>

<script>
import installmentPlan from '@/views/Deanery/statements/forms/installmentPlan';

export default {
  name: 'SendingStatements',

  components: {
    installmentPlan,
  },

  props: {
    statementsData: {
      type: Object,
      default: () => ({}),
    },

    statusSending: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      statementType: null,
    };
  },

  computed: {
    statementsTypes() {
      return this.statementsData?.statementType || [];
    },
  },

  methods: {
    getStatementType() {
      return this.statementsTypes.find((el) => el.value === this.statementType) || null;
    },

    onCreate($event) {
      const { label, key } = this.getStatementType();
      this.$emit('create', { statement_type: key, statement_name: label, ...$event });
    },
  },
};
</script>

<style lang="scss">
.sending-statements {
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 2.4rem;
  justify-items: center;

  .el-select {
    width: 100%;
    .el-input__inner {
      border-radius: 1rem;
      border-color: $basic-white;
      background: linear-gradient(90deg, #f7f5fa 0%, #e4deee 100%);
      box-shadow: 0px 4px 4px rgb(117 69 201 / 10%);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);

      &::placeholder {
        color: $brand-clr-1;
        font-size: 1.4rem;
        font-family: "ProximaNova-Regular";
      }
    }
    .el-select__caret {
      color: $brand-clr-1;
      font-weight: bold;
    }
    .el-input.is-focus .el-input__inner {
      border-color: $brand-clr-1;
    }
  }
  .order__popper {
    .el-select-dropdown__wrap {
      background: linear-gradient(90deg, #f7f5fa 0%, #e4deee 100%);
      box-shadow: 0px 4px 4px rgb(117 69 201 / 10%);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }
}
</style>
