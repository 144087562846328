import { render, staticRenderFns } from "./PasswordRecoveryModal.vue?vue&type=template&id=242d78b4"
import script from "./PasswordRecoveryModal.vue?vue&type=script&lang=js"
export * from "./PasswordRecoveryModal.vue?vue&type=script&lang=js"
import style0 from "./PasswordRecoveryModal.vue?vue&type=style&index=0&id=242d78b4&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports