<template>
  <div class="m-checkbox">
    <span @click="handleChange" class="m-checkbox__input">
      <span
        :class="[
          'm-checkbox__inner',
          {
            'm-checkbox__inner--checked': groupMode
              ? checkLabelFunction
              : value,
          },
        ]"
      ></span>
      <input class="m-checkbox__original" type="checkbox" />
    </span>
    <span class="m-checkbox__label"><slot></slot></span>
  </div>
</template>

<script>
export default {
  props: {
    groupMode: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    arrayValues: Array,
  },
  computed: {
    checkLabelFunction() {
      try {
        if (this.arrayValues.length) {
          return Boolean(this.arrayValues.find((el) => el === this.label));
        }
      } catch (e) {
        console.warn(e);
      }
    },
  },

  methods: {
    handleChange() {
      if (this.groupMode) {
        const { label } = this;
        this.$emit('change', label);
      } else {
        const value = !this.value;
        this.$emit('input', value);
      }
    },
  },
};
</script>

<style lang="scss">
.m-checkbox {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 0.8rem;
  align-items: center;
  .m-checkbox__input {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    vertical-align: middle;
  }
  .m-checkbox__inner {
    display: inline-block;
    position: relative;
    border: 1px solid $brand-clr-1;
    border-radius: 0.3rem;
    box-sizing: border-box;
    width: 14px;
    height: 14px;
    background-color: transparent;
    z-index: 1;
    transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
      background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
  }
  .m-checkbox__inner::after {
    box-sizing: content-box;
    content: "";
    border: 1px solid #fff;
    border-left: 0;
    border-top: 0;
    height: 7px;
    left: 4px;
    position: absolute;
    top: 1px;
    transform: rotate(45deg) scaleY(0);
    width: 3px;
    transition: transform 0.15s ease-in 0.05s;
    transform-origin: center;
  }
  .m-checkbox__inner--checked {
    background-color: $brand-clr-1;
    border-color: $brand-clr-1;
    &::after {
      transform: rotate(45deg) scaleY(1);
    }
  }
  .m-checkbox__original {
    opacity: 0;
    outline: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }
  .m-checkbox__label {
    font-size: 1.4rem;
    font-family: "Gilroy-Regular";
    color: $brand-clr-1;
  }
}
</style>
