<template>
  <component :class="['m-icon', 'm-icon--' + name]" :is="name"></component>
</template>

<script>
import email from './email';
import fullscreen from './fullscreen';
import copy from './copy';
import otherCalendar from './otherCalendar';
import yandexCalendar from './yandexCalendar';
import outlook from './outlook';
import googleCalendar from './googleCalendar';
import severalFiles from './severalFiles';
import star from './star';
import save from './save';
import magniferPlus from './magnifer_plus';
import edit from './edit';
import fileImgBackground from './fileImgBackground';
import logo_is from './logo_is_2.0';
import fileBackground from './fileBackground';
import supportMen from './supportMen';
import close from './close';
import magnifier from './magnifier';
import telegrammLogo from './telegrammLogo';
import setting from './setting';
import user from './user';
import message from './message';
import home from './home';
import calendar from './calendar';
import progressCircle from './progress-circle';
import files from './files';
import logout from './logout';
import handPay from './handPay';
import paperClip from './paperСlip';
import download from './download';
import file from './file';
import folder from './folder';
import play from './play';
import tabletList from './tabletList';
import questionBackground from './questionBackground';
import watch from './watch';
import lock from './lock';
import tree from './tree';
import fileWatch from './fileWatch';
import fileEdit from './fileEdit';
import arrow from './arrow';
import chevron from './chevron';
import hiddenEye from './hidden-eye';
import angle from './angle';
import fileNoBackground from './fileNoBackground';
import successIcon from './successIcon';
import warningIcon from './warningIcon';
import errorIcon from './errorIcon';
import infoIcon from './infoIcon';
import eyeSolid from './eyeSolid';
import upload from './upload';
import vkLogo from './vkLogo';
import rutubeLogo from './rutubeLogo';

export default {
  props: {
    name: {
      type: String,
      default: 'calendar',
    },
  },
  components: {
    outlook,
    calendar,
    fullscreen,
    email,
    'other-calendar': otherCalendar,
    'yandex-calendar': yandexCalendar,
    'several-files': severalFiles,
    'info-icon': infoIcon,
    'error-icon': errorIcon,
    'warning-icon': warningIcon,
    'success-icon': successIcon,
    'logo-is-2.0': logo_is,
    'file-background': fileBackground,
    'progress-circle': progressCircle,
    'paper-clip': paperClip,
    'support-men': supportMen,
    'file-img-background': fileImgBackground,
    'magnifer-plus': magniferPlus,
    'tablet-list': tabletList,
    'question-background': questionBackground,
    'file-watch': fileWatch,
    'file-edit': fileEdit,
    'hidden-eye': hiddenEye,
    'file-no-background': fileNoBackground,
    'eye-solid': eyeSolid,
    'google-calendar': googleCalendar,
    vkLogo,
    telegrammLogo,
    rutubeLogo,
    upload,
    watch,
    star,
    edit,
    home,
    message,
    user,
    setting,
    files,
    file,
    logout,
    handPay,
    magnifier,
    close,
    save,
    download,
    folder,
    play,
    lock,
    tree,
    arrow,
    chevron,
    angle,
    copy,
  },
};
</script>

<style lang="scss">
svg {
  width: 1.5rem;
}
</style>
