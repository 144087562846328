var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"educational-process"},[(!_vm.mobileMode)?_c('div',{staticClass:"education-process__content page"},[_c(_vm.currentComponent,{tag:"component"})],1):_vm._e(),_c('aside-bar',[_c('div',{staticClass:"educational-process__controls-list"},[(_vm.mobileMode)?_vm._l((_vm.filtredControlButtons),function(button,idx){return _c('m-collapse',{key:idx,staticClass:"m-collapse--controls-list",attrs:{"blockShowBody":button.href ? true : undefined},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header__content-wrp",on:{"click":function($event){return _vm.handleButtonClick(button)}}},[_c('span',{staticClass:"header__text"},[_vm._v(_vm._s(button.name))])])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"body__content"},[_c(button.component,{tag:"component",attrs:{"changeViews":false}})],1)]},proxy:true}],null,true)})}):_vm._l((_vm.filtredControlButtons),function(button){return _c('button',{key:button.id,class:[
            'controls-list__item',
            {
              'controls-list__item--active':
                _vm.currentComponent === button.component,
            },
          ],on:{"click":function($event){return _vm.handleButtonClick(button)}}},[_vm._v(" "+_vm._s(button.name)+" ")])})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }