<template>
  <div class="events-tab">
    <h3 class="text-title">{{ setTitle(selectedDay) }}</h3>
    <div class="events-tab__list">
      <template v-if="eventsOnSelectedDay().length">
        <event-tab
          v-for="(event, idx) of eventsOnSelectedDay()"
          :key="idx"
          :modalData="event"
        />
      </template>
      <template
        v-if="
          selectedDay !== null && mobileMode && !eventsOnSelectedDay().length
        "
      >
        <span class="text-empty-notify">Нет событий</span>
      </template>
    </div>
  </div>
</template>

<script>
import tabColors from '@/views/schedule/tabColors';
import { mapGetters } from 'vuex';
import eventTab from './eventTab.vue';

export default {
  data() {
    return {};
  },
  computed: {
    mobileMode() {
      return this.$store.state.mobileMode;
    },
    colorsForTab() {
      return tabColors;
    },
    selectedDay() {
      return this.$store.state.shedule.selectedDay;
    },
  },
  components: {
    'event-tab': eventTab,
  },
  methods: {
    ...mapGetters({
      eventsOnSelectedDay: 'shedule/eventsOnSelectedDay',
    }),
    setTitle(value) {
      if (value) {
        return `${value.format('dddd')} | ${+value.format('DD')} ${value.format(
          'MMMM',
        )}`;
      }
    },
  },
};
</script>
<style lang="scss">
.events-tab {
  .events-tab__list {
    display: grid;
    row-gap: 1.6rem;
  }
  .text-title::first-letter {
    text-transform: uppercase;
  }
  .text-empty-notify {
    justify-self: center;
    align-self: center;
    color: $basic-black;
  }
}
</style>
