<template>
  <div class="comments-modal">
    <div class="comments-modal__header">
      <span class="modal__header">Комментарий</span>
    </div>
    <textarea v-model="comment" class="comments-modal__body"></textarea>
    <button @click="sendComment()" class="comments__send-btn">
      <m-icon name="telegrammLogo" />
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      comment: '',
    };
  },
  props: {
    modalData: Object,
  },
  methods: {
    sendComment() {
      this.modalData.callBack(this.comment);
    },
  },
};
</script>

<style lang="scss">
//sd
.comments-modal {
  overflow: hidden;
  display: grid;
  grid-template-rows: 4rem 1fr 3rem;
  width: 30rem;
  height: 30rem;
  border-radius: 1rem;
  border-color: transparent;
  outline: none;
  background-color: white;
  .comments__send-btn {
    padding: 0 1rem;
    justify-self: flex-end;
    align-self: center;
    width: max-content;
    height: max-content;
    background-color: transparent;
  }
  .m-icon--telegramm {
    fill: $brand-clr-1;
  }
  .m-button {
    background-color: $basic-white !important;
    top: 1rem !important;
    right: 1rem !important;
    .m-icon--close {
      fill: $brand-clr-1 !important;
    }
  }
  .comments-modal__header {
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    background-color: $brand-clr-1;
  }
  .modal__header {
    color: $basic-white;
    font-family: "Gilroy-Bold";
  }
  .comments-modal__body {
    font-size: 1.4rem;
    width: 100%;
    height: 100%;
    border-color: transparent;
    outline: none;
    padding: 0.5rem;
    font-family: "Gilroy-Regular";
    color: $basic-black;
    resize: none;
  }
}
.comments-modal + .m-button {
  top: 1rem !important;
  right: 1rem !important;
  background-color: $basic-black !important;
}
</style>
