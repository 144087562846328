var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reference"},[_c('h3',{staticClass:"resume-list__header"},[_vm._v(_vm._s(_vm.referenceType.name))]),_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("Выдача справки")]),_c('el-radio-group',{model:{value:(_vm.form.reference_form),callback:function ($$v) {_vm.$set(_vm.form, "reference_form", $$v)},expression:"form.reference_form"}},[_c('el-radio',{attrs:{"label":0,"disabled":!_vm.referenceType.enable_email}},[_vm._v("На электронный адрес")]),_c('el-tooltip',{attrs:{"popper-class":"tooltip-popper","content":"Внимание! Студентам из Москвы и МО справки по почте не отправляются, справку необходимо получить лично.","placement":"top-start"}},[_c('el-radio',{attrs:{"label":1,"disabled":!_vm.referenceType.enable_post}},[_vm._v("Оригинал почтой")])],1),_c('el-radio',{attrs:{"label":2,"disabled":!_vm.referenceType.enable_personally}},[_vm._v("Получить лично")])],1),_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("Электронный адрес")]),_c('m-input',{attrs:{"placeholder":"Электронный адрес","valid":!_vm.$v.form.email.$error,"error-message":!_vm.$v.form.email.required
        ? 'Обязательное поле'
        : !_vm.$v.form.email.email
        ? 'Неверный формат почты'
        : ''},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.form.reference_form === 1)?[_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("Почтовый адрес получения справки")]),_c('p',{staticClass:"resume-list__input-desscription"},[_vm._v(" Пример: 109316, г. Москва, Волгоградский проспект, д. 32, корп. 5, (каб.101) ")]),_c('m-input',{attrs:{"placeholder":"Адрес","valid":!_vm.$v.form.address.$error,"error-message":!_vm.$v.form.address.required ? 'Обязательное поле' : ''},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})]:_vm._e(),_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("Наименование военного комиссариата муниципального образования (муниципальных образований)")]),_c('m-input',{attrs:{"placeholder":"Начните вводить","valid":!_vm.$v.form.commissariat.$error,"error-message":!_vm.$v.form.commissariat.required
        ? 'Обязательное поле'
        : ''},model:{value:(_vm.form.commissariat),callback:function ($$v) {_vm.$set(_vm.form, "commissariat", $$v)},expression:"form.commissariat"}}),_c('h4',{staticClass:"resume-list__sub-header"},[_vm._v("Количество справок")]),_c('el-input-number',{attrs:{"min":1,"max":5},model:{value:(_vm.form.reference_count),callback:function ($$v) {_vm.$set(_vm.form, "reference_count", $$v)},expression:"form.reference_count"}}),_c('button',{staticClass:"help-cal__send-btn",on:{"click":_vm.createHandler}},[_vm._v("Заказать")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }