<template>
  <div :class="classCompiller">
    <span class="m-input__label" v-if="label">{{ label }}</span>
    <div class="wrp-input">
      <transition name="slide-fade-top">
        <span
          v-if="!valid"
          class="m-input__invalid-message"
          >{{ errorMessage }}</span
        >
      </transition>
      <textarea
        v-if="type === 'textarea'"
        :cols="cols"
        :rows="rows"
        :value="value"
        :readonly="readonly"
        :class="[
          'm-input__input',
          { 'icon-input-isLeft': iconPosition === 'left' },
          {
            'icon-input-isRight':
              iconPosition === 'right' || iconPosition === '',
          },
        ]"
        :placeholder="placeholder"
        @input="inputFunc"
      >
      </textarea>
      <input
        v-else
        ref="input"
        :disabled="disabled"
        :readonly="readonly"
        v-imask="imask"
        :type="type"
        :value="value"
        :class="[
          'm-input__input',
          { 'icon-input-isLeft': iconPosition === 'left' },
          {
            'icon-input-isRight':
              iconPosition === 'right' || iconPosition === '',
          },
        ]"
        :placeholder="placeholder"
        @keydown.enter="$emit('enter', value)"
        @blur="$emit('blur', { $event, value })"
        @accept="onAccept"
        @input="onInput"
      />
      <div v-if="iconClass" :class="iconClass">
        <slot name="icon"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from 'vue-imask';

export default {
  name: 'm-input',
  data() {
    return {
      imask: this.mask ? { mask: this.mask } : '',
    };
  },

  directives: {
    imask: IMaskDirective,
  },

  props: {
    mask: {
      type: String,
      default: '',
    },

    valid: {
      type: Boolean,
      default: () => true,
    },

    errorMessage: {
      type: String,
      default: 'text',
    },

    type: {
      type: String,
      default: 'text',
    },

    cols: {
      type: Number,
      default: 20,
    },

    rows: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    dataType: String,

    icon: String,

    iconPosition: String,

    value: [String, Number],

    placeholder: String,

    label: String,

    labelPosition: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    max: {
      type: [Number, null],
      default: null,
    },
  },
  computed: {
    classCompiller() {
      return [
        'm-input',
        {
          'm-input--label-left': this.labelPosition === 'left',
          'm-input--label-right': this.labelPosition === 'right',
          'm-input--no-valid': !this.valid,
        },
      ];
    },

    iconClass() {
      let classIcon = '';
      if (this.iconPosition === 'right') {
        classIcon = 'm-input__icon--isRight';
      }
      if (this.iconPosition === 'left') {
        classIcon = 'm-input__icon--isLeft';
      }
      return classIcon;
    },
  },
  methods: {
    isEmpty(value) {
      return this.$helpers.helperFunctions.isEmpty(value);
    },

    focus() {
      this.$refs.input.focus();
    },

    onInput(event) {
      if (this.mask) return;

      this.$emit('input', event.target.value);
    },

    onAccept(e) {
      this.$emit('input', e.detail._value);
    },
  },
};
</script>

<style lang="scss">
@mixin m-input {
  align-items: center;
  max-width: 30rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  column-gap: 1rem;
  width: 100%;
  box-sizing: border-box;
  &:hover {
    border-color: $secondary-text-clr;
  }
  .wrp-input {
    position: relative;
  }
  .m-input__invalid-message {
    font-size: 1.2rem;
    position: absolute;
    bottom: -1.6rem;
    left: 0;
    color: $danger-clr;
  }
  .m-input__icon {
    position: absolute;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    margin-top: -1.5rem;
  }
  .m-input__icon--isRight {
    @extend .m-input__icon;
    right: 1rem;
  }
  .m-input__icon--isLeft {
    @extend .m-input__icon;
    left: 1rem;
  }
  .m-input {
    display: grid;
    grid-template-columns: 1fr;
  }

  .icon-input-isLeft {
    padding-left: 4rem;
  }
  .icon-input-isRight {
    padding-right: 4rem;
  }
}
.m-input {
  @include m-input;
}
.m-input--label-left {
  @include m-input;
  grid-template-columns: max-content 1fr;
}
.m-input--label-right {
  @include m-input;
  grid-template-columns: 1fr max-content;
}
.m-input__input {
  font-family: "Gilroy-Bold";
  font-size: 1.4rem;
  transition-duration: 0.3s;
  transition-property: border-color;
  border: 1px solid $placeholder-text-clr;
  padding: 1rem 1.6rem;
  border-radius: 0.5rem;
  width: 100%;
  outline: none;
  &:focus {
    border-color: $brand-clr-1;
  }
}

.m-input--no-valid {
    .m-input__input {
      border: 1px solid $danger-clr !important;
    }
  }
</style>
