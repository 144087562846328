<template>
  <div
    ref="container"
    class="container"
    :style="{ height: heightContainer }"
  >
    <transition-group
      class="notification__container"
      tag="div"
      name="slide-fade-right"
    >
      <notification
        @close="handlerCloseNotify"
        v-for="notify of notifyElements"
        :key="notify.id"
        :notyfyData="notify"
      />
    </transition-group>
    <transition
      :name="
        currentModalComponent.drawerMode ? 'slide-fade-left' : 'slide-fade-top'
      "
    >
      <modal
        :drawer="currentModalComponent.drawerMode"
        v-if="currentModalComponent.name"
      >
        <component
          :modalData="currentModalComponent.data"
          :is="currentModalComponent.name"
          @close="onCloseModal"
        ></component>
      </modal>
    </transition>
    <navigation v-if="showNav" />
    <main :class="['main', { 'main-collapse': !showNav }]">
      <transition name="fade">
        <preloader v-if="preloaderRun" />
      </transition>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import comentsModal from '@/components/modalContents/comentsModal';
import selectCourseworkModal from '@/components/modalContents/selectCourseworkModal';
import syncCalendarModal from '@/components/modalContents/syncCalendarModal/syncCalendarModal';
import preloader from '@/components/ui/preloader';
import contentReader from '@/components/modalContents/contentReader';
import notification from '@/components/ui/notification';
import eventTab from '@/components/asideBar/eventTab';
import specialization from '@/components/modalContents/specialization';
import portfolioImg from '@/components/modalContents/portfolioImg';
import workExperience from '@/components/modalContents/workExperience';
import PasswordRecoveryModal from '@/components/modalContents/PasswordRecoveryModal';
import modal from '@/components/ui/modal';
import navigation from '@/components/navigation';
import { mapMutations, mapActions } from 'vuex';

export default {
  name: 'app',
  data() {
    return {
      mobileWidth: 600,
      tabletWidth: 1300,
    };
  },
  components: {
    comentsModal,
    modal,
    navigation,
    workExperience,
    portfolioImg,
    specialization,
    eventTab,
    notification,
    contentReader,
    preloader,
    selectCourseworkModal,
    syncCalendarModal,
    PasswordRecoveryModal,
  },
  computed: {
    mobileMode() {
      return this.$store.state.mobileMode;
    },
    heightContainer() {
      if (this.mobileMode) {
        return `${window.innerHeight}px`;
      }
      return '100vh';
    },
    preloaderRun() {
      return this.$store.state.preloaderRun;
    },
    notifyElements() {
      return this.$store.state.notifyElements;
    },
    currentModalComponent() {
      return this.$store.state.currentModalComponent;
    },
    showNav() {
      return !this.$route.meta.fullScreen;
    },
    fullscreenMode() {
      return this.$route.meta.fullScreen;
    },
  },
  methods: {
    ...mapMutations(['setMobileMode', 'setTabletMode', 'shiftNotify', 'setCurrentModalComponent']),
    ...mapActions(['checkMode']),

    handlerCloseNotify(data) {
      this.shiftNotify(data);
    },

    resizeWindow() {
      window.addEventListener('resize', () => {
        this.checkMode(window.innerWidth);
      });
    },

    onCloseModal() {
      this.setCurrentModalComponent({ name: null, data: null });
    },
  },

  mounted() {
    this.resizeWindow();
    this.checkMode(window.innerWidth);
  },
};
</script>

<style lang="scss">
.container {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  @extend %main-gradient;
  padding-top: 2.4rem;
  padding-right: 2.4rem;
  grid-template-areas: "navigation main";
}
.navigation {
  grid-area: navigation;
}
.notification__container {
  display: grid;
  row-gap: 1rem;
  position: fixed;
  z-index: 35;
  right: 2.4rem;
  bottom: 1rem;
}
.content-wraper {
  padding: 4rem 4rem 0 0;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100%;
}
.wraper-fullscreen {
  grid-column-end: 3;
  padding: 0;
}
.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  grid-area: main;
}
.main-collapse {
  grid-column-start: 1;
  grid-column-end: 4;
  border-radius: 0;
}
.secret-input {
  position: absolute;
  width: 30rem;
  left: 50%;
  margin-left: -15rem;
}
.page {
  background-color: $basic-white;
  width: 100%;
  height: 100%;
  border-radius: 2rem 2rem 0 0;
  padding: 2rem 3.2rem;
}
// .fullscreen-btn {
//   position: absolute;
//   visibility: hidden;
// }
@include breakPoints(md) {
  .container {
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    padding: 0;
    grid-template-areas:
      "navigation"
      "main";
  }
}
</style>
