import axios from 'axios';

export default {
  namespaced: true,

  state: {
    curriculum: [],
    fetch: false,
    selectedSemester: null,
    recordBook: null,
  },

  getters: {},

  mutations: {
    setSelectedSemester(state, value) {
      state.selectedSemester = value;
    },
    setPlans(state, value) {
      state.curriculum = value;
      state.fetch = true;
    },
    setRecordBook(state, value) {
      state.recordBook = value;
    },
  },

  actions: {
    async getRecordBook({ commit, state }) {
      const response = await axios('api/record_book');
      const { data, status } = response;
      if (status === 200) {
        commit('setRecordBook', data);
      }
    },
    async getPlans({ commit, state }, semester) {
      return await axios.get(`api/plans/${semester}`).then((res) => {
        commit('setPlans', res.data.curriculum);
        return state.plans;
      });
    },
  },
};
