<template>
  <div :class="$style.HomeSessionProgress">
    <h3 :class="$style.text" v-html="daysLeft"></h3>
    <m-progress type="line" :percent="percent"/>
  </div>
</template>

<script>

export default {
  name: 'HomeSessionProgress',

  props: {
    sessionData: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    isEnd() {
      return this.sessionData.startSession - this.sessionData.currentDate < 0;
    },

    sessionIsBegin() {
      return this.sessionData?.session === 1;
    },

    percent() {
      const start = this.sessionData?.progress?.start * 1000 ?? null;
      const end = this.sessionData?.progress?.end * 1000 ?? null;
      const now = this.sessionData?.progress?.current * 1000 ?? null;

      if (start && end && now) {
        return ((now - start) * 100) / (end - start);
      }

      return 0;
    },

    daysLeft() {
      if (!this.sessionData.days) {
        return '';
      }

      const { plural } = this.$helpers.helperFunctions;
      const firstPart = this.sessionIsBegin ? 'Конец сессии' : 'Начало сессии';
      const secondPart = this.sessionIsBegin ? `| ${this.sessionData?.endSessionDate ?? ''}` : `| ${this.sessionData.startSessionDate}`;
      return `${firstPart} <span>| ${this.sessionData.days} ${plural(this.sessionData.days, ['день', 'дня', 'дней'])} ${secondPart}</span>`;
    },
  },
};
</script>

<style lang="scss" module>
    .HomeSessionProgress {
        display: grid;
    }

    .text {
        justify-self: center;
        margin-bottom: .5rem;
        font-size: 1.4rem;

        span {
            letter-spacing: .13rem;
        }
    }
</style>
