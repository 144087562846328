<template>
  <div :class="$style.PasswordRecoveryModal">
    <h2 :class="$style.title">Восстановление пароля</h2>

    <p :class="$style.prompt">*Только для студентов Московского городского открытого колледж</p>

    <m-input
      :valid="!$v.form.name.$error"
      v-model.trim="form.name"
      label="ФИО"
      placeholder="Введите фамилию имя отчество"
      :class="$style.input"
      :error-message="!$v.form.name.required ? 'Имя не заполнено' : ''"
    />

    <m-input
      :valid="!$v.form.email.$error"
      v-model.trim="form.email"
      label="Почта"
      placeholder="Введите email"
      :class="$style.input"
      :error-message="
        !$v.form.email.required
          ? 'Email не заполнен'
          : !$v.form.email.email
          ? 'Неверный формат почты'
          : ''
      "
    />

    <button
      :class="[$style.button, 'btn-fill', {'_isLoad': isLoad}]"
      @click="submit"
    >
      Восстановить
    </button>
  </div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators';
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'PasswordRecoveryModal',

  data() {
    return {
      form: {
        name: '',
        email: '',
      },

      isLoad: false,
    };
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },
      name: {
        required,
        between: minLength(4),
      },
    },
  },

  methods: {
    ...mapActions({
      passwordRecovery: 'auth/passwordRecovery',
    }),

    ...mapMutations(['pushNotify']),

    test() {
      this.$emit('close');
    },

    async submit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoad = true;
        const { status, messages } = await this.passwordRecovery(this.form);

        this.isLoad = false;

        this.pushNotify({
          content: messages,
          type: status ? 'success' : 'error',
          duration: 7000,
        });

        if (status) {
          this.$emit('close');
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.PasswordRecoveryModal {
  display: grid;
  row-gap: 2.4rem;
  width: 52rem;
  padding: 2.4rem 3.4rem;
  background-color: $basic-white;
  border-radius: 1rem;

  & + :global(.m-button) {
    top: 1.8rem !important;
    right: 1.8rem !important;
  }

  .input {
    :global(.m-input__input) {
      color: $brand-clr-1;
      font-family: "Gilroy-Regular";
      font-weight: 400;
      background: $extra-light-border;
      border: 0.1rem solid $basic-white;
      box-shadow: 0px 4px 4px rgba(117, 69, 201, 0.1);
    }

    :global(.m-input__input::placeholder) {
      color: $brand-clr-1;
      font-family: "Gilroy-Regular";
      font-weight: 400;
    }

    :global(.m-input__label) {
      color: $basic-black;
      font-family: "Gilroy-Bold";
    }
  }
}

.title {
  font-size: 2rem;
  color: $basic-black;
  text-align: center;
}

.button {
  margin-top: 2rem;
}

.prompt {
  margin-bottom: 2rem;
  font-size: 1.4rem;
  text-align: center;
}
</style>
