<template>
  <div :class="['modal-wrp', { 'drawer-wrp': drawer }]">
    <div class="modal-window">
      <slot></slot>
      <button
        class="m-button m-button--default button-no-border"
        @click="closeModal"
      >
        <m-icon name="close" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations(['setCurrentModalComponent']),
    closeModal() {
      this.$emit('close');
      this.setCurrentModalComponent({ name: null, data: null });
    },
  },
};
</script>

<style lang="scss">
.modal-wrp {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color: $basic-black, $alpha: 0.4);
  &::-webkit-scrollbar {
    width: 1rem;
  }
  .modal-content {
    // position: relative;
    // display: flex;
    // justify-content: center;
    // max-height: 100%;
    // height: 100%;
    // overflow: hidden;
  }
  .modal-window {
    position: relative;
    // display: grid;
    // grid-template-rows: max-content 1fr;
    // overflow: hidden;
    // border-radius: 2rem;
    //padding: 3rem;
    //overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%;
    max-height: 90%;
    //width: 100%;
    // height: 100%;
    // max-height: 80%;
    // background-color: transparent;
    // justify-items: center;
    // align-items: center;
  }
  .m-button {
    top: -2.5rem;
    right: -2.5rem;
    position: absolute;
    width: 2rem;
    height: 2rem;
    background-color: $brand-clr-1;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: lighten($color: $brand-clr-1, $amount: 10%);
    }
    .m-icon {
      width: 1rem;
      fill: $basic-white;
    }
  }
  .drawer-wrp {
    // justify-content: flex-start;
    // .modal-window {
    //   width: 30%;
    //   height: 100%;
    //   border-radius: 0;
    // }
  }
}
@include breakPoints(md) {
  .modal-wrp {
    .modal-window {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>
