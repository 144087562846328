<template>
  <div :class="classCompiller">
    <span v-if="label">{{ label }}</span>
    <input
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @focus="focus = true"
      @blur="focus = false"
      :class="['m-input__input']"
      type="text"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>
export default {
  name: 'm-select',
  data() {
    return {
      focus: false,
    };
  },
  props: {
    modelValue: String,
    placeholder: {
      type: String,
      default: 'Select',
    },
    label: String,
    labelPosition: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    classCompiller() {
      return `m-select${this.labelPosition === 'left' ? '--label-left' : ''}`;
    },
  },
};
</script>

<style scoped lang="scss">
@mixin m-input {
  align-items: center;
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
  column-gap: 10px;
  &:hover {
    border-color: $secondary-text-clr;
  }
  .m-select__input {
    transition-duration: 0.3s;
    transition-property: border-color;
    border: 1px solid $placeholder-text-clr;
    padding: 10px 5px;
    border-radius: 5px;
    width: 100%;
    outline: none;
    &:focus {
      border-color: $brand-clr-1;
    }
  }
  .m-select__arrow {
    transition-duration: 0.3s;
    transition-property: transform;
    color: $placeholder-text-clr;
  }
}
.m-select {
  @include m-input;
}
.m-input--label-left {
  @include m-input;
  grid-template-columns: max-content 1fr;
}
</style>
