<template>
  <div class="specialization-modal">
    <h3 class="specialization-modal__header">Выбор профобластей</h3>
    <m-input v-model="search" placeholder="Быстрый поиск" />
    <div class="specialization__list">
      <template v-if="professionalAreaFiltred(professionalArea).length">
        <m-collapse
          v-for="area of professionalAreaFiltred(professionalArea)"
          :key="area.id"
          :manualShow="Boolean(search)"
        >
          <template v-slot:header>
            <div class="header__text-wrp">
              <span class="header__text">{{ area.name }}</span>
            </div>
          </template>
          <template v-slot:body>
            <div class="body__content">
              <ul class="tree__sub-list">
                <li
                  class="tree__sub-item"
                  v-for="specialization of professionalAreaFiltred(
                    area.specializations
                  )"
                  :key="specialization.id"
                >
                  <m-checkbox
                    :groupMode="true"
                    :arrayValues="selectedProfessional"
                    @change="selectProfessional(specialization)"
                    :label="specialization.name"
                  >
                    {{ specialization.name }}
                  </m-checkbox>
                </li>
              </ul>
            </div>
          </template>
        </m-collapse>
      </template>
      <template v-else>
        <span class="specialization-modal__empty-list"
          >Совпадений не найдено</span
        >
      </template>
    </div>
    <div class="specialization-modal__footer">
      <button @click="closeModal" class="footer__btn footer__btn--cancel">
        Отменить
      </button>
      <button @click="saveData" class="footer__btn footer__btn--select">
        Выбрать
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  data() {
    return {
      selectedProfessional: [],
      search: '',
      searchRun: false,
      professionalArea: [],
    };
  },
  methods: {
    checkboxStatus(specialization) {
      return Boolean(
        this.selectedProfessional.find((el) => el === specialization),
      );
    },
    professionalAreaFiltred(array) {
      return array.filter((item) => {
        if (item.specializations) {
          const result = this.professionalAreaFiltred(item.specializations);
          return (
            item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
            || result.length
          );
        }
        return (
          item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      });
    },
    ...mapMutations(['setSpecialization']),
    selectProfessional(value) {
      const result = this.selectedProfessional.find((el) => el === value.name);
      if (result) {
        this.selectedProfessional = this.selectedProfessional.filter(
          (el) => el !== value.name,
        );
        return;
      }
      this.selectedProfessional.push(value.name);
    },
    closeModal() {
      this.$emit('close');
    },
    saveData() {
      this.$emit('save', this.selectedProfessional);
    },
  },
  beforeMount() {
    if (this.$store.state.resume.resumeStatus) {
      this.selectedProfessional = this.$store.state.resume.resume.specializationData.professional;
    }
    this.professionalArea = this.$store.state.resume.specializations;
  },
};
</script>

<style lang="scss">
.specialization-modal {
  display: grid;
  row-gap: 2.2rem;
  grid-template-rows: max-content max-content 1fr max-content;
  padding: 6.4rem 5.3rem;
  width: 48.5rem;
  height: 67.2rem;
  background: rgb(243, 242, 246);
  background: linear-gradient(
    90deg,
    rgba(243, 242, 246, 1) 0%,
    rgba(235, 232, 239, 1) 100%
  );
  box-shadow: 0px 4px 4px rgba(117, 69, 201, 0.1);
  border-radius: 1rem;
  .specialization__list {
    overflow: auto;
  }
  .specialization-modal__empty-list {
    font-size: 1.6rem;
    color: $basic-black;
    font-family: "ProximaNova-Regular";
  }
  .specialization-modal__header {
    font-size: 2rem;
    font-family: "Gilroy-Bold";
    color: $basic-black;
  }
  .m-checkbox {
    // display: flex;
    // flex-wrap: wrap;
  }
  .m-input {
    .m-input__input {
      background-color: transparent;
      border: 1px solid $basic-white;
      color: $basic-black;
      font-size: 1.4rem;
      font-family: "Gilroy-Regular";
      &::placeholder {
        color: $brand-clr-1;
        font-size: 1.4rem;
        font-family: "Gilroy-Regular";
      }
      &:focus {
        border-color: $brand-clr-1;
      }
    }
  }
  .m-collapse__header {
    grid-template-columns: max-content 1fr !important;
    background-color: transparent;
    padding: 1rem;
    column-gap: 1rem;
    font-size: 1.4rem;
    color: $brand-clr-1;
    font-family: "Gilroy-Regular";
    grid-template-areas: "icon text";
  }
  .header__text-wrp {
    grid-area: text;
    font-size: 1.4rem;
  }
  .header__text {
    font-family: "Gilroy-Regular";
    color: $brand-clr-1;
  }
  .header__icon {
    grid-area: icon;
    transform: rotate(-90deg);
  }
  .arrow-is-active {
    transform: rotate(0deg) !important;
  }
  .m-collapse__body {
    background-color: transparent !important;
  }
  .tree__sub-list {
    padding-left: 2.5rem;
    display: grid;
    row-gap: 0.8rem;
  }
  .tree__sub-item {
    align-items: center;
    padding: 0.2rem;
    border-radius: 0.5rem;
    display: flex;

    // display: grid;
    // grid-template-columns: max-content 1fr;
    // column-gap: 0.8rem;
    border: 1px solid transparent;
    span {
      font-size: 1.4rem;
      font-family: "Gilroy-Regular";
      color: $brand-clr-1;
    }
  }
  .tree__sub-item--searchable {
    border: 1px solid $brand-clr-1;
  }
  .specialization-modal__footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .footer__btn {
    font-size: 1.4rem;
    font-family: "Gilroy-Bold";
    padding: 1.2rem 2.5rem;
    border-radius: 1rem;
  }
  .footer__btn--cancel {
    justify-self: flex-start;
    border: 1px solid $brand-clr-1;
    color: $brand-clr-1;
  }
  .footer__btn--select {
    justify-self: flex-end;
    background-color: $brand-clr-1;
    color: $basic-white;
  }
}
</style>
