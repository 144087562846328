<template>
  <div class="messages__chat-list" id="chat-list">
    <div class="chat-list__main">
      <button
        v-if="modileMode"
        :class="['chat-list__btn', { 'chat-list__btn--show': showSearchValue }]"
        @click="toggleShowSearch"
      >
        <m-icon name="magnifier" />
      </button>
      <m-input
        v-if="showSearch"
        v-model="search"
        ref="searchInput"
        iconPosition="left"
        placeholder="Поиск"
      >
        <template v-slot:icon>
          <m-icon :name="'magnifier'" />
        </template>
      </m-input>
      <curatorTab
        v-if="messageData.curatorWithMsgCount"
        v-model="selectedChat"
        @select="selectChat"
        :dataTab="messageData.curatorWithMsgCount"
      />
      <teacherTab
        v-if="messageData.teacherListWithMsgCount.length"
        @select="selectChat"
        :dataTab="messageData"
        v-model="selectedChat"
      />
      <classmatesTab
        :dataClassmates="filtredClassmates()"
        @select="selectChat"
        v-model="selectedChat"
      />
    </div>
    <div class="chat-list__footer">
      <!--<a href="https://t.me/mgosha_chatbot" target="blank" class="footer-item">
        <div class="icon-wrp">
          <m-icon name="telegramm" />
        </div>
        <span class="footer-item__text">Чат-бот Мгоша</span>
      </a>
      <div class="footer-item">
        <m-icon name="support-men" />
        <span class="footer-item__text">Техническая поддержка</span>
      </div>!-->
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import classmatesTab from './classmatesTab.vue';
import teacherTab from './teacherTab.vue';
import curatorTab from './curatorTab.vue';

export default {
  data() {
    return {
      search: '',
      dataClassmates: {},
      chatData: [],
      showSearchValue: false,
    };
  },
  components: {
    curatorTab,
    teacherTab,
    classmatesTab,
  },
  computed: {
    showSearch() {
      if (this.modileMode) {
        return this.showSearchValue;
      }
      return true;
    },
    modileMode() {
      return this.$store.state.mobileMode;
    },
    selectedChat() {
      return this.$store.state.messages.selectedChat;
    },
    messageData() {
      return this.$store.state.messages.messageData;
    },
    notifyElements() {
      return this.$store.state.notifyElements;
    },
  },
  methods: {
    ...mapMutations({
      setSelectedChat: 'messages/setSelectedChat',
      setNotifyData: 'setNotifyData',
      pushNotify: 'pushNotify',
      shiftNotify: 'shiftNotify',
    }),

    toggleShowSearch() {
      this.search = '';
      this.showSearchValue = !this.showSearchValue;
      if (this.showSearchValue) {
        setTimeout(() => {
          this.$refs.searchInput.focus();
        }, 100);
      }
    },
    filtredClassmates() {
      return this.messageData.classmatesWithMsgCount.filter(
        (item) => item.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1,
      );
    },
    getLastMessenger(id, array) {
      return array.find((el) => el.idUser === id);
    },
    selectChat(data) {
      if (this.selectedChat === data) return this.setSelectedChat(null);
      this.setSelectedChat(data);
    },
  },
};
</script>

<style lang="scss">
.messages__chat-list {
  height: calc(100% - 2rem);
  overflow-y: auto;
  display: grid;
  grid-template-rows: 1fr max-content;
  align-items: flex-start;
  grid-auto-rows: max-content;
  row-gap: 1.5rem;
  background-color: #f8f9fa;
  padding: 2rem;

  .chat-list__main {
    display: grid;
    row-gap: 1.5rem;
  }

  .m-input {
    margin-bottom: 1rem;
    max-width: 100%;
    .m-input__input {
      color: $basic-black;
      background-color: $lighter-border-clr;
      padding: 1rem 1.6rem;
      &:focus + .m-input__icon--isLeft {
        opacity: 0;
        transition: opacity 0.3s ease;
      }
      &::placeholder {
        color: $placeholder-text-clr;
        padding-left: 4rem;
        font-size: 1.4rem;
      }
    }
    .m-input__icon--isLeft {
      left: 1.7rem;
    }
    .m-icon {
      fill: $placeholder-text-clr;
      width: 1.7rem;
    }
  }

  .m-collapse {
    border-radius: 1rem;
    transition-duration: 0.3s;
  }

  .m-collapse__body {
    padding-bottom: 0 !important;
    overflow: hidden;
    background-color: transparent !important;
  }

  .m-collapse__header {
    background-color: $brand-clr-1;
    height: 4rem;
    display: grid;
    padding: 0 1.6rem !important;
    grid-template-columns: 1fr 2.5rem !important;
  }

  .m-collapse__header-icon {
    color: $basic-white;
  }

  .chat-list__btn {
    background-color: transparent;
    border-radius: 1rem;
    width: 4rem;
    height: 4rem;
    position: fixed;
    top: 2.5rem;
    right: 2rem;
    .m-icon {
      fill: $brand-clr-1;
    }
  }

  .chat-list__btn--show {
    -webkit-box-shadow: 0px 4px 15px 2px rgba(117, 69, 201, 0.2);
    -moz-box-shadow: 0px 4px 15px 2px rgba(117, 69, 201, 0.2);
    box-shadow: 0px 4px 15px 2px rgba(117, 69, 201, 0.2);
  }

  .header__content-wrp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .header__text {
    color: white;
    grid-row-start: 1;
    font-family: "Gilroy-Bold";
    margin-right: 1rem;
  }

  .header__count {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
    color: white;
    background-color: $basic-black;
    height: 1.4rem;
    border-radius: 0.7rem;
    min-width: 1.4rem;
    font-size: 0.9rem;
    font-family: "Gilroy-Bold";
  }

  .body__content {
    padding-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    row-gap: 0.8rem;
  }

  .avatar-wrp {
    align-self: center;
    grid-area: avatar;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
    }
  }

  .user-tab-item {
    cursor: pointer;
    background-color: $basic-white;
  }

  .user-tab-item--userInfo {
    border-color: $brand-clr-1;
  }

  .user-tab-item__name {
    grid-area: name;
    color: $basic-black;
    font-size: 1.4rem;
    font-family: "Gilroy-Bold";
  }

  .user-tab-item__unrd-msg {
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: $brand-clr-1;
    grid-area: unrd;
  }

  .chat-list__footer {
    display: grid;
    row-gap: 1.6rem;
  }
  .footer-item {
    background-color: $base-border-clr;
    height: 4rem;
    border-radius: 1rem;
    display: grid;
    grid-template-columns: max-content auto;
    padding: 0.5rem 1.6rem;
    align-items: center;
    column-gap: 0.8rem;
    .icon-wrp {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
      padding-top: 10%;
      padding-right: 10%;
      border-radius: 50%;
      background-color: #039be5;
    }
    .m-icon--telegramm {
      width: 1.1rem;
      fill: $basic-white;
    }
    .footer-item__text {
      font-family: "Gilroy-Bold";
      font-size: 1.4rem;
      color: $basic-black;
    }
    .m-icon--support-men {
      width: 1.7rem;
    }
  }
}

@include breakPoints(md) {
  .messages__chat-list {
    height: 100%;
  }
}
</style>
