<template>
  <div class="vkr-module">
    <h3 class="vkr-module__title">{{ vkr.topicVKR }}</h3>

    <el-select
      popper-class="documents__popper"
      v-model="vkrType"
      placeholder="Выберите тип файла"
      class="vkr-module__select"
    >
      <el-option
        v-for="option in options"
        :key="option.value"
        :label="option.label"
        :value="option.value"
      />
    </el-select>

    <div class="vkr-module__upload-wrp">
      <el-upload
        v-if="vkrType"
        class="vkr-module__upload"
        action="#"
        :auto-upload="false"
        :on-change="handleChange"
        :limit="1"
        :on-exceed="handleExceed"
        :file-list="fileList"
      >
        <div class="upload__buttons">
          <el-button class="upload__button" type="primary"
            >Прикрепить файл</el-button
          >
          <transition name="fade">
            <el-button
              v-if="fileList.length"
              class="upload__button upload__button--upload"
              type="primary"
              @click.stop="sendFiles"
            >
              <m-icon name="upload" />
            </el-button>
          </transition>
        </div>
        <div class="upload__file-list" slot="file" slot-scope="{ file }">
          <span class="upload__file-item">
            <button class="upload__delete-btn" @click="handleRemove(file)">
              <m-icon name="close" />
            </button>
            <m-icon name="file" />
            <span>
              {{ file.name }}
            </span>
          </span>
        </div>
        <div slot="tip" class="el-upload__tip">
          Размер файла не должен превышать 500kb
        </div>
      </el-upload>
      <transition name="fade">
        <m-input
          v-if="fileList.length"
          class="upload__comment"
          placeholder="Комментраий"
          v-model="fileComment"
          type="textarea"
        />
      </transition>
    </div>

    <div class="vkr-module__table">
      <div class="row row--headar">
        <p class="col col--justify-left">Дата</p>
        <p class="col">Проверена</p>
        <p class="col col--justify-right">Подробности</p>
      </div>

      <div v-for="(vkrItem, idx) in vkrItems" :key="idx" class="table-row">
        <div class="col">
          <span class="col__header">{{ vkrItem.label }}</span>
        </div>
        <m-collapse
          class="m-collapse"
          v-for="(item, index) of vkrItem.data"
          :key="index"
        >
          <template v-slot:header>
            <div class="header__content-wrp">
              <span> {{ item.date }}</span>
              <m-icon
                v-if="item.check"
                name="success-icon"
                :class="{ 'm-icon--success-icon--warning': !item.done }"
              />
            </div>
          </template>
          <template v-slot:body>
            <div class="body__content">
              <div class="row">
                <div class="col">Тип</div>
                <div class="col">ВКР</div>
              </div>
              <div class="row">
                <div class="col">Дата отправки</div>
                <div class="col">{{ item.date }}</div>
              </div>
              <div class="row">
                <div class="col">Дата проверки</div>
                <div class="col">{{ item.check_date }}</div>
              </div>
              <div
                v-if="item.student_comment || item.student_comment !== 'null'"
                class="row"
              >
                <div class="col">Комментарий</div>
                <div class="col">{{ item.student_comment }}</div>
              </div>
              <div class="row">
                <div class="col">Комментарий преподавателя</div>
                <div class="col">{{ item.teacher_comment }}</div>
              </div>
              <div v-if="item.path" class="row">
                <div class="col">Скачать файл</div>
                <div class="col">
                  <a :href="item.path">
                    <m-icon name="download" />
                  </a>
                </div>
              </div>
            </div>
          </template>
        </m-collapse>
      </div>
    </div>
  </div>
</template>

<script>
const vkrGroups = [
  {
    label: 'Заявление на выбор темы',
    value: 'app',
  },
  {
    label: 'План работы',
    value: 'plan',
  },
  {
    label: 'Рецензия',
    value: 'review',
  },
  {
    label: 'Работа ВКР',
    value: 'work',
  },
];

export default {
  name: 'VkrModule',

  props: {
    vkr: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      fileList: [],
      vkrType: '',
      fileComment: '',
      options: [{ lable: '', value: '' }, ...vkrGroups],
    };
  },

  computed: {
    vkrItems() {
      return vkrGroups.map((group) => ({
        ...group,
        data: this.vkr[group.value],
      }));
    },
  },

  methods: {

    handleChange(file) {
      this.fileList.push(file);
    },

    async sendFiles() {
      const formData = new FormData();
      const file = this.fileList[0].raw;
      formData.append('type', this.vkrType);
      formData.append('file', file);
      formData.append('comment', this.fileComment);

      try {
        const { data } = await this.axios.post('/api/save-vkr-file', formData);

        if (!data.errors) {
          this.$message.success('Файлы успкшно отправлены');
          this.fileList = [];
          this.vkrType = '';
        } else {
          this.$message.error('Ошибка при отправке файлов');
        }
      } catch (e) {
        console.warn('[Failed fetch VkrModule/sendFiles]', e);
      }
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((f) => f.name !== file.name);
    },

    handleExceed() {
      this.$message.warning(
        'Лимит превышен максимальное количество файлов - 1',
      );
    },
  },
};
</script>

<style lang="scss">
.vkr-module {
  grid-template-areas:
    "header header"
    "select upload"
    "table table";
  border-radius: 1rem;
  padding: 1.6rem;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  &__title {
    grid-area: header;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
    color: $basic-black;
  }

  &__upload-wrp {
    position: relative;
  }

  &__select {
    grid-area: select;
    justify-self: flex-start;
  }

  &__upload {
    grid-area: upload;
  }

  .upload__button {
    height: 4rem;
    background-color: $brand-clr-1;
    border: 0.1rem solid $brand-clr-1;
    &:focus {
      background-color: $brand-clr-1;
      border: 0.1rem solid $brand-clr-1;
    }
    &:hover,
    &:active {
      background-color: lighten($brand-clr-1, 10);
      border: 0.1rem solid lighten($brand-clr-1, 10);
    }

    &--upload {
      background-color: $success-clr;
      border: 0.1rem solid $success-clr;

      &:hover,
      &:active {
        background-color: lighten($color: $success-clr, $amount: 10);
        border: 0.1rem solid lighten($color: $success-clr, $amount: 10);
      }
    }

    .m-icon {
      width: 1.5rem;
      fill: $basic-white;
    }

    span {
      color: white;
    }
  }

  &__table {
    grid-area: table;
    display: grid;
    row-gap: 1rem;
    position: relative;
    overflow-y: auto;

    .row {
      display: grid;
      background-color: #fff;
      column-gap: 1.6rem;
      top: 0;
      left: 0;
      position: sticky;

      &--headar {
        display: grid;
        grid-template-columns: 32% 27% 36%;
      }
    }

    .col {
      font-size: 1.4rem;
      font-family: Roboto-Regular;
      justify-self: center;

      &__header {
        color: $basic-black;
        font-family: "Gilroy-Bold";
      }

      &--justify-left {
        justify-self: flex-start;
      }

      &--justify-right {
        justify-self: flex-end;
      }
    }
  }

  .m-collapse__header {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: 64% 36%;

    &-icon {
      justify-self: flex-end;
    }
  }

  .header__content-wrp {
    display: grid;
    grid-template-columns: 73% 27%;
    align-items: center;
  }

  .m-icon--success-icon {
    fill: $success-clr;

    &--warning {
      fill: $warning-clr;
    }
  }

  .body__content {
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: 1fr;
    grid-template-areas: unset;

    .row {
      justify-items: flex-start;
      padding: 0.8rem 0;
      grid-template-columns: 33% 67%;
    }
    .col {
      justify-self: flex-start;
    }

    .m-icon--download {
      fill: $brand-clr-1;
    }
  }

  .upload__buttons {
    display: flex;
  }

  .upload__delete-btn {
    width: 1.5rem;
    background-color: transparent;
    cursor: pointer;
  }

  .upload__file-item {
    display: grid;
    grid-template-columns: auto auto 1fr;
    align-items: center;
    column-gap: 1rem;

    .m-icon {
      width: 1rem;
      height: 1rem;
      fill: $info-clr;
    }
  }

  .upload__comment {
    margin-top: 2rem;
    position: absolute;
    z-index: 20;
    width: 100%;
    bottom: 0;
    transform: translateY(calc(100% + 1rem));
    color: $brand-clr-2;

    .m-input__input {
      color: $brand-clr-2;

      &::placeholder {
        color: $brand-clr-2;
      }
    }
  }
}
</style>
