<template lang="pug">
  .shedule-calendar
    .shedule-calendar__header
      .shedule-calendar__controller
        button.controller__btn(@click="setPrevMonth")
          m-icon(name="chevron")
        p.controller__date
          b {{ date.toLocaleString("ru-RU", { month: "long" }) }}, {{`${firstDayOfTheWeek.format("DD")} - ${lastDayOfTheWeek.format("DD")}`}}
          span | Пн - Вс
        button.controller__btn(@click="setNextMonth")
          m-icon.m-icon--rotate(name="chevron")

      .shedule-calendar__filters
        el-select(
          :value="currentTeacher"
          placeholder="Преподаватель"
          filterable
          remote
          :load="isLoad"
          :remote-method="event => $emit('search', { type: 'teacher', value: event })"
          clearable
          @blur="$emit('search', { type: 'teacher', value: '' })"
          @change="handleChangeTeacher"
        )
          el-option(
            v-for="teacher in teachers"
            :key="teacher.value"
            :label="teacher.name"
            :value="teacher.id"
          )

        el-select(
          :value="currentGroup"
          placeholder="Номер группы"
          filterable
          remote
          :load="isLoad"
          :remote-method="event => $emit('search', { type: 'group', value: event })"
          @blur="$emit('search', { type: 'group', value: '' })"
          @change="handleChangeGroup"
        )
          el-option(
            v-for="group in groups"
            :key="group.value"
            :label="group.name"
            :value="group.id"
          )

      button.btn-fill.shedule-calendar__header-btn(@click="showSincModal")
        m-icon(name="calendar")
        |Синхронизировать календарь

    .shedule-calendar__body(ref="calendar-body")
      .body__column(
        :class="{ 'body__column--is-now': setSelectorColumnNow(day), 'body__column--is-events': filteringByStatus(day).length, 'body__column--is-selected': setSelectorColumn(day) }"
        v-for="(day, idx) of days"
        :key="idx"
        @click="selectDay(day)"
      )
        .column__header
          .header__day {{ +day.format("DD") }}
          .header__weak-name {{ weekNames[idx] }}

        .column__body(v-if="!mobileMode")
          .event-tab(
            v-for="(event, idx) of filteringByStatus(day)"
            :key="idx"
            @click.stop="handleTabCkick(event)"
          )
            span.event-tab__date {{ event.time_begin }} - {{ event.time_end }}
            b.event-tab__title {{ event.discipline.toUpperCase() }}
</template>

<script>
import { mapMutations } from 'vuex';
import tabColors from '@/views/schedule/tabColors';

export default {
  props: {
    events: Array,
    selectedDay: [Object, null],
    isLoad: {
      type: Boolean,
      default: false
    },
    teachers: {
      type: Array,
      default: () => ([])
    },
    groups: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      currentTeacher: null,
      currentGroup: null,
      checkboxes: {
        change: false,
        all: true,
        checkList: {
          educational: false,
          session: false,
          college: false,
          yourown: false,
        },
        statusOptions: ['учебные', 'сессия', 'колледж', 'свои'],
      },

      date: new Date(),
      weekNames: ['пон', 'вт', 'ср', 'чт', 'пят', 'суб', 'вс'],
    };
  },
  watch: {
    days() {
      this.handleChange();
    },
  },
  computed: {
    dateStart() {
      return this.$moment(this.date).locale('ru').startOf('week').format('YYYY-MM-DD')
    },
    dateEnd() {
      return this.$moment(this.date).locale('ru').endOf('week').format('YYYY-MM-DD')
    },
    mobileMode() {
      return this.$store.state.mobileMode;
    },
    days() {
      return [0, 1, 2, 3, 4, 5, 6].map((i) => this.$moment(this.firstDayOfTheWeek).add(i, 'days').locale('ru'));
    },
    colorsForTab() {
      return tabColors;
    },
    currentDate() {
      return this.$moment();
    },
    firstDayOfTheWeek() {
      return this.$moment(this.date).locale('ru').startOf('week');
    },
    lastDayOfTheWeek() {
      return this.$moment(this.date).locale('ru').endOf('week');
    },
  },
  methods: {
    ...mapMutations({
      modalRun: 'setCurrentModalComponent',
      setCurrentModalComponent: 'setCurrentModalComponent',
    }),

    handleChangeTeacher(value) {
      this.currentTeacher = value;
      this.currentGroup = null
      this.handleChange();
      this.$emit('search', { type: 'teacher', value: '' });
    },

    handleChangeGroup(value) {
      this.currentGroup = value;
      this.currentTeacher = null
      this.handleChange();
      this.$emit('search', { type: 'group', value: '' });
    },

    handleChange() {
      this.$emit('change', {
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        teacherId: this.currentTeacher,
        groupId:  this.currentGroup
      });
    },

    setSelectorColumnNow(day) {
      return day.format('YYYY-MM-DD') === this.currentDate.format('YYYY-MM-DD');
    },

    setSelectorColumn(day) {
      return (
        this.selectedDay !== null
        && this.selectedDay.format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
      );
    },

    setPrevMonth() {
      const nextDate = this.date.setDate(this.date.getDate() - 7);
      this.date = new Date(nextDate);
    },

    setNextMonth() {
      const nextDate = this.date.setDate(this.date.getDate() + 7);
      this.date = new Date(nextDate);
    },

    filterinByDay(day) {
      if (!this.events) return [];
      return this.events.filter((event) => event.date === day.format('YYYY-MM-DD'));
    },

    filteringByStatus(day) {
      const filtredData = this.filterinByDay(day);
      if (this.checkboxes.all) return filtredData;
      const result = {};
      const target = this.checkboxes.checkList;
      for (const key in target) {
        if (target[key]) result[key] = target[key];
      }
      return filtredData.filter((el) => Boolean(result[el.status]));
    },

    handlerCheckboxChange() {
      const target = this.checkboxes.checkList;
      let moreThanZero = false;
      const values = [];
      for (const key in target) {
        if (target[key]) {
          moreThanZero = true;
        }
        values.push(target[key]);
      }
      const allListCheck = values.every((el) => el === true);
      if (moreThanZero) this.checkboxes.all = false;
      if (allListCheck) {
        this.throwAll();
        this.checkboxes.all = true;
      }
    },

    throwAll() {
      const target = this.checkboxes.checkList;
      for (const key in target) {
        target[key] = false;
      }
    },

    selectDay(day) {
      this.$emit('selectDay', day);
    },

    handleTabCkick(day) {
      this.modalRun({
        name: 'eventTab',
        data: day,
      });
    },

    showSincModal() {
      this.setCurrentModalComponent({
        name: 'syncCalendarModal',
        data: {},
      });
    },
  },
};
</script>

<style lang="scss">
.shedule-calendar {
  width: 100%;
  height: 100%;
  display: grid;
  row-gap: 1.6rem;
  grid-template-rows: auto 1fr;

  .shedule-calendar__filters {
    display: flex;
    gap: 16px;
    width: 100%;

    @include breakPoints(lg) {

      .el-select {
        width: 100%;
      }
    }

    @include breakPoints(sm) {
      flex-wrap: wrap;
    }
  }

  .shedule-calendar__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @include breakPoints(lg) {
      flex-wrap: wrap;
    }
  }

  .shedule-calendar__header-btn {
    display: flex;
    padding: .5rem 1rem;

    @include breakPoints(lg) {
      width: 100%;
    }

    .m-icon {
      width: 1.5rem;
      margin-right: 1rem;
      fill: $basic-white;
    }
  }

  .home__calendar-btn {
    display: flex;
    border-radius: 1rem 1rem 0 0;

    .m-icon {
      width: 1.5rem;
      margin-right: 1rem;
      fill: $basic-white;
    }
  }

  .shedule-calendar__controller {
    justify-items: center;
    align-items: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    background-color: $brand-clr-5;
    border-radius: 1rem;
    max-width: 27.6rem;
    padding: 1.3rem;
    flex-shrink: 0;
    @include breakPoints(lg) {
      width: 100%;
      max-width: unset;
    }
  }

  .controller__date {
    color: $basic-black;
    font-size: 1.6rem;
    b {
      color: inherit;
      font-family: "Gilroy-Bold";
    }
    span {
      font-family: "Gilroy-Regular";
      color: inherit;
    }
  }

  .m-icon--chevron {
    width: 0.7rem;
    fill: $brand-clr-1;
  }

  .m-icon--rotate {
    transform: rotate(180deg);
  }

  .shedule-calendar__weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .shedule-calendar__body {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  .body__column {
    transition-duration: 0.3s;
    transition-property: background-color;
    border-radius: 1rem;
    display: grid;
    grid-template-rows: 7rem 1fr;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &:hover {
      background-color: $extra-light-border;
    }
  }

  .body__column--is-selected {
    background-color: $extra-light-border;
  }

  .body__column--is-now {
    background-color: $brand-clr-5;
    &:hover {
      background-color: darken($color: $brand-clr-5, $amount: 5);
    }
  }

  .body__column:nth-child(7) {
    .column__body {
      border-right: none;
    }
  }

  .column__header {
    grid-template-areas:
      "number"
      "day";
    align-self: center;
    display: grid;
    align-items: center;
    justify-items: center;
    height: max-content;
  }

  .header__day {
    grid-area: number;
    font-size: 1.8rem;
    font-family: "Gilroy-Bold";
    color: $basic-black;
  }

  .header__weak-name {
    grid-area: day;
    font-family: "ProximaNova-Regular";
    color: $basic-black;
  }

  .column__body {
    align-items: flex-start;
    padding: 0 0.3rem;
    border-left: 1px solid transparent;
    border-right: 1px solid $lighter-border-clr;
    row-gap: 0.8rem;
    display: flex;
    flex-direction: column;
  }

  .event-tab {
    row-gap: 0.5rem;
    width: 100%;
    display: grid;
    grid-template-rows: max-content max-content;
    padding: 0.8rem 0.4rem;
    cursor: pointer;
    border-radius: 1rem;
    background-color: #4b5aca;
    transition-property: transform;
    transition-duration: 0.3s;
    color: white;
    height: max-content;
    &:hover {
      transform: scale(1.1);
    }
  }

  .event-tab__title,
  .event-tab__date {
    color: inherit;
    font-size: 1rem;
    font-family: "Gilroy-Regular";
  }

  .el-checkbox-group {
    display: grid;
    column-gap: 1.5rem;
    grid-template-columns: repeat(5, 1fr);
    justify-items: center;
    .el-checkbox {
      margin-right: 0;
    }
  }

  .el-checkbox {
    .el-checkbox__label {
      color: $basic-black;
    }
  }

  .el-checkbox__inner {
    width: 1.6rem;
    height: 1.6rem;
    border-width: 0.2rem;
    border-radius: 50%;
  }

  .el-checkbox--is-all {
    .el-checkbox__input .el-checkbox__inner {
      border-color: $basic-black;
    }
    .is-checked {
      .el-checkbox__inner {
        background-color: $basic-black;
      }
    }
    .is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: $basic-black;
    }
  }

  .el-checkbox--educational {
    .el-checkbox__inner {
      border-color: #379e8d;
    }
    .is-checked {
      .el-checkbox__inner {
        border-color: #379e8d;
        background: #379e8d;
      }
    }
  }

  .el-checkbox--session {
    .el-checkbox__inner {
      border-color: #4b5aca;
    }
    .is-checked {
      .el-checkbox__inner {
        border-color: #4b5aca;
        background: #4b5aca;
      }
    }
  }

  .el-checkbox--college {
    .el-checkbox__inner {
      border-color: #ffe946;
    }
    .is-checked {
      .el-checkbox__inner {
        border-color: #ffe946;
        background: #ffe946;
      }
    }
  }

  .el-checkbox--yourown {
    .el-checkbox__inner {
      border-color: #b039c5;
    }
    .is-checked {
      .el-checkbox__inner {
        border-color: #b039c5;
        background: #b039c5;
      }
    }
  }
}

@include breakPoints(md) {
  .shedule-calendar {
    height: auto;
    .shedule-calendar__controller {
      justify-self: center;
      max-width: 100%;
      width: 100%;
    }
    .shedule-calendar__body {
      column-gap: 1rem;
    }
    .column__header {
      border-radius: 0.8rem;
      height: 100%;
      row-gap: 1rem;
      grid-template-areas:
        "day"
        "number";
    }
    .header__weak-name {
      align-self: flex-end;
    }
    .header__day {
      font-size: 1.4rem;
      align-self: flex-start;
    }
    .body__column {
      grid-template-rows: 7rem;
      padding: 0.2rem;
      &:hover {
        background-color: transparent;
      }
    }
    .body__column--is-events {
      border: 1px solid $brand-clr-1;
    }
    .body__column--is-now {
      background-color: transparent !important;
      .column__header {
        background-color: $brand-clr-5 !important;
      }
    }
    .body__column--is-selected {
      background-color: transparent !important;
      .column__header {
        background-color: $brand-clr-1 !important;
      }
      .header__weak-name,
      .header__day {
        color: $basic-white;
      }
    }
  }
}
</style>
