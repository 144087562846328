<template>
  <div class="switch-tab">
    <div class="switch-tab__buttons">
      <button
        :class="[
          'switch-tab__button',
          {
            'switch-tab__button--active': button.component === currentComponent,
          },
        ]"
        @click="currentComponent = button.component"
        v-for="(button, idx) of buttons"
        :key="idx"
      >
        <div
          v-if="button.component === currentComponent"
          class="button__curtain"
        >
          <m-icon v-if="idx !== 0" name="angle" class="angle__left" />
          <m-icon name="angle" class="angle__right" />
        </div>
        <span v-if="!mobileMode" class="button-text">{{ button.title }}</span>
        <m-icon v-else :name="button.icon" />
      </button>
    </div>
    <component :is="currentComponent"></component>
  </div>
</template>
<script>
import webinars from '@/views/EducationalProcess/discipline/modules/tabs/webinars';
import description from './tabs/discription.vue';
import materials from './tabs/materials';
import tests from './tabs/tests.vue';
import tasks from './tabs/tasks/index.vue';

export default {
  data() {
    return {
      currentComponent: this.buttons[0]?.component,
    };
  },
  components: {
    description,
    materials,
    tests,
    tasks,
    webinars,
  },
  computed: {
    mobileMode() {
      return this.$store.state.mobileMode;
    },
  },
  watch: {
    buttons(newValue) {
      this.currentComponent = newValue[0].component;
    },
  },
  props: {
    buttons: Array,
  },
};
</script>

<style lang="scss">
.switch-tab {
  width: 100%;
  height: 100%;
  max-width: 89.5rem;
  justify-self: center;
  display: grid;
  grid-template-rows: max-content 1fr;
  .switch-tab__buttons {
    display: flex;
    column-gap: 1.6rem;
    margin-bottom: 2.4rem;
  }
  .switch-tab__button {
    box-sizing: border-box;
    transition-duration: 0.3s;
    transition-property: background-color, color;
    width: 12.8rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    border: 1px solid $base-border-clr;
    background-color: transparent;
    position: relative;
    z-index: 5;
    .button-text {
      font-family: "Gilroy-Bold";
      font-size: 1.4rem;
      color: $secondary-text-clr;
    }
    .m-icon--several-files,
    .m-icon--tablet-list {
      fill: $secondary-text-clr;
    }
  }
  .m-icon--angle {
    width: 0.8rem;
    position: absolute;
    bottom: 1rem;
    fill: #f2f6fc;
  }
  .angle__right {
    right: -0.8rem;
  }
  .angle__left {
    transform: scaleX(-1);
    left: -0.8rem;
  }
  .button__curtain {
    border-radius: 1rem 1rem 0 0;
    width: 13rem;
    position: absolute;
    left: -0.1rem;
    top: 0;
    background-color: transparent;
    z-index: -1;
  }
  .switch-tab__button--active {
    background-color: $extra-light-border;
    border-color: $extra-light-border;
    .button-text {
      color: $basic-black;
    }
    .m-icon--several-files,
    .m-icon--tablet-list {
      fill: $brand-clr-1;
    }
    .button__curtain {
      height: 7.5rem;
      background-color: #f2f3f9;
    }
  }
}
@include breakPoints(md) {
  .switch-tab {
    .switch-tab__buttons {
      margin-bottom: 0;
    }
    .switch-tab__button {
      width: 7rem;
      height: 6rem;
      border-radius: 1rem 1rem 0 0;
    }
    .button__curtain {
      width: 100%;
    }
    .angle__right {
      bottom: 1.6rem;
      right: -1rem;
    }
  }
}
</style>
