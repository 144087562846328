<template>
  <svg ref="graph" class="graph">
    <g>
      <line
        class="graph__line"
        :x1="10 + 'px'"
        :y1="positionY(0) + 'px'"
        :x2="10 + 'px'"
        :y2="positionY(points.length - 1) + 'px'"
        stroke="black"
      />
      <circle
        v-for="(point, idx) in points"
        :class="['graph__circle', { 'graph__circle--stroke': !point.status }]"
        :key="idx"
        :cx="10 + 'px'"
        :cy="positionY(idx) + 'px'"
        :r="radiusDot + 'px'"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    points: {
      type: Array,
      default: () => [],
    },

    radiusDot: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      height: 0,
    };
  },

  mounted() {
    this.setHeight();

    new ResizeObserver(this.setHeight).observe(this.$refs.graph);
  },

  computed: {
    heightSector() {
      return this.height / this.points.length;
    },
  },

  methods: {
    async setHeight() {
      await this.$nextTick();
      if (this.$refs.graph) {
        this.height = this.$refs.graph.clientHeight;
      }
    },

    strokeFunction(index) {
      if (this.dotesStroke.length) {
        return Boolean(this.dotesStroke.find((el) => el === index + 1));
      }
      return false;
    },
    positionY(index) {
      return index * this.heightSector + this.heightSector / 2;
    },
  },
};
</script>

<style lang="scss">
.graph {
  display: flex;
  justify-content: center;
  width: 20px;
  height: 100%;

  &__line {
    stroke-width: 2;
    stroke: $brand-clr-1;
  }

  &__circle {
    fill: $brand-clr-1;

    &--stroke {
      fill: $basic-white;
      stroke: $brand-clr-1;
      stroke-width: 2;
    }
  }
}
</style>
