<template>
  <div class="user-tab">
    <div v-if="!mobileMode" class="user__avatar">
      <img class="user__img" :src="setPhoto()" alt="avatar" />
    </div>
    <span class="description__name">{{ studentInfo.student }}</span>
    <span class="description__group" v-if="studentInfo.group"
      ><b>Группа: </b> {{ studentInfo.group }}
    </span>
    <span class="description__specialty">{{ studentInfo.specialty }}</span>
    <span class="description__course"
      >{{ studentInfo.current_course }}
      {{ studentInfo.current_semestr }} семестр</span
    >
    <span class="description__form">{{ studentInfo.form }}</span>
    <waves class="waves-backgound--user-tab" />
  </div>
</template>

<script>
import waves from '@/components/ui/waves';

export default {
  components: {
    waves,
  },

  computed: {
    studentInfo() {
      return this.$store.state.userInfo;
    },
    mobileMode() {
      return this.$store.state.mobileMode;
    },
  },

  methods: {
    setPhoto() {
      if (this.studentInfo.avatar_link) return this.studentInfo.avatar_link;
      return 'https://college.instudy.online/img/mgok/avatar/avatar.jpg';
    },
  },
};
</script>

<style lang="scss">
.user-tab {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.8rem;
  row-gap: 0.2rem;
  grid-template-areas:
    "avatar name"
    "avatar group"
    "avatar form"
    "avatar course"
    "avatar qualification"
    "specialty specialty";
  .description__name {
    justify-self: flex-start;
    grid-area: name;
    font-weight: bold;
    color: $basic-black;
    text-align: left;
    font-size: 1.2rem;
  }
  .description__form {
    grid-area: form;
  }
  .description__group {
    grid-area: group;
    color: $basic-black;
    b {
      color: inherit;
    }
  }
  .description__course {
    grid-area: course;
    margin-bottom: 0.3rem;
  }
  .description__qualification {
    grid-area: qualification;
  }
  .description__specialty {
    grid-area: specialty;
    text-align: left;
  }
  .description__specialty,
  .description__form,
  .description__group,
  .description__course,
  .description__qualification {
    justify-self: flex-start;
    font-size: 1rem;
  }
  .description__qualification::first-letter {
    text-transform: uppercase;
  }
  .user__avatar {
    grid-area: avatar;
    display: flex;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 1rem;
    overflow: hidden;
    align-self: flex-start;
  }
  .waves-backgound--user-tab {
    position: absolute;
    bottom: 0;
    transform: translateX(-27rem) translateY(40rem) rotate(-45deg) !important;
    width: 97.9rem;
    height: 83rem;
    z-index: -1;
    stroke: #f4f4f6;
    stroke-width: 0.1rem;
  }
}
@include breakPoints(md) {
  .user-tab {
    position: relative;
    overflow: hidden;
    padding: 1.6rem;
    border-radius: 1rem;
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
    grid-template-areas:
      "name"
      "group"
      "form"
      "course"
      "qualification"
      "specialty";
    .description__name,
    .description__group {
      font-size: 1.5rem;
    }
    .description__specialty,
    .description__course,
    .description__form {
      font-size: 1.3rem;
    }
  }
}
</style>
