<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" class="xd476a__calendar-icon">
        <path d="M29.4732 10.5264H10.5259V29.4737H29.4732V10.5264Z" fill="white"></path>
        <path d="M14.4126 25.2247C13.7044 24.7463 13.2142 24.0476 12.9465 23.1239L14.5902 22.4466C14.7394 23.015 15 23.4555 15.3718 23.7682C15.7413 24.0808 16.1913 24.2347 16.7171 24.2347C17.2547 24.2347 17.7165 24.0713 18.1026 23.7445C18.4886 23.4176 18.6828 23.0008 18.6828 22.4963C18.6828 21.98 18.4792 21.5584 18.0718 21.2316C17.6644 20.9047 17.1528 20.7413 16.5418 20.7413H15.5921V19.1142H16.4447C16.9705 19.1142 17.4134 18.9721 17.7734 18.6879C18.1334 18.4037 18.3134 18.0153 18.3134 17.5203C18.3134 17.0797 18.1523 16.7292 17.8302 16.4663C17.5081 16.2034 17.1007 16.0708 16.6057 16.0708C16.1226 16.0708 15.7389 16.1987 15.4547 16.4568C15.1707 16.7157 14.9571 17.0425 14.8342 17.4066L13.2071 16.7292C13.4226 16.1182 13.8181 15.5782 14.3984 15.1116C14.9786 14.645 15.72 14.4105 16.62 14.4105C17.2855 14.4105 17.8847 14.5384 18.4152 14.7966C18.9457 15.0547 19.3626 15.4124 19.6634 15.8671C19.9642 16.3242 20.1134 16.8358 20.1134 17.4042C20.1134 17.9845 19.9736 18.4747 19.6942 18.8774C19.4147 19.28 19.0713 19.5879 18.6639 19.8034V19.9005C19.1899 20.1174 19.6468 20.4736 19.9855 20.9308C20.3289 21.3926 20.5018 21.9445 20.5018 22.5887C20.5018 23.2329 20.3384 23.8084 20.0115 24.3129C19.6847 24.8174 19.2323 25.2153 18.6592 25.5042C18.0836 25.7932 17.4371 25.94 16.7194 25.94C15.8881 25.9424 15.1207 25.7032 14.4126 25.2247ZM24.5092 17.0679L22.7044 18.3729L21.8021 17.0039L25.0397 14.6687H26.2807V25.6842H24.5092V17.0679Z" fill="#4285F4"></path>
        <path d="M29.4732 29.4738H10.5259V38.0001H29.4732V29.4738Z" fill="#34A853"></path>
        <path d="M29.4737 2H4.8421C3.27184 2 2 3.27184 2 4.8421V29.4737H10.5263V10.5263H29.4737V2Z" fill="#4285F4"></path>
        <path d="M2 29.4738V35.158C2 36.7282 3.27184 38.0001 4.8421 38.0001H10.5263V29.4738H2Z" fill="#188038"></path>
        <path d="M37.9999 10.5264H29.4736V29.4737H37.9999V10.5264Z" fill="#FBBC04"></path>
        <path d="M37.9999 10.5263V4.8421C37.9999 3.27184 36.7281 2 35.1578 2H29.4736V10.5263H37.9999Z" fill="#1967D2"></path>
        <path d="M29.4736 38.0001L37.9999 29.4738H29.4736V38.0001Z" fill="#EA4335"></path>
    </svg>
</template>

<script>
export default {
  name: 'googleCalendar',
};
</script>
