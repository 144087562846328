<template>
  <div :class="['m-collapse', { 'm-collapse--active': showBody }]">
    <div @click="handlerShowBody" class="m-collapse__header" ref="header">
      <slot name="header"></slot>
      <i
        v-if="blockShowBody === undefined || blockShowBody === false"
        :class="[
          'fas fa-chevron-down',
          'm-collapse__header-icon',
          { 'm-collapse__header-icon--active': showBody },
        ]"
      ></i>
    </div>
    <div :class="['m-collapse__body', { 'm-collapse__body--show': showBody }]">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'm-collapse',
  data() {
    return {
      showBody: false,
    };
  },
  watch: {
    manualShow(newValue) {
      this.showBody = newValue;
    },
  },
  props: {
    manualShow: {
      type: Boolean,
      default: false,
    },
    blockShowBody: {
      type: [Boolean, undefined],
      default: undefined,
    },
  },
  methods: {
    handlerShowBody() {
      if (this.blockShowBody === true) {
        return;
      }
      this.showBody = !this.showBody;
      this.$emit('toggle', this.showBody);
    },
  },
  mounted() {
    if (this.manualShow !== undefined) this.showBody = this.manualShow;
  },
};
</script>

<style lang="scss">
.m-collapse {
  overflow: hidden;
  width: 100%;
  height: auto;
}
.m-collapse__header-icon {
  transition-duration: 0.3s;
  transition-property: transform;
  justify-self: center;
  color: inherit;
  font-size: 1.5rem;
}
.m-collapse__header {
  display: grid;
  grid-template-columns: 1fr 2rem;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 2;
  padding: 20px;
  background-color: white;
}
.m-collapse__body {
  transition: ease 0.3s;
  z-index: 1;
  max-height: 0;
  overflow-y: auto;
}
.m-collapse__body--show {
  max-height: 3000px;
}
.m-collapse__header-icon--active {
  transform: rotate(180deg);
}
</style>
