<template>
  <div class="completedTasks">
    <span class="text-title">Выполненные задания</span>
    <div class="completedTasks__tab-list">
      <div
        @mouseover="hoveredElement = tab"
        @mouseout="hoveredElement = null"
        v-for="tab of tabs"
        :key="tab"
        class="completedTasks-tab"
      >
        <div class="completed-task">
          <span class="completed-task__title">Контрольные</span>
          <m-progress :percent="27" unit="rem" :size="5.5" :strokeWidth="0.1" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [1, 2, 3, 4],
      hoveredElement: null,
    };
  },
  computed: {
    tabletMode() {
      return this.$store.state.tabletMode;
    },
  },
  methods: {
    hoverStart(el) {
      this.hoveredElement = el;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.completedTasks {
  display: grid;
  .completedTasks__tab-list {
    border-radius: 1rem;
    background-color: $basic-white;
    border-radius: 1rem;
    display: grid;
    padding: 1.6rem;
    row-gap: 0.8rem;
  }
  .completedTasks-tab {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 0.8rem;

    .m-progress {
      transition-duration: 0.3s;
      background: rgb(200, 180, 246);
      background: linear-gradient(
        356deg,
        rgba(200, 180, 246, 1) 0%,
        rgba(246, 246, 246, 1) 100%
      );
      box-shadow: 0px 4px 16px rgba(117, 69, 201, 0.38);
      .m-progress__percent {
        transition-duration: 0.3s;
        transition-property: color;
        font-size: 1.6rem;
        font-family: "Gilroy-Regular";
        color: $brand-clr-1;
      }
      .m-progress__circle {
        transition-duration: 0.3s;
        transition-property: color;
        stroke: $brand-clr-1;
      }
      .m-progress__circle-bg {
        stroke: transparent;
      }
    }

    .completed-task {
      cursor: pointer;
      display: grid;
      column-gap: 1.6rem;
      grid-template-columns: max-content 1fr;
      grid-template-areas: "b a";
      &:hover {
        .completed-task__title {
          background-color: $brand-clr-1;
        }
        .m-progress {
          background: linear-gradient(
            356deg,
            rgba(117, 69, 201, 1) 0%,
            rgba(246, 246, 246, 1) 100%
          );
          box-shadow: 0px 0.4rem 1.6rem #7545c9;
        }
        .m-progress__percent {
          color: $basic-white;
        }
        .m-progress__circle {
          stroke: $basic-white;
        }
      }
    }
    .completed-task__title {
      transition-duration: 0.3s;
      transition-property: background-color;
      font-family: "Gilroy-Bold";
      background-color: $brand-clr-3;
      height: max-content;
      min-height: 4rem;
      align-self: center;
      font-size: 1.4rem;
      padding: 1.2rem 2.6rem;
      color: $basic-white;
      border-radius: 1rem;
      text-align: center;
      grid-area: a;
    }
  }
}
</style>
