export default {
  state: {
    resume: {
      surname: '',
      name: '',
      middleName: '',
    },
    skills: [],
    resumeStatus: false,
    specializations: [],
    languages: [],
    countries: [],
    suggestsSkill: [],
  },
  mutations: {
    setResumeStatus(state, value) {
      state.resumeStatus = value;
    },
    setResume(state, value) {
      state.resume = value;
    },
    setSpecializations(state, value) {
      state.specializations = value;
    },
    setLanguages(state, value) {
      state.languages = value;
    },
    setCountries(state, value) {
      state.countries = value;
    },
    setSkills(state, value) {
      state.skills = value;
    },
    suggestsSkill(state, value) {
      state.suggestsSkill = value.items;
    },
  },
  actions: {
    async getResume({ commit, state, dispatch }) {
      const resume = JSON.parse(localStorage.getItem('resume'));
      if (resume) {
        commit('setResume', resume);
        commit('setResumeStatus', true);
      } else {
        commit('setResumeStatus', false);
      }
      dispatch('getData', {
        path: 'https://api.hh.ru/specializations',
        mutation: 'setSpecializations',
      });
      dispatch('getData', {
        path: 'https://api.hh.ru/languages',
        mutation: 'setLanguages',
      });
      dispatch('getData', {
        path: 'https://api.hh.ru/areas/countries',
        mutation: 'setCountries',
      });

      return state.resumeStatus;
    },
    async getData({ commit }, incomingData) {
      fetch(incomingData.path, {
        headers: { 'User-Agent': 'api-test-agent' },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          commit(incomingData.mutation, data);
        });
    },
  },
};
