<template>
  <div class="table">
    <div :class="['table__header', {'isPadding': tableData.length > 1}]">
      <div class="table__row table__row--header" :style="templateColumnsStyle">
        <span
          :class="['table__col', `table__col--${idx}`]"
          v-for="(header, idx) of headers"
          :key="idx"
        >
          {{ header }}
        </span>
      </div>
    </div>
    <div class="table__body">
      <template v-if="tableData && tableData.length">
        <div
          class="table__row"
          v-for="(row, idx_row) of tableData"
          :key="idx_row"
          :style="templateColumnsStyle"
          @click="$emit('row-click', row)"
        >
          <template v-if="!slotRow">
            <div class="table__col" v-for="(col, idx_col) in row" :key="idx_col">
              {{ col }}
            </div>
          </template>

          <template v-else>
            <slot name="row" v-bind="row"></slot>
          </template>
        </div>
      </template>
      <div v-else class="table__row _isEmpty">
        Нет данных
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },

    headers: {
      type: Array,
      default: () => [],
    },

    slotRow: {
      type: Boolean,
      default: false,
    },

    templateColumns: {
      type: String,
      default: '2% 23% 8% 18% 14% 11% 16%',
    },
  },

  computed: {
    templateColumnsStyle() {
      return { 'grid-template-columns': this.templateColumns };
    },
  },

  methods: {
    isEmpty() {
      return this.$helpers.helperFunctions.isEmpty(this.tableData);
    },
  },
};
</script>

<style lang="scss">
.table {
  max-width: 100%;
  box-shadow: 0px 0px 5px 1px #c4c4c4;
  border-radius: 1rem;
  padding: 1.6rem;
  display: grid;
  row-gap: 1.2rem;

  @include breakPoints(sm) {
    box-shadow: unset;
  }

  .table__row:nth-child(odd) {
    background-color: #f7f7f8;
  }

  .table__row--header {
    background-color: transparent !important;

    .table__col {
      color: #666666;
      font-weight: 500;
    }
  }

  .table__col {
    font-family: "Roboto-Regular";
    font-weight: 400;
    color: $basic-black;
    font-size: 1.4rem;

    // &:nth-child(1),
    // &:nth-child(3),
    // &:nth-child(4),
    // &:nth-child(6),
    // &:nth-child(7) {
    //   @include breakPoints(sm) {
    //     display: none;
    //   }
    // }
  }

  .table__col:nth-child(2) {
    justify-self: flex-start;
  }

  .table__col:last-child {
    justify-self: flex-start;
  }

  .table__row {
    column-gap: 1rem;
    display: grid;
    justify-items: center;
    align-items: center;

    @include breakPoints(sm) {
      grid-template-columns: 1fr 1fr;
    }

    &._isEmpty {
      font-size: 1.4rem;
    }
  }

  .table__body {
    .table__row {
      border-top: 1px solid #ebecee;
      padding: 0.8rem 0;
    }
    .table__col {
      font-size: 1.2rem;
    }
    .table__row:last-child {
      border-bottom: 1px solid #ebecee;
    }
  }
}
</style>
