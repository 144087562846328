<template>
  <div class="lesson-tab">
    <span class="text-title">Продолжить просмотр</span>
    <lessonTab />
  </div>
</template>

<script>
import lessonTab from '@/components/ui/lessonTab.vue';

export default {
  components: {
    lessonTab,
  },
};
</script>

<style lang="scss">
.lesson-tab {
  display: grid;
}
</style>
