<template>
  <div class="deanery">
    <div class="deanery__main page">
      <div class="deanery__tab-block">
        <div
          :class="['tab', { 'active-tab': activeTab(tab.id) }]"
          v-for="(tab, idx) of tabs"
          :key="idx"
          @click="currentComponentId = idx"
        >
          <m-icon :name="tab.icon" />
          <span class="tab__title text-title">{{ tab.lable }}</span>
          <span v-if="tab.numberOfFiles" class="tab__total-files"
            >{{ tab.numberOfFiles }} {{ plural(tab.numberOfFiles) }}</span
          >
        </div>
      </div>
      <component
        v-if="currentComponent"
        class="deanery__content"
        id="current-deanery-component"
        :changeViews="false"
        :treeMode="true"
        :reference-types="referenceTypes"
        :attestation-types="attestationTypes"
        :reference-count="referenceCount"
        :statements-data="statementsData"
        :status-sending="statusSending"
        :is="currentComponent.component"
        v-model="currentComponent.children"
        @create-reference="createReferenceHandler"
        @create-statement="createStatementHandler"
        @clear-status="statusSending = null"
      ></component>
    </div>
    <aside-bar :componentsList="asideList" class="asside-bar" />
  </div>
</template>

<script>

import statements from '@/views/Deanery/statements';
import documents from '@/components/documents/index.vue';
import asideBar from '@/components/asideBar';
import { mapActions, mapState } from 'vuex';
import orderingInformation from './orderingInformation/index.vue';

export default {
  name: 'DeaneryPage',

  data() {
    return {
      currentComponentId: null,
      tabs: [],
      asideList: ['curatorTab'],
      icons: {
        1: 'file-edit',
        2: 'file',
        3: 'file',
      },

      currentHistory: null,
      statusSending: null,
    };
  },

  components: {
    'aside-bar': asideBar,
    documents,
    orderingInformation,
    statements,
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      referenceTypes: (state) => state.deanery.referenceTypes,
      attestationTypes: (state) => state.deanery.attestationTypes,
      referenceCount: (state) => state.deanery.referenceCount,
      statementsData: (state) => state.deanery.statementsList,
    }),

    statementsTypes() {
      return this.statementsData?.statementType || [];
    },

    currentComponent() {
      return this.tabs.find((el, idx) => idx === this.currentComponentId);
    },

  },

  watch: {
    async currentComponentId() {
      await this.$nextTick();

      if (this.$isMobile()) {
        this.$helpers.helperFunctions.scrollTo('current-deanery-component', 'start');
      }
    },
  },

  methods: {
    ...mapActions([
      'getDeaneryData',
      'createReference',
      'getReferenceList',
      'getReferenceHistory',
      'getStatementsList',
      'getStatementsHistory',
      'createStatement',
    ]),

    plural(number) {
      const declension = ['Файл', 'Файла', 'Файлов'];
      const cases = [2, 0, 1, 1, 1, 2];
      return declension[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },
    activeTab(id) {
      return this.currentComponent ? this.currentComponent.id === id : false;
    },

    async createStatementHandler(form) {
      const status = await this.createStatement(form);

      const title = status
        ? `Заявление ${form?.statement_name?.toLowerCase() || ''} успешно сформирована`
        : 'Упс, что-то пошло не так :( <br> Вернитесь позже';

      this.statusSending = {
        title,
        done: status,
      };
    },

    async createReferenceHandler(form) {
      const result = this.referenceTypes.find((el) => el.index === form.reference_type);
      const referenceName = result?.name ?? '';

      const status = await this.createReference(form);

      const title = status === 200
        ? `${referenceName} успешно сформирована`
        : 'Упс, что-то пошло не так :( <br> Вернитесь позже';

      this.statusSending = {
        title,
        referenceName,
        done: status === 200,
      };
    },
  },

  async created() {
    Promise.all([
      await this.getDeaneryData(),
      await this.getReferenceList(),
      await this.getReferenceHistory(),
      await this.getStatementsList(),
      await this.getStatementsHistory(),
    ]);

    this.tabs = [
      ...this.$store.state.deanery.folders.map((el) => {
        el.component = 'documents';
        el.icon = this.icons[el.id] ? this.icons[el.id] : 'file';
        return el;
      }),
      {
        component: 'orderingInformation',
        icon: 'file-watch',
        id: 5,
        lable: 'Заказ справок',
      },
      {
        component: 'statements',
        icon: 'file',
        id: 6,
        lable: 'Отправка заявлений',
      },
    ];
  },

};
</script>

<style lang="scss">
.deanery {
  display: grid;
  height: 100%;
  grid-template-columns: auto 29rem;
  grid-template-areas: "content aside-bar";

  @include breakPoints(lg) {
    grid-template-columns: 1fr;
    grid-template-areas: "aside-bar" "content";
    grid-template-rows: max-content 1fr;
  }

  @include breakPoints(md) {
    grid-template-areas: "content";
    grid-template-rows: 1fr;
  }

  .deanery__main {
    display: grid;
    row-gap: 3.2rem;
    grid-template-rows: max-content 1fr;
    grid-area: content;
    border-radius: 2rem 0 0 0;
    height: 100%;
    overflow: auto;

    @include breakPoints(md) {
      border-radius: 0;
    }
  }

  .deanery__tab-block {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(17.5rem, 1fr));
    gap: 1.6rem;
  }

  .asside-bar {
    grid-area: aside-bar;

    @include breakPoints(md) {
      display: none;
    }
  }

  .deanery__references {
    display: flex;
  }

  .tab {
    flex-shrink: 0;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: background-color;
    width: 100%;
    min-height: 9.6rem;
    display: grid;
    border-radius: 1rem;
    padding: 1.2rem 1.7rem;
    background-color: $brand-clr-5;
    grid-template-rows: auto 1fr auto;
    &:hover {
      background-color: darken($color: $brand-clr-5, $amount: 5%);
    }
    .m-icon {
      transition-duration: inherit;
      transition-property: fill;
      width: 2rem;
      height: 3.5rem;
      margin-bottom: 1rem;
    }
    .tab__title {
      margin-bottom: 0.3rem;
      align-self: flex-start;
    }
    .tab__total-files {
      transition-duration: inherit;
      transition-property: color;
      font-family: "Gilroy-Regular";
      font-size: 1.2rem;
      color: $secondary-text-clr;
    }
  }
  .active-tab {
    background-color: $brand-clr-1;
    &:hover {
      background-color: $brand-clr-1;
    }
    .m-icon {
      fill: $base-border-clr;
    }
    .tab__title {
      color: $basic-white;
    }
    .tab__total-files {
      color: $base-border-clr;
    }
  }
}
</style>
