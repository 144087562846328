<template lang="pug">
  .list-of-semesters(v-if="list" :class="{ '--isDisabled': disabled }")
    .list-of-semesters__item(
      v-for="(semesters, year) in list"
      :key="`${year}-${semesters}`"
      :class="{ 'list-of-semesters__item--selected': value && value.year === year }"
    )
      h3.item__title {{ year }}

      button.item__btn(
        v-for="(semester, idx) in semesters"
        :key="idx"
        :class="{ 'item__btn--selected': value && value.semester === semester }"
        :disabled="disabled"
        @click="$emit('input', value && value.semester === semester &&  value.year === year ? null : { semester, year:+year })"
      ) {{ semester }} семестр

</template>

<script>
export default {
  props: {
    value: {
      type: [Object, null],
      default: null,
    },

    list: {
      type: [Object, null],
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.list-of-semesters {
  display: flex;
  justify-content: center;

  @include breakPoints(sm) {
    overflow-x: auto;
    justify-content: unset;
  }

  &._isDisabled {
    user-select: none;

    .list-of-courses__item {
      cursor: not-allowed;
    }

    .item__btn {
      cursor: not-allowed;
    }
  }

  &__item {
    flex-shrink: 0;
    max-width: 12.5rem;
    width: 100%;
    min-width: 10rem;
    transition-duration: 0.3s;
    transition-property: background-color;
    justify-items: center;
    background-color: #f2f3f9;
    border-radius: 1rem;
    padding: 1.2rem 1rem;
    display: grid;
    row-gap: 1rem;

    &:not(:last-child) {
      margin-right: 2.6rem;

      @include breakPoints(sm) {
        margin-right: 1.5rem;
      }
    }
  }

  .item__title {
    transition-duration: 0.3s;
    transition-property: color;
    text-transform: uppercase;
    font-family: "Gilroy-Bold";
    color: $basic-black;
  }

  .item__btn {
    transition-duration: 0.3s;
    transition-property: background-color, color;
    background-color: $basic-white;
    border-radius: 0.6rem;
    width: 100%;
    height: 2.4rem;
    font-family: "Gilroy-Bold";
    color: $basic-black;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .item__btn--selected {
    background-color: $brand-clr-1;
    color: $basic-white;
  }
  .list-of-semesters__item--selected {
    background-color: #e3ddfa;
    .item__title {
      color: $brand-clr-1;
    }
  }
}
</style>
