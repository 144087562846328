<template>
  <section class="history">
    <el-collapse
      v-model="activeGroup"
      accordion
      class="history__collapse-wrp"
    >
      <el-collapse-item
        v-for="group in history"
        :key="group.index"
        :name="group.index"
        class="history__collapse"
      >
        <template #title>
          <div class="header__content-wrp">
            <h3 class="history__sub-title">{{ group.title }}</h3>

            <m-icon name="chevron" class="" />
          </div>
        </template>

        <el-collapse v-model="activeItem" accordion>
          <el-collapse-item
            v-for="(item, idx) of group.history"
            :key="idx"
            :name="`${group.index}.${idx}`"
          >
            <template #title>
              <div class="header__content-wrp">
                <h3 class="history__sub-title">
                  {{ item.send_type }}
                </h3>

                <m-icon name="chevron" />
              </div>
            </template>

            <div class="body__content">
              <div class="history__reference">
                <div class="reference__left-section">
                  <div class="reference__graph">
                    <transition name="fade">
                      <graph
                        v-if="activeItem === `${group.index}.${idx}`"
                        :radiusDot="8"
                        :points="item.progress"
                      />
                    </transition>
                  </div>
                  <ul class="reference__list">
                    <li
                      v-for="(progress, idx) in item.progress"
                      :key="idx"
                      class="list-item"
                    >
                      <b>{{ progress.title }}</b>
                      <time>{{ progress.date }}</time>
                      <span v-if="progress.time">|</span>
                      <time>{{ progress.time }}</time>
                    </li>
                  </ul>
                </div>
                <div v-if="false" class="reference__right-section">
                  <button class="reference__download">
                    <span>Скачать</span><span>14.1 КВ</span>
                  </button>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>
  </section>
</template>

<script>
import graph from '@/views/Deanery/graph.vue';

export default {
  name: 'ReferenceHistory',

  components: {
    graph,
  },

  props: {
    history: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeItem: '',
      activeGroup: '',
    };
  },

  watch: {
    activeGroup() {
      this.activeItem = '';
    },
  },

  mounted() {
    if (this.$isMobile()) {
      this.$helpers.helperFunctions.scrollTo('history-statements', 'start');
    }
  },
};
</script>

<style lang="scss">
.history {
  border-top: none;

  &__group {
    margin-bottom: 1.5rem;
  }

  &__sub-title {
    color: $basic-black;
    font-family: "Gilroy-Bold";
    font-size: 1.6rem;
  }

  .el-collapse {
    display: flex;
    flex-direction: column;
    border-top: none;
    row-gap: 0.8rem;

    &-wrp {
      border-top: none;
    }
  }

  &__collapse {
    border-bottom: none;
    margin-bottom: 0.8rem;

    .header__content-wrp {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;
      justify-content: center;
      span {
        text-align: center;
      }
    }

    .m-icon--chevron {
      width: .8rem;
      transform: rotate(-90deg);
      fill: $brand-clr-2;
      transition-duration: .3s;
      transition-property: transform;
    }

    & > .el-collapse-item__wrap {
      padding: 0.8rem 0 0 2rem;
    }

    .el-collapse-item__arrow {
      display: none;
    }

    .el-collapse-item__wrap {
      border-bottom: none;
    }

    .el-collapse-item__content {
      padding-bottom: 0;
    }

    .el-collapse-item__header {
      border-bottom: none;
      line-height: unset;
      transition-duration: 0.3s;
      transition-property: background-color;
      padding: 1.2rem 2rem;
      border-radius: 1rem;
      background-color: #f8f8f8;
      border-radius: 1rem;

      &.is-active {
        background-color: rgba($color: $brand-clr-1, $alpha: 0.15);

        .m-icon--chevron {
          transform: rotate(90deg);
        }
      }
    }
  }

  .reference__list {
    display: grid;
  }

  .list-item {
    padding: 0.7rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.6rem;

    span {
      font-family: "ProximaNova-Regular";
      font-size: 1.4rem;
      color: $basic-black;
    }

    b {
      width: 100%;
      font-family: "Gilroy-Bold";
      font-size: 1.4rem;
      color: $basic-black;
    }

    time {
      line-height: 100%;
      font-family: "ProximaNova-Regular";
      font-size: 1.4rem;
      color: $basic-black;
    }
  }

  .history__reference {
    padding: 2rem 0;
  }

  .reference__left-section {
    display: grid;
    grid-template-columns: 4rem 1fr;
  }
}
</style>
