<template>
  <div
    :class="['notification-window', `notification-window--${notyfyData.type}`]"
  >
    <m-icon :name="notyfyData.type + '-icon'" />
    <h3 class="notification-window__notify">{{ headers[notyfyData.type] }}</h3>
    <button
      @click="$emit('close', notyfyData)"
      class="notification-window__close"
    >
      <m-icon name="close" />
    </button>
    <div class="notification-window__content">
      {{ notyfyData.content }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      duration: this.notyfyData.duration ?? 3000,
      timoutId: null,
      headers: {
        success: 'Успех',
        warning: 'warning message',
        error: 'Ошибка',
        info: 'info message',
      },
    };
  },

  props: {
    notyfyData: Object,
  },

  beforeDestroy() {
    clearTimeout(this.timoutId);
  },

  mounted() {
    if (this.notyfyData.autoclose !== false) {
      this.timoutId = setTimeout(() => {
        this.$emit('close', this.notyfyData);
      }, this.duration);
    }
  },
};
</script>

<style lang="scss">
.notification-window {
  z-index: 100;
  max-width: 35rem;
  display: grid;
  column-gap: 1.6rem;
  row-gap: 0.5rem;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    "notify-icon notify-text close-icon"
    "notify-icon notify-content close-icon";
  padding: 1.3rem 1.6rem;
  border-radius: 1rem 1rem 0px 1rem;

  right: 2rem;
  position: sticky;
  .notification-window__notify {
    grid-area: notify-text;
    font-family: "Gilroy-Bold";
    color: $basic-black;
    text-transform: uppercase;
    font-size: 1.4rem;
  }
  .m-icon--sucess-icon {
    width: 4rem;
    grid-area: notify-icon;
  }
  .m-icon--close {
    width: 1rem;
    cursor: pointer;
  }
  .notification-window__content {
    grid-area: notify-content;
    font-size: 1.4rem;
    color: $basic-black;
    font-family: "ProximaNova-Regular";

    P {
      color: $basic-black;
      font-family: "ProximaNova-Regular";
    }
  }
  .notification-window__close {
    background-color: transparent;
    grid-area: close-icon;
    align-self: flex-start;
    cursor: pointer;
  }
}
.notification-window--success {
  background-color: #befaad;
}
.notification-window--warning {
  background-color: #ffe946;
}
.notification-window--error {
  background-color: #ff6c46;
}
.notification-window--info {
  background-color: #999999;
}
</style>
