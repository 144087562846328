<template>
  <div class="tab-webinars">
    <div class="tab-webinars__tabs-block">
      <ul>
        <li v-for="(webinar, idx) of webinars" :key="idx">
          {{ webinar.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    webinars() {
      return this.$store.state.discipline.disciplineData.webinar;
    },
  },
};
</script>

<style lang="scss">
.tab-webinars {
  .tab-webinars__tabs-block {
    display: flex;
    column-gap: 2.4rem;
    padding: 1.6rem 3.2rem;
    border-radius: 1rem;
    background-color: #f2f3f9;
    margin-bottom: 3.2rem;
  }
}
</style>
