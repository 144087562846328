import axios from 'axios';

export default {
  namespaced: true,
  state: {
    messageData: {
      classmatesWithMsgCount: [],
      teacherListWithMsgCount: [],
    },
    chatList: [],
    selectedChat: null,
  },
  mutations: {
    setMessages(state, value) {
      state.messageData = value;
    },
    setChatlist(state, value) {
      state.chatList = value;
    },
    setSelectedChat(state, value) {
      state.selectedChat = value;
    },
  },
  actions: {
    async getMessages({ commit }) {
      await axios.get('api/messages').then((res) => {
        if (res.data.status) {
          commit('setMessages', res.data);
        }
      });
    },
    async getChatList({ commit }, id) {
      await axios.get(`api/messages/${id}`).then((res) => {
        if (res.data.status) {
          commit('setChatlist', res.data.messages);
        }
      });
    },
    async sendMessage({ commit }, data) {
      return await axios.post('api/messages', data).then((res) => res);
    },
  },
};
