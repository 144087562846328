<template>
  <div
    @click="$emit('select', dataTab)"
    :class="['curotor-tab', { 'curator-tab--selected': value === dataTab }]"
  >
    <div class="curotor-tab__header">
      <span class="header__text">Куратор</span>
      <span v-if="dataTab.newMsgCount" class="header__count">{{ dataTab.newMsgCount }}</span>
    </div>
    <div class="curotor-tab__body">
      <div class="avatar-wrp">
        <img :src="setPhoto()" alt="user-avatar" />
      </div>
      <span class="user-tab-item__name">{{ dataTab.name }}</span>
      <span v-if="dataTab.lastDate" class="user-tab-item__date">{{
        dataTab.lastDate
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    dataTab: Object,
    value: [Object, null],
  },
  methods: {
    ...mapGetters(['getCuratorInfo']),
    setPhoto() {
      if (this.getCuratorInfo().photo) return `data:image/png;base64,${this.getCuratorInfo().photo}`;
      return 'https://college.instudy.online/img/mgok/avatar/avatar.jpg';
    },
  },
};
</script>

<style lang="scss">
.curotor-tab {
  cursor: pointer;
  border-radius: 1rem;
  overflow: hidden;
  transition-duration: 0.3s;
  transition-property: box-shadow;
}

.curator-tab--selected {
  -webkit-box-shadow: 0px 4px 15px 2px rgba(117, 69, 201, 0.2);
  -moz-box-shadow: 0px 4px 15px 2px rgba(117, 69, 201, 0.2);
  box-shadow: 0px 4px 15px 2px rgba(117, 69, 201, 0.2);
}

.curotor-tab__header {
  padding: 1.2rem 1.6rem;
  background-color: $brand-clr-1;
  display: flex;
  justify-content: flex-start;
  column-gap: .6rem;
  align-items: center;
}

.curotor-tab__body {
  background-color: $basic-white;
  padding: 0.8rem 1.1rem;
  display: grid;
  justify-content: space-between;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: "avatar name date";
  column-gap: 0.8rem;
}
</style>
