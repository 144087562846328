<template>
  <div class="tab-wrp">
    <span class="lesson-tab__header">{{ tabData ? tabData : "История" }}</span>
    <div class="lesson-tab__content">
      <m-progress
        :customText="true"
        :percent="27"
        :size="5.5"
        :strokeWidth="0.1"
        unit="rem"
      >
        <i class="fas fa-play"></i>
      </m-progress>
      <div class="lesson-tab__description">
        <h5>{{ tabData ? tabData : "История" }}</h5>
        <span>35 мин</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabData: String,
  },
};
</script>

<style lang="scss">
.tab-wrp {
  max-width: 25rem;
  width: 25rem;
  overflow: hidden;
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  grid-template-columns: 1fr;
  background-color: $brand-clr-3;
  .lesson-tab__header {
    height: max-content;
    justify-self: flex-end;
    padding: 0.6rem 1.8rem;
    background-color: $basic-white;
    font-size: 1rem;
    font-family: "ProximaNova-Regular";
    color: $basic-black;
    border-radius: 0 0 0 1rem;
  }
  .lesson-tab__content {
    cursor: pointer;
    padding: 0 1.6rem;
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 1.6rem;
    align-items: center;
    &:hover {
      .m-progress {
        background: linear-gradient(
          356deg,
          rgba(117, 69, 201, 1) 0%,
          rgba(246, 246, 246, 1) 100%
        );
        box-shadow: 0px 0.4rem 1.6rem #7545c9;
      }
      .m-progress__text i {
        color: $basic-white;
      }
      .m-progress__circle {
        stroke: $basic-white;
      }
      h5 {
        color: $brand-clr-1 !important;
      }
    }
  }

  .lesson-tab__description {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    h5 {
      transition-duration: 0.3s;
      transition-property: color;
      color: $brand-clr-1;
      font-size: 1.4rem;
      line-height: 1.73rem;
    }
    span {
      color: $brand-clr-1;
      font-size: 1rem;
    }
  }
  .m-progress {
    transition-duration: 0.3s;
    background: rgb(200, 180, 246);
    background: linear-gradient(
      356deg,
      rgba(200, 180, 246, 1) 0%,
      rgba(246, 246, 246, 1) 100%
    );
    box-shadow: 0px 4px 16px rgba(117, 69, 201, 0.38);
    .m-progress__text i {
      transition-duration: 0.3s;
      transition-property: color;
      font-size: 1.6rem;
      color: $brand-clr-1;
    }
    .m-progress__circle {
      transition-duration: 0.3s;
      transition-property: color;
      stroke: $brand-clr-1;
    }
    .m-progress__circle-bg {
      stroke: transparent;
    }
  }
}
</style>
