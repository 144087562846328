var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{class:['navigation', { 'navigation--show': _vm.showMenuFunc }]},[_c('button',{staticClass:"mobile-menu__btn",on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},[_c('div',{class:['mobile-menu__icon', { iconActive: _vm.showMenu }]})]),_c('m-icon',{staticClass:"nav-logo nav-logo--mobile",attrs:{"name":"logo-is-2.0"}}),(_vm.mobileMode)?_c('div',{class:['user__avatar', { 'user__avatar--active': _vm.showUserInfo }],on:{"click":function($event){_vm.showUserInfo = !_vm.showUserInfo}}},[_c('img',{staticClass:"user__img",attrs:{"src":_vm.setPhoto,"alt":"avatar"}})]):_vm._e(),_c('transition',{attrs:{"name":"slide-fade-right"}},[(_vm.showUserInfoFunc)?_c('div',{staticClass:"navigation__user-info"},[_c('userTab'),_c('curatorTab'),_c('socialsTab')],1):_vm._e()]),_c('transition',{attrs:{"name":"slide-fade-left"}},[(_vm.showMenuFunc)?_c('ul',{staticClass:"navigation__list"},[(_vm.mobileMode)?_c('waves'):_vm._e(),_c('li',{class:[
          'navigation__item--logo',
          { 'mini-visible': !_vm.navigationMax },
        ]},[_c('m-icon',{staticClass:"nav-logo",attrs:{"name":"logo-is-2.0"}})],1),_vm._l((_vm.routes),function(route,index){return _c('li',{key:index,class:[
          'navigation__item',
          { 'mini-visible': !_vm.navigationMax },
          { 'item-active': _vm.checkCurrentRoute(route.path) },
          { 'navigation__item--penultimate': index === _vm.routes.length - 2 },
        ],on:{"click":function($event){_vm.showMenu = false}}},[(route.path === '/home')?_c('button',{staticClass:"btn-hide-text",on:{"click":_vm.menuToggle}},[_c('i',{class:[
              'fas fa-chevron-left',
              { 'icon-rotate': !_vm.navigationMax },
            ]})]):_vm._e(),_c('router-link',{staticClass:"navigation__link",attrs:{"to":route.path}},[_c('div',{staticClass:"icon-wrp"},[_c('el-badge',{staticClass:"navigation__badge",attrs:{"hidden":route.path !== '/messages' || !_vm.unreadMessages,"value":_vm.unreadMessages}},[_c('icon',{attrs:{"name":route.meta.icon}})],1)],1),_c('transition',{attrs:{"name":"collapse"}},[(_vm.navigationMax)?_c('span',{staticClass:"text-title"},[_vm._v(_vm._s(route.meta.title))]):_vm._e()])],1)],1)}),_c('li',{class:[
          'navigation__item',
          'navigation__item--last',
          { 'mini-visible': !_vm.navigationMax },
          { 'item-active': _vm.checkCurrentRoute('/auth') },
        ],on:{"click":function($event){return _vm.logOut()}}},[_c('div',{staticClass:"navigation__link"},[_c('div',{staticClass:"icon-wrp"},[_c('icon',{attrs:{"name":"logout"}})],1),_c('transition',{attrs:{"name":"collapse"}},[(_vm.navigationMax)?_c('span',{staticClass:"text-title"},[_vm._v("Выход")]):_vm._e()])],1)])],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }