<template>
    <a
        href="http://content.inpsycho.ru/9a5347323e77d4814e4d1922f7107cb5"
        target="_blank"
        :class="$style.navigationTab"
    >
        Навигация по обучению
    </a>
</template>

<script>
export default {
  name: 'navigationTab',
};
</script>

<style lang="scss" module>
    .navigationTab {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        padding: 1.2rem 2rem;
        border-radius: 1rem;
        background-color: $base-border-clr;
        font-family: "Gilroy-Bold";
        font-size: 1.4rem;
        color: #000;
        transition: .3s background-color;

        &:hover {
            background-color: $placeholder-text-clr;
        }
    }
</style>
