<template>
  <div class="portfolio-img">
    <div class="portfolio-img__wrp">
      <img
        ref="img"
        :src="require(`@/assets/img/development/${modalData}`)"
        alt="portfolio-img"
      />
    </div>
    <div class="portfolio-img__footer">
      <button class="portfolio-img__btn portfolio-img__btn--delete-btn">
        Удалить
      </button>
      <button class="portfolio-img__btn portfolio-img__btn--download-btn">
        Скачать
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    modalData: String,
  },
  methods: {
    setImgSize() {
      if (this.$refs.img.clientHeight > this.$refs.img.clientWidth) {
        this.$refs.img.style.height = '100%';
      } else {
        this.$refs.img.style.width = '100%';
      }
    },
  },
  mounted() {
    this.setImgSize();
  },
};
</script>

<style lang="scss">
.portfolio-img {
  display: grid;
  grid-template-rows: 1fr max-content;
  border-radius: 1rem;
  width: 50rem;
  height: 55rem;
  background-color: $basic-white;
  padding: 2.7rem;
  .portfolio-img__wrp {
    background-color: $info-clr;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .portfolio-img__btn {
    padding: 0.8rem 3.2rem;
    border-radius: 1rem;
    font-size: 1.4rem;
    font-family: "Gilroy-Bold";
    border: 1px solid $brand-clr-1;
  }
  .portfolio-img__btn--delete-btn {
    color: $brand-clr-1;
    background-color: transparent;
    justify-self: flex-start;
  }
  .portfolio-img__btn--download-btn {
    background-color: $brand-clr-1;
    color: $basic-white;
    justify-self: flex-end;
  }
  .portfolio-img__footer {
    padding-top: 2.3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
