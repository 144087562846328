<template>
  <div class="discipline-page page">
    <button
      @click="handleReturn"
      class="discipline-page__return-btn text-title"
    >
      <i class="fas fa-long-arrow-alt-left"></i>Дисциплины
    </button>
    <simplebar data-simplebar-auto-hide="false" class="discipline-page__wrp">
      <div v-if="!isEmpty(disciplineData)" class="discipline-page__header-tab">
        <m-progress
          v-if="false"
          :percent="0"
          :size="mobileMode ? 7.2 : 11"
          :strokeWidth="0.2"
          unit="rem"
        />
        <p class="header-tab__title">
          {{ disciplineData.disciplineName }}
        </p>
        <p class="header-tab__control">{{ disciplineData.code }}</p>
        <div v-if="disciplineData.teacher" class="header-tab__teacher-names">
          <p class="header-tab__teacher-name">
            <m-icon name="telegrammLogo" />
            <span class="teacher-name__substring">{{
              disciplineData.teacher
            }}</span>
          </p>
        </div>
        <!-- <a
          href="https://spo.instudy.online/discipline/rpd/fizra.pdf"
          target="blank"
          class="btn-fill header-tab__rpd-btn"
        >
          <m-icon name="tree" />
          РПД
        </a> -->
        <!-- <m-icon name="star" /> -->
      </div>
      <switchTab
        v-if="!isEmpty(disciplineData) && switchButtons.length"
        :buttons="switchButtons"
      />
    </simplebar>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';
import { mapActions, mapMutations } from 'vuex';
import switchTab from './modules';

const switchButtons = [
  {
    id: 1,
    title: 'Описание',
    component: 'description',
    icon: 'tablet-list',
  },
  {
    id: 2,
    title: 'Материалы',
    component: 'materials',
    icon: 'several-files',
  },
  {
    id: 3,
    title: 'Задания',
    component: 'tasks',
  },
];
export default {
  name: 'discipline',
  data() {
    return {
      switchButtons: [],
    };
  },
  components: {
    switchTab,
    simplebar,
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    mobileMode() {
      return this.$store.state.mobileMode;
    },
    disciplineData() {
      return this.$store.state.discipline.disciplineData;
    },
    disciplineParams() {
      return this.$route.query;
    },
  },
  methods: {
    ...mapMutations({
      setMaterials: 'discipline/setMaterials',
    }),
    ...mapActions({
      getMaterials: 'discipline/getMaterials',
    }),
    handleReturn() {
      this.$router.go(-1);
    },
    isEmpty(object) {
      return this.$helpers.helperFunctions.isEmpty(object);
    },
  },
  async created() {
    const {
      description, materials, onlineCourse, tests,
    } = await this.getMaterials(
      JSON.parse(localStorage.getItem('selectedDiscipline')),
    );

    this.switchButtons = switchButtons.filter((item) => {
      if (item.component === 'description') {
        return !!description;
      }
      if (item.component === 'materials') {
        return materials?.length || onlineCourse;
      }
      if (item.component === 'tasks') {
        return tests?.length;
      }
    });
  },
  beforeDestroy() {
    this.setMaterials({});
  },
};
</script>

<style lang="scss">
.discipline-page {
  overflow-y: auto;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 2.5rem;
  .discipline-page__wrp {
    height: 100%;
    overflow-y: auto;
  }
  .simplebar-content {
    display: grid;
    padding-right: 1rem !important;
  }
  .discipline-page__return-btn {
    background-color: transparent;
    justify-self: flex-start;
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
    i {
      font-size: 1.7rem;
      color: $basic-black;
    }
  }
  .text-title {
    font-size: 1.2rem;
  }
  .discipline-page__header-tab {
    justify-self: center;
    width: 100%;
    max-width: 89.5rem;
    padding: 2.4rem 2.4rem 2.4rem 3.2rem;
    background-color: #f2f3f9;
    border-radius: 1rem;
    // grid-template-columns: 1fr auto;
    column-gap: 3.4rem;
    row-gap: 1rem;
    display: grid;
    // grid-template-areas:
    //   "title rpd "
    //   "control ."
    //   "names . ";
    margin-bottom: 2rem;
  }
  .m-progress {
    align-self: center;
    // grid-area: progress;
    transition-duration: 0.3s;
    background: rgb(200, 180, 246);
    background: linear-gradient(
      356deg,
      rgba(200, 180, 246, 1) 0%,
      rgba(246, 246, 246, 1) 100%
    );
    box-shadow: 0px 4px 16px rgba(117, 69, 201, 0.38);

    .m-progress__percent {
      font-size: 3.4rem;
      transition-duration: 0.3s;
      transition-property: color;
      font-family: "ProximaNova-Regular";
      color: $brand-clr-1;
    }

    .m-progress__circle {
      transition-duration: 0.3s;
      transition-property: color;
      stroke: $brand-clr-1;
    }

    .m-progress__circle-bg {
      stroke: transparent;
    }
  }
  .header-tab__title {
    justify-self: flex-start;
    // grid-area: title;
    color: $basic-black;
    font-size: 2.4rem;
    font-family: "Gilroy-Bold";
    text-transform: uppercase;
  }
  .title__sub-string {
    font-size: inherit;
    font-family: "Gilroy-Regular";
  }
  .m-icon--star {
    align-self: center;
    grid-area: star;
    fill: none;
    stroke: $placeholder-text-clr;
  }
  .header-tab__teacher-names {
    justify-self: flex-start;
    display: grid;
    row-gap: 0.8rem;
    // grid-area: names;
  }
  .header-tab__rpd-btn {
    transition-duration: 0.3s;
    padding: 1rem;
    grid-area: rpd;
    height: 3.5rem;
    background-color: $basic-white;
    color: $placeholder-text-clr;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 0.8rem;
    .m-icon {
      transition-duration: 0.3s;
      fill: #cccccc;
    }
    &:hover {
      color: $basic-white;
      .m-icon {
        fill: $basic-white;
      }
    }
  }
  .header-tab__teacher-name {
    display: flex;
    align-items: center;
    column-gap: 0.8rem;
  }
  .header-tab__control {
    // grid-area: control;
    color: $basic-black;
    font-family: "ProximaNova-Regular";
    font-size: 1.4rem;
  }
  .teacher-name__substring {
    color: $brand-clr-1;
    font-family: "ProximaNova-Regular";
  }
  .m-icon--telegramm {
    width: 1.6rem;
    font-size: 1.4rem;
    fill: $brand-clr-1;
  }
}
@include breakPoints(md) {
  .page {
    padding: 0 2rem;
    border-radius: 0;
  }

  .discipline-page {
    .discipline-page__header-tab {
      padding: 1.6rem 2rem;
      column-gap: 1.4rem;
      grid-template-columns: auto 1fr;
    }
    .header-tab__title {
      font-size: 1.7rem;
    }
  }
}
</style>
