export default {
  session: {
    backgroundColor: '#4B5ACA',
    color: 'white',
  },
  educational: {
    backgroundColor: '#379E8D',
    color: 'white',
  },
  yourown: {
    backgroundColor: '#B039C5',
    color: 'white',
  },
  college: {
    backgroundColor: '#FFE946',
    color: 'black',
  },
};
