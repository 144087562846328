<template>
  <div :class="$style.ElectronicJournal">
    <h4 :class="$style.title">Электронный журнал</h4>

    <listOfSemesters
      v-if="semesters"
      :class="$style.listOfCourses"
      :list="semesters"
      v-model="selectedSemester"
    />


    <button
      v-if="themes.length"
      :class="$style.backButton"
      @click="themes = []"
    >
      <m-icon name="arrow"/>
    </button>

    <creditTable
      v-if="selectedSemester"
      :class="$style.creditTable"
      :headers="headers"
      :tableData="currentTableData"
      slot-row
      :template-columns="currentTemplateColumns"
      @row-click="rowClickHandler"
    >
      <template #row="row">
        <span class="table__col">{{ row.idx + 1 }}</span>
        <span class="table__col">{{ themes.length ? row.topic_lesson : row.discipline }}</span>
        <span class="table__col">{{ themes.length ? row.assessment_name : row.average_score }}</span>
        <span class="table__col">{{ themes.length ? row.date_lesson : row.absences }}</span>
      </template>
    </creditTable>

    <h2 v-else class="credit-book__notify"> {{ semesters ? 'Выберите семестр' : 'Нет данных' }}</h2>
  </div>
</template>

<script>
import listOfSemesters from '@/views/EducationalProcess/modules/common/listOfSemesters';
import creditTable from '@/views/EducationalProcess/modules/common/creditTable';
import { mapState } from 'vuex';
import { isObject } from 'lodash';

const assesments = [
  { id: 1, name: "Неудовлетворительно" },
  { id: 2, name: "Удовлетворительно" },
  { id: 3, name: "Хорошо" },
  { id: 4, name: "Отлично" },
  { id: 5, name: "Зачтено" },
  { id: 6, name: "Не зачтено" },
  { id: 7, name: "Неявка" }
];

export default {
  name: 'ElectronicJournal',

  components: {
    listOfSemesters,
    creditTable,
  },

  data() {
    return {
      selectedSemester: null,
      journalNote: null,
      disciplines: [],
      themes: [],
      semesters: null,
    };
  },

  computed: {
    ...mapState({
      mobileMode: (state) => state.mobileMode,
    }),

    filteredDisciplines() {
      return this.selectedSemester
        ? this.disciplines.filter(el => +el.year === this.selectedSemester.year && +el.semester === this.selectedSemester.semester)
        : this.disciplines;
    },

    formatedThemes() {
      return this.themes.map((el, idx) => {
        const assesmentsName = assesments.find(assesment => assesment.id === +el.assessment_code)?.name || ''

        return {
          ...el,
          idx,
          date_lesson: this.$moment(el.date_lesson).format('DD.MM.YYYY'),
          assessment_name: assesmentsName
        }
      });
    },

    currentTableData() {
      return this.themes.length ? this.formatedThemes : this.filteredDisciplines;
    },

    headers() {
      if (!this.themes.length) {
        if (this.mobileMode) {
          return [
            'Дисциплина',
            'Средний балл',
            'Пропуски',
          ];
        }
        return [
          '№',
          'Дисциплина',
          'Средний балл',
          'Пропуски',
        ];
      }
      return [
        '№',
        'Тема',
        'Оценка',
        'Дата',
      ];
    },

    currentTemplateColumns() {
      if (!this.journalNote) {
        return this.mobileMode ? '63% 15% 12%' : '2% 60% 21% 12%';
      }
      return '6% 60% 19% 11%';
    },
  },

  async created() {
    await this.getEjournal()
  },

  methods: {
    async getEjournal() {
      try {
        const { status, data: { data: disciplines, semesters } } = await this.axios.get('/api/ejournal');

        if (status) {
          this.disciplines = disciplines.map((el, idx) => ({...el, idx}));
          this.semesters = semesters.length ? semesters : null;
        }
      } catch (e) {
        console.warn('Failed fetch ElectronicJournal/getEjournal', e);
      }
    },

    rowClickHandler({ discipline_id }) {
      if (discipline_id) {
        this.getDiscipline(discipline_id);
      }
    },

    async getDiscipline(id) {
      try {
        console.log('id', id)
        const { data: { data: themes }, status } = await this.axios.get(`/api/ejournal/discipline`, {
          params: {
            year: this.selectedSemester.year,
            discipline_id: id
          }
        });

        if (status === 200) {
          this.themes = themes;
        }
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>

<style lang="scss" module>
  .ElectronicJournal {
    padding-right: 3rem;
  }

  .title {
    font-size: .9rem;
    color: $basic-black;

    @include breakPoints(md) {
      display: none;
    }
  }

  .listOfCourses {
    margin-bottom: 5.9rem;
  }

  .creditTable {
    & :global(.table__body .table__row) {
      transition: .3s background-color;
      cursor: pointer;

      &:hover {
          background-color: $placeholder-text-clr;
      }
    }

    & :global(.table__body .table__row span:nth-child(2) ) {
      justify-self: flex-start;
    }
  }

  .noteAndDateCol {
    display: grid;
    width: 100%;
    grid-column: 3/5;
  }

  .noteAndDate {
    display: grid;
    grid-template-columns: 3.5fr 2fr;
    column-gap: 1rem;
    width: 100%;

    span {
      display: block;

      &:first-child {
        justify-self: center;
      }

      &:last-child {
        justify-self: flex-start;
      }
    }
  }

  .backButton {
    margin-bottom: 2rem;
    background-color: transparent;
  }
</style>
