<template>
  <div :class="$style.syncCalendarModalInstruction">
    <el-tabs
      v-if="methods.length > 1"
      v-model="currentTabIdx"
      type="card"
      :class="$style.tabs"
    >
      <el-tab-pane
        v-for="method in methods"
        :key="method.value"
        :label="method.label"
      />
    </el-tabs>

    <p v-if="description" :class="$style.description">{{ description }}</p>

    <ul :class="$style.list">
      <li
        v-for="(step, idx) in steps"
        :key="idx"
        :class="[
          $style.item,
          { [$style._isCopy]: step.type === 'copy' },
          { [$style._isOneStep]: isOneStep },
        ]"
      >
        <span v-show="!isOneStep" :class="$style.number">{{ idx + 1 }}</span>

        <div v-html="step.desc" :class="$style.desc"></div>

        <div v-if="step.type === 'copy'" :class="$style.copyBlock">
          <m-input :class="$style.input" :value="url" readonly />
          <button :class="[$style.button, 'btn-fill']" @click="onCopy">
            <m-icon name="copy" :class="$style.icon" />
          </button>
        </div>
      </li>
    </ul>

    <div :class="[$style.imageWrap, {[$style._isFull]: imageIsFull}]">
      <img
        v-if="gif"
        :src="require(`@/assets/img/syncCalendarGif/${gif}`)"
        :class="$style.image"
        alt="instruction"
      />
      <button :class="$style.fullscreen" class="btn-fill" @click="imageIsFull = !imageIsFull">
        <m-icon :class="$style.fullscreenIcon" :name="imageIsFull ? 'close' : 'fullscreen'" />
      </button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import helperFunctions from '@/helpers/modules/helperFunctions.js';

export default {
  name: 'syncCalendarModalInstruction',

  props: {
    methods: {
      type: Array,
      default: () => [],
    },

    url: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentTabIdx: 0,
      imageIsFull: false,
    };
  },

  watch: {
    currentTabIdx(newValue) {
      this.$emit('select-method', newValue);
    },
  },

  computed: {
    currentTab() {
      return this.methods[this.currentTabIdx];
    },

    description() {
      return this.currentTab?.desc ?? '';
    },

    steps() {
      return this.currentTab?.steps ?? [];
    },

    gif() {
      return this.currentTab?.gif ?? '';
    },

    isOneStep() {
      return this.currentTab?.steps?.length === 1;
    },
  },

  methods: {
    ...mapMutations(['pushNotify']),

    onCopy() {
      const { copyText } = helperFunctions;

      copyText(this.url);

      this.pushNotify({
        content: 'Текст скопирован',
        type: 'success',
      });
    },
  },
};
</script>

<style lang="scss" module>
.syncCalendarModalInstruction {
  .icon {
    fill: $basic-white;
  }

  .input {
    width: 100%;
    max-width: unset;

    :global(.m-input__input) {
      color: $basic-black;
    }
  }

  .tabs {
    margin-bottom: 2rem;

    :global(.el-tabs__item) {
      color: $brand-clr-1;
    }

    :global(.el-tabs__nav) {
      border-radius: 1rem 1rem 0 0;
    }

    :global(.el-tabs__item:first-child) {
      border-radius: 1rem 0 0 0;
    }

    :global(.el-tabs__item:last-child) {
      border-radius: 0 1rem 0 0;
    }

    :global(.el-tabs__item.is-active) {
      background-color: $brand-clr-1;
      color: $basic-white;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas: "number desc";
  column-gap: 2rem;
  row-gap: 0.8rem;

  &:not(:last-child) {
    margin-bottom: 2.4rem;
  }

  &._isCopy {
    grid-template-columns: auto 1fr;
    grid-template-areas:
      "number desc"
      ". copy";
  }

  &._isOneStep {
    grid-template-columns: 1fr;
    grid-template-areas: "desc";
  }

  &._isOneStep._isCopy {
    justify-items: flex-start;
    grid-template-columns: 1fr;
    grid-template-areas: "desc" "copy";
  }
}

.number {
  grid-area: number;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  color: $basic-white;
  border-radius: 50%;
  background-color: $brand-clr-1;
}

.description {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

.desc {
  grid-area: desc;
  margin-top: 0.4rem;
  font-size: 1.6rem;

  svg {
    color: $basic-black;
    transform: translateY(0.2rem);
  }

  b {
    color: $basic-black;
  }
}

.copyBlock {
  grid-area: copy;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 0.6rem;
  width: 100%;
}

.button {
  width: 4rem;
  height: 4rem;
}

.image {
  margin-top: 4rem;
  width: 100%;
}

.imageWrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &._isFull {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .image {
      width: 90%;
      height: 90%;
      margin: auto;
      object-fit: contain;
    }

    .fullscreen {
      top: 2rem;
      bottom: unset;
    }
  }
}

.fullscreen {
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  width: 4rem;
  height: 4rem;
  transition-duration: .3s;
  transition-property: left, right;

  .fullscreenIcon {
    width: 2rem;
    height: 2rem;
    fill: $basic-white;
  }
}
</style>
