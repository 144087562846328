<template>
  <div iv id="sweetCalendar">
    <div class="container-calendar">
      <div :class="[arrow ? 'header-no-arrow' : 'header']">
        <m-button
          colorBg="transparent"
          :noBorder="true"
          v-if="!arrow"
          @click="prevMonth"
        >
          <i class="fas fa-chevron-left"></i>
        </m-button>
        <div class="month">
          <b>{{ selectedMonthName }}</b> , <b>{{ selectedDay }}</b> |
          <span>{{ selectedDayOfTheWeekName }}</span>
        </div>
        <m-button
          colorBg="transparent"
          :noBorder="true"
          v-if="!arrow"
          @click="nextMonth"
        >
          <i class="fas fa-chevron-right"></i>
        </m-button>
      </div>
      <div class="body">
        <div
          v-for="(day, index) in weekdays"
          :key="`day-name-${index + 1}`"
          class="day-name"
        >
          {{ day }}
        </div>
        <div v-for="(day, index) in days" :key="index" class="day-container">
          <div
            @click="goToEvents(day)"
            v-if="day"
            :class="generateDayClass(day)"
          >
            <div class="day-number">{{ day.getDate() }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DateTime from '@/modules/dateTime';

export default {
  name: 'sweetCalendar',
  data() {
    return {
      today: new DateTime(),
      date: '',
      weekdays: null,
      mode: 'Месяц',
    };
  },
  watch: {
    initialDate(val) {
      this.date = new DateTime(val);
    },
  },
  computed: {
    days() {
      const emptyDays = Array(
        (this.startWeekDayOfMonth - this.firstDayOfWeek + 7) % 7,
      ).fill(null);
      const days = Array(this.numberOfDays)
        .fill()
        .map(
          (item, index) => new DateTime(this.selectedYear, this.selectedMonth, index + 1),
        );
      return emptyDays.concat(days);
    },
    startWeekDayOfMonth() {
      return this.date.getFirstWeekdayOfMonth();
    },
    numberOfDays() {
      return this.date.getNumberOfDaysInMonth();
    },
    selectedMonth() {
      return this.date.getMonth();
    },
    selectedDayOfTheWeekName() {
      return this.value.getDayName();
    },
    selectedMonthName() {
      return this.date.getMonthName();
    },
    selectedYear() {
      return this.date.getFullYear();
    },
    selectedDay() {
      return this.value.getDate();
    },
    prevMonthData() {
      return new DateTime(this.selectedYear, this.selectedMonth - 1, 1);
    },
    selectedEvent() {
      return this.$store.state.journal.selectDate;
    },
  },
  methods: {
    goToEvents(day) {
      this.$emit('input', day);
    },
    prevMonth() {
      this.date = new DateTime(this.selectedYear, this.selectedMonth - 1, 1);
      this.$emit('prevMonth', this.date);
    },
    nextMonth() {
      this.date = new DateTime(this.selectedYear, this.selectedMonth + 1, 1);
      this.$emit('nextMonth', this.date);
    },
    generateWeekdayNames(firstDayOfWeek = 1) {
      const weekdays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
      for (let i = 2; i <= firstDayOfWeek; i++) {
        const first = weekdays.shift();
        weekdays.push(first);
      }
      return weekdays;
    },
    generateDayClass(date) {
      const isNow = date.toDateString() === this.today.toDateString();
      const result = this.events.find((el) => el.date === date.getYYYYMMDD());
      const dayIsSelected = this.value.getYYYYMMDD() === date.getYYYYMMDD();
      const classes = ['day'];
      if (this.offDays.includes(date.getDay())) classes.push('off-day');
      if (isNow) classes.push(this.eventCategories.now);
      if (result) classes.push(this.eventCategories.event);
      if (dayIsSelected) classes.push(this.eventCategories.selected);
      return classes;
    },
    goToday() {
      this.date = this.today;
    },
    goSelectDate() {
      this.date = this.$store.state.journal.selectDate;
    },
  },
  props: {
    value: Object,
    initialDate: {
      type: String,
      default: null,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    firstDayOfWeek: {
      type: Number,
      default: 1, // 1: Sunday, 2: Monday, etc
    },
    eventCategories: {
      type: Object,
    },
    events: {
      type: Array,
      default() {
        return [];
      },
    },
    offDays: {
      type: Array,
      default() {
        return [1, 7];
      },
    },
  },
  beforeMount() {
    this.date = Date.parse(this.initialDate)
      ? new DateTime(this.initialDate)
      : new DateTime();
    this.weekdays = this.generateWeekdayNames(this.firstDayOfWeek);
  },
};
</script>

<style lang="scss">
#sweetCalendar {
  box-sizing: border-box;
  .container-calendar {
    padding: 1.7rem;
    background: transparent;
    grid-template-columns: 1fr;
    display: grid;
    grid-template-rows: 3rem 1fr;
    height: 100%;
    row-gap: 1rem;
    .header {
      padding: 0 1rem;
      justify-items: center;
      align-items: center;
      display: grid;
      grid-column-gap: 0.5rem;
      grid-template-columns: max-content 1fr max-content;
      justify-items: center;
      .el-icon-d-arrow-left,
      .el-icon-d-arrow-right {
        font-size: 1.8rem;
      }
      .el-button {
        padding: 0;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
      }
    }
    .month {
      display: flex;
      color: $basic-black;
      font-family: "Roboto-Bold";
      text-align: center;
      justify-self: center;
      font-size: 1.4rem;
      gap: 0.5rem;
      b,
      span {
        color: inherit;
        &:first-letter {
          text-transform: uppercase;
        }
      }
      b {
        font-family: "Gilroy-Bold";
      }
      span {
        font-family: "Gilroy-Regular";
      }
    }
    .header-no-arrow {
      display: grid;
      justify-content: center;
      align-items: center;
    }
    .body {
      align-items: center;
      display: grid;
      grid-row-gap: 0.1rem;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: repeat(7, 1fr);
      gap: 0.2rem;
      justify-items: center;

      .day-name {
        color: $basic-black;
        font-size: 1.2rem;
      }
      .day-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.8rem;
        position: relative;
        width: 100%;

        .day {
          box-sizing: border-box;
          position: relative;
          border: 0.1rem solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $basic-black;
          border-radius: 50%;
          box-sizing: content-box;
          width: 2.3rem;
          height: 2.3rem;
          cursor: pointer;
          &.today {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $brand-clr-1;
            color: $basic-white;
          }
          .day-number {
            position: absolute;
            border: 0.1rem solid transparent;
            border-radius: 50%;
            margin-right: -0.95rem;
            margin-top: -0.95rem;
            top: 50%;
            right: 50%;
            font-family: "Gilroy-Bold";
            font-size: 1rem;
            width: 1.9rem;
            height: 1.9rem;
            color: inherit;
            line-height: 1.8rem;
            text-align: center;
          }
        }
      }
    }
  }
  .m-button--default {
    padding: 0;
    i {
      color: $basic-black;
      font-size: 1.6rem;
    }
    &:hover {
      i {
        color: $brand-clr-1;
      }
    }
  }
}
</style>
